import React, { useState, useEffect } from "react"
import "./StaffLogin.css"
import axiosConfig from "../../Service/axiosConfig"
import { useNavigate } from 'react-router-dom';

const StaffLogin = () => {
    let navigate = useNavigate();
    const [visible, SetVisable] = useState(false)
    const [siteSettings, setSiteSettings] = useState({})
    const inputType = visible ? "text" : "password"
    const [Data, SetData] = useState({
        mobileno: "",
        password: ""
    })
    const [error, SetError] = useState("")
    const InputHandleer = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        console.log(name, value)

        SetData({ ...Data, [name]: value })
    }

    const SubmitHandller = (e) => {
        e.preventDefault()

        const { mobileno, password } = Data
        if (mobileno === "") {
            SetError(
                <>
                    <img className="errorIcon" src={require('./images/LoginError.png')} alt="LoginError" />
                    mobileno is Required
                </>

            )
        }
        else if (password === "") {
            SetError(
                <>
                    <img className="errorIcon" src={require('./images/LoginError.png')} alt="LoginError" />
                    password is Required
                </>
            )
        }
        else {
            const { mobileno, password } = Data
            const formData = new FormData();
            formData.append("mobileno", mobileno)
            formData.append("password", password)
            axiosConfig.post("/accounts/api/login/", formData).then((res) => {
                localStorage.setItem('token', res.data.token);
                console.log(res, 'res')
                localStorage.setItem('user_data', JSON.stringify(res.data.user));
                return navigate("/dashboard/orders");
            }).catch(e => {
                console.log(e)
                SetError(
                    <>
                        <img className="errorIcon" src={require('./images/LoginError.png')} alt="LoginError" />
                        {e.response.data.error || e.response.data.detail}
                    </>
                )
            })
        }
    }
    const GetSiteSettings = async () => {
        const site_response = await axiosConfig.get('/sitedata/site/')
        console.log("site data", site_response.data.results)
        if (site_response.data.results.length > 0) {
            setSiteSettings(site_response.data.results[0])
            document.title = site_response.data.results[0].title;
            const favicon = document.querySelector("link[rel='icon']") || document.createElement('link');
            favicon.rel = 'icon';
            favicon.href = site_response.data.results[0].favicon.image;
            document.head.appendChild(favicon);
        }
    }
    useEffect(() => {
        GetSiteSettings();
    }, [])
    return (
        <>
            <div className="login-main">
                <div className="container">
                    <div className="login-container">
                        <div className="login-c-left">
                            <div className="login-image text-center">
                                <img className="vector1" src={siteSettings.logo ? siteSettings.logo.image : ''} alt="Logo" />
                                <img className="vector2" src={siteSettings.banner ? siteSettings.banner.image : ''} alt="banner" />
                            </div>
                        </div>
                        <div className="login-c-right">
                            <div className="login-form">
                                <div className="form-container">
                                    <h2>Login your Account</h2>
                                    <div className="Loginerror">
                                        <p>{error}</p>
                                    </div>
                                    <form>
                                        <div className="form-group">
                                            <label>Email or Phone:</label>
                                            <input type="text" className="form-control" placeholder="Email Address or Phone Number" name="mobileno" value={Data.mobileno} onChange={InputHandleer} />
                                        </div>
                                        <div className="form-group has-icon">
                                            <label>Password:</label>
                                            <input type={inputType} className="form-control" placeholder="Enter password" name="password" value={Data.password} onChange={InputHandleer} />
                                            {visible ?
                                                <img className="inputicon1" src={require('../../Pages/images/show.png')} alt="password" onClick={() => SetVisable(false)} /> :
                                                <img className="inputicon" src={require('./images/eye.jpg')} alt="password" onClick={() => SetVisable(true)} />
                                            }
                                        </div>
                                        <div className="d-grid">
                                            <button type="submit" className="btn btn-primary btn-block" onKeyPress={(e) => e.key === "ENTER" ? SubmitHandller : null} onClick={SubmitHandller}>Login</button>
                                        </div>
                                    </form>
                                    <div className="f-copy text-center">
                                        <p className="copy-sym2"><span className="copy-sym">&copy;</span>2021 all rights reserved
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export { StaffLogin };