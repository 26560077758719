import React from "react";
import { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
const AlertDeletePopupModal = ({ AlertDeletePopupType, HandleDelete, row, CancelAlerrt }) => {
    const [Show, setShow] = useState(false);
    const handleClose = () => {
        setShow(!Show)
    };
    const GetData = () => {
        console.log(row)
        if (AlertDeletePopupType === "success") {
            setShow(!Show);
        } else {
            setShow(Show);
        }
    }
    useEffect(() => {
        GetData();
    }, [AlertDeletePopupType])
    return (
        <>
            <div>
                <Modal
                    show={Show}
                    onHide={() => CancelAlerrt()}>
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="success-icon">
                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                        </div>
                        <h5 className="successmsg">Are You Sure You Want to delete</h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <button variant="primary" className="btn btn-primary" onClick={() => CancelAlerrt()}>
                            Cancel
                        </button>
                        <button variant="primary" className="btn btn-primary" onClick={() => HandleDelete(row)}>
                            Ok
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>

    )
}
export default AlertDeletePopupModal