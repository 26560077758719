import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal, FormParser } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import { FaHandHoldingHeart } from "react-icons/fa"
import DataTable from 'react-data-table-component';
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import "./master_categories.css";
import { Button, Modal } from 'react-bootstrap';
import { BiSearch } from "react-icons/bi"
import Select from 'react-select';
import Masters from "../Components/masters.json";
import { NavbarComponent } from "../Components/navbar/NavComponent";
import { FileManager } from "../Components/FileManager";
import useDebouncedValue from "../Utils/Debounce";
const Subcategories = () => {
    const MastersModal = Masters.SubCategories
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const [loading, setLoading] = useState(false)
    const [RowId, SetRowId] = useState()
    const [Id, SetId] = useState()
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [popupType, setpopupType] = useState("")
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false)
        SetError("")
        setpopupType("")
    }
    const [Error, SetError] = useState("")
    const [modalTitle, setModalTitle] = useState("Add New Item");
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState("");
    const [message, setMessage] = useState('')
    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "center"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "center",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    }
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                let allCategories = [];
                let nextPage = "catlog/categories/?is_suspended=false";

                while (nextPage) {
                    const response = await axiosConfig.get(nextPage);
                    const categories = response.data.results.map(category => ({
                        value: category.id,
                        label: category.name
                    }));
                    allCategories = allCategories.concat(categories);
                    nextPage = response.data.next;
                }

                console.log("All categories:", allCategories);
                setCategoryOptions(allCategories);
            } catch (error) {
                console.error("Error fetching categories:", error);
                setCategoryOptions([]);
            }
        };
        fetchCategories();
    }, []);
    const handleCategoryFilterChange = (e) => {
        console.log(e)
        setSelectedCategoryId(e.value);
    };
    const handleShow = () => {
        setShow(true)
        setFormData({
            name: '',
            description: '',
            image: '',
            category: '',
            meta_title: '',
            meta_description: '',
            meta_keyword: '',
            sort: ''
        });
        setcatid(null);
        setModalTitle("Add New Item");
    };
    const [catid, setcatid] = useState()
    const [search, SetSearch] = useState({
        search: ""
    })
    const debouncedSearchTerm = useDebouncedValue(search.search, 500);
    const [Data, SetData] = useState([])
    const GetData = async () => {
        setLoading(true);
        try {
            let url = `catlog/sub-categories/?page=${currentPage}&page_size=${RowPerPage}`;
            if (selectedCategoryId) {
                url += `&category=${selectedCategoryId}`;
            }
            const response = await axiosConfig.get(url);
            SetData(response.data.results);
            setLoading(false);
            setTotalRows(response.data.count);
        } catch (error) {
            console.log(error);
        }
    };


    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
    }

    const HandleSearchDebounce = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(
                `catlog/sub-categories/?search=${debouncedSearchTerm}`
            );
            SetData(response.data.results);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }
    const HandleMouse = (row) => {
        SetId(row.id)
    }
    const HandleRowmouseLeave = () => {
        SetId()
    }
    const handleSort = (column, direction) => {
        console.log(column.selector, direction)
        axiosConfig.get(`catlog/sub-categories/?page=${currentPage}&page_size=${RowPerPage}&ordering=${direction === "asc" ? column.selector : -column.selector}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        image: '',
        category: '',
        meta_title: '',
        meta_description: '',
        meta_keyword: '',
        sort: ''
    });
    const catedit = (id) => {
        axiosConfig
            .get(`catlog/sub-categories/${id}/`)
            .then((res) => {
                console.log(res.data);
                setFormData({ ...res.data, category: res.data.category.id });
                setShow(true)
                setcatid(id);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        GetData();
    }, [currentPage, RowPerPage, selectedCategoryId]);
    useEffect(() => {
        HandleSearchDebounce();
    }, [debouncedSearchTerm])
    const formSubmit = (Data) => {
        console.log(Data)
        const formDataToSend = new FormData();
        formDataToSend.append("name", Data.name);
        formDataToSend.append("description", Data.description);
        formDataToSend.append("sort", Data.sort);
        formDataToSend.append("category", Data.category)
        formDataToSend.append("meta_title", Data.meta_title);
        formDataToSend.append("meta_description", Data.meta_description);
        formDataToSend.append("meta_keyword", Data.meta_keyword);
        formDataToSend.append("image", Data.image.id);
        axiosConfig.defaults.headers["Content-Type"] = "multipart/form-data";
        if (Data.id) {
            console.log(formDataToSend.category, 'category')
            axiosConfig
                .patch(`catlog/sub-categories/${Data.id}/`, formDataToSend)
                .then((res) => {
                    console.log(res);
                    setpopupType("success");
                    setMessage('Subcategory Updated Successfully')
                    GetData();
                    setShow(false)
                    SetError("")
                })
                .catch((error) => {
                    console.log(error)
                    if (error.response.data.error) {
                        SetError(error.response.data.error)
                    }
                    setpopupType("error");
                });
        } else {
            axiosConfig
                .post("catlog/sub-categories/", formDataToSend)
                .then((res) => {
                    console.log(res);
                    setMessage('Subcategory Created Successfully')
                    setpopupType("success");
                    GetData();
                    setShow(false)
                    SetError("")
                })
                .catch((error) => {
                    console.log(error)
                    setpopupType("error");
                    if (error.response.data.error === "Selected category is suspended.") {
                        SetError("Selected category is suspended.")
                    }
                    if (error.response.data.error) {
                        SetError(error.response.data.error)
                    }
                });
        }
    };

    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true
        },
        {
            name: "Name",
            selector: "name",
            cell: row => row.name,
            sortable: true
        },
        {
            name: "image",
            selector: row => <>
                <img className="image" src={row.image && row.image.image} alt={row.image && row.image.image} />
            </>,
            sortable: true

        },
        {
            name: "category_name",
            selector: "category_name",
            cell: row => row.category_name,
            sortable: true
        },
        {
            name: "sort",
            selector: "sort",
            cell: row => row.sort,
            sortable: true
        },
        {
            name: "ACTIONS",
            selector: row => {
                const HandleEdit = async () => {
                    console.log(row.id, 'iddddd')
                    setShow(true)
                    catedit(row.id)
                    setModalTitle("Edit Item");


                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (row_id) => {
                    await axiosConfig.delete(`catlog/sub-categories/${row_id}/`).then(() => {
                        SetAlertDeletePopupType("error")
                        setDeletepopupType("success")
                        GetData();
                    }).catch((error) => {
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                        console.log(error)
                    })
                }
                const handleRevive = async () => {
                    try {
                        const response = await axiosConfig.post(`catlog/sub-categories/${row.id}/revive/`)
                        setpopupType("success")
                        setMessage("Sub-category revived successfully")
                        GetData()
                    } catch (error) {
                        console.log("Error While Reviving", error)
                    }
                }
                return (
                    <>
                        {Id === row.id ? (
                            row.is_suspended ? (
                                <FaHandHoldingHeart className="dashboard-tabe-data-actions" onClick={handleRevive} />
                            ) : (
                                <>
                                    <div className="row" >
                                        <div className="col-4">
                                            <MdOutlineEdit className="dashboard-tabe-data-actions" onClick={HandleEdit} />
                                        </div>
                                        <div className="col-4">
                                            <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
                                        </div>
                                    </div>
                                </>
                            )
                        ) : (
                            <div className={`client-type ${row.is_suspended ? 'variable-background' : 'contract-background'}`}>
                                {row.is_suspended ? 'Suspended' : 'Active'}
                            </div>
                        )
                        }

                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }
    ]
    return (
        <>
            <DashboardLayout>
                <div className="child-sidenav">
                    <NavbarComponent />
                    <div className="childnav-content">
                        <div className="cat-button">
                            <div className="btn" onClick={handleShow} >
                                + Add New
                            </div>
                        </div>

                        <div className="row d-flex justify-content-between">
                            <div className="col-md-4 sub-filter">
                                <Select
                                    options={categoryOptions}
                                    placeholder={selectedCategoryId ? "" : "Select Category"}
                                    value={selectedCategoryId.category}
                                    onChange={(selectedOption) => handleCategoryFilterChange(selectedOption, "category")}
                                    required
                                />
                            </div>
                            <div className="col-md-4 input-subcat-with-icon">
                                <span className="icon-product"><BiSearch /></span>
                                <input type="text" className="input-control" placeholder="Search" name="search" value={search.search} onChange={HandleSearch} />
                            </div>
                        </div>
                        <div className="table-responsive marginTop">
                            <DataTable
                                columns={columns}
                                data={Data} pagination paginationTotalRows={totalRows}
                                paginationPerPage={RowPerPage}
                                paginationDefaultPage={currentPage}
                                onChangePage={(page) => setCurrentPage(page)}
                                paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                                onSort={handleSort}
                                onRowMouseEnter={HandleMouse}
                                onRowMouseLeave={HandleRowmouseLeave}
                                paginationServer fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                                progressComponent={<div className="loader-container">
                                    <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                </div>}
                            />
                        </div>
                    </div>
                </div>
                <Modal
                    size="xl"
                    show={show}
                    onHide={handleClose}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            {modalTitle}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormParser modelObject={MastersModal} formData={formData} formSubmit={formSubmit} error={Error} buttonTitle='Save' />
                    </Modal.Body>
                </Modal>

                {popupType != "" &&
                    <PopupModal setpopupType={setpopupType} popupType={popupType} Navigation="/dashboard/masters/subcategories" Message={message} error={Error} />
                }
                {deletePopupType != "" &&
                    <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                }
            </DashboardLayout >
        </>
    )
}
export { Subcategories }