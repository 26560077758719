import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal } from "../Components";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import { MarketingNavbarComponent } from "../Components/navbar/marketingnav";
import { BiSearch } from "react-icons/bi";
import axiosConfig from "../Service/axiosConfig"
import { FormParser } from "../Components";
import Modal from 'react-bootstrap/Modal';
import Masters from "../Components/masters.json";
import DataTable from 'react-data-table-component';
import { MdOutlineAccountCircle, MdOutlineRemoveRedEye, MdFilterList, MdOutlineMailOutline, MdOutlinePhone, MdOutlineStore } from "react-icons/md";
import { FaHandHoldingHeart } from "react-icons/fa"
import "./customerManagement.css"
import { TbUserCheck } from "react-icons/tb";
import { RiMapPin2Line, RiDeleteBin6Line } from "react-icons/ri"
import { useNavigate } from "react-router-dom"
import Select from 'react-select';
import useDebouncedValue from "../Utils/Debounce";

const CustomerManagement = () => {
    const MastersModal = Masters.CustomerManagement
    const [search, SetSearch] = useState({
        search: ""
    })
    const debouncedSearchTerm = useDebouncedValue(search.search, 500);
    let navigate = useNavigate();
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
    }
    const HandleSearchDebounce = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(
                `/clients/clients/?search=${debouncedSearchTerm}`
            );
            SetData(response.data.results);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
    const [Error, SetError] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("");
    const [Data, SetData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [RowId, SetRowId] = useState()
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [popupType, setpopupType] = useState("")
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('')
    const [rowId, setRowId] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null);
    const [LoadingButton, setLoadingButton] = useState();
    const options = [
        { value: 'Contracted', label: 'Contracted' },
        { value: 'Variable', label: 'Variable' },
    ];
    const handleChange = async (selectedOption) => {
        setSelectedOption(selectedOption);
        setLoading(true)
        try {
            const response = await axiosConfig.get(`/clients/clients/?client_type=${selectedOption.value}&client_status=&page=${currentPage}&page_size=${RowPerPage}&is_suspended=false`);
            SetData(response.data.results);
            setLoading(false)
            setTotalRows(response.data.count);
        } catch (error) {
            console.log(error);
        }
    };
    const [formData, setFormData] = useState({});
    const handleShow = () => {
        setShow(true)
        SetError('')
        setFormData({
            ...formData,
            client_status: 'Active'
        })
    };
    const handleClose = () => {
        setShow(false)
        SetError('')
        setFormData("")
        setpopupType("")
    };
    const handleSort = (column, direction) => {
        console.log(column.selector, direction)
        axiosConfig.get(`/clients/clients/?page=${currentPage}&page_size=${RowPerPage}&ordering=${direction === "asc" ? column.selector : -column.selector}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };
    const formSubmit = async (formData, setFieldErrors) => {
        // Prepare JSON payload
        const payload = {
            address: formData.address,
            agreement: formData.agreement?.id || '',
            client_name: formData.client_name,
            client_status: formData.client_status,
            client_type: formData.client_type,
            contract_from_date: formData.contract_from_date || '',
            contract_to_date: formData.contract_to_date || '',
            email: formData.email,
            latitude: formData.latitude,
            logo: formData.logo?.id || '',
            longitude: formData.longitude,
            mobile: formData.mobile,
            business_vertical: formData.business_vertical,
            payment: formData.payment || [], // Ensure it's an array
            business_pan_no: formData.business_pan_no,
            business_pan_image: formData.business_pan?.id || '',
            partner_aadhar_no: formData.partner_aadhar_no,
            partner_aadhar_image: formData.partner_aadhar?.id || '',
            company_firm_no: formData.company_firm_no,
            company_firm_image: formData.company_firm?.id || '',
            gst_no: formData.gst_no,
            gst_image: formData.gst?.id || '',
            trade_license_no: formData.trade_license_no,
            trade_license_image: formData.trade_license?.id || '',
        };
    
        console.log("Payload:", payload);
    
        // Validate contracted clients
        if (formData.client_type === "Contracted") {
            if (!formData.contract_from_date || !formData.contract_to_date) {
                const errorMessage = "Error: Contracted client requires both contract_from_date and contract_to_date.";
                setFieldErrors((prev) => ({
                    ...prev,
                    contract_from_date: "Required",
                    contract_to_date: "Required",
                }));
                SetError(errorMessage);
                return;
            }
    
            if (formData.contract_to_date < formData.contract_from_date) {
                setFieldErrors((prev) => ({
                    ...prev,
                    contract_to_date: "To date must not be earlier than from date.",
                }));
                SetError("To date must not be earlier than from date.");
                return;
            }
        }
    
        try {
            SetError(null);
            axiosConfig.defaults.headers['Content-Type'] = 'application/json';
            setLoadingButton(true);
    
            const response = await axiosConfig.post("/clients/clients/", payload);
    
            console.log(response);
            setLoadingButton(false);
            setShow(false);
            setMessage('Client created successfully');
            setpopupType("success");
            GetData();
        } catch (error) {
            console.error(error);
            setLoadingButton(false);
    
            if (error.response?.data?.detail) {
                setFieldErrors((prev) => ({
                    ...prev,
                    email: "This email or mobile number already exists.",
                    mobile: "This email or mobile number already exists.",
                }));
                SetError(error.response.data.detail);
            }
    
            setShow(true);
            setpopupType("error");
        }
    };
    
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/clients/clients/?store=&delivery_agent=&client_type=&client_status=&page=${currentPage}&page_size=${RowPerPage}`)
            console.log(responce.data.results)
            SetData(responce.data.results)
            setLoading(false)
            setTotalRows(responce.data.count)
        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        GetData();
    }, [currentPage, RowPerPage]);
    useEffect(() => {
        HandleSearchDebounce();
    }, [debouncedSearchTerm])
    const [customerid, setCustomerid] = useState()
    const Customeredit = (id) => {
        console.log(id, 'iddddd')
        axiosConfig.get(`/clients/clients/${id}/`).then(res => {
            console.log(res.data)
            setFormData(res.data)
            setCustomerid(id)
            setShow(true)
        }).catch(error => {
            console.log(error)
        })
    }
    const HandleMouse = (row) => {
        setRowId(row.id)
    }
    const HandleRowmouseLeave = () => {
        setRowId()
    }
    const handleRowClick = (row) => {
        console.log(row)
        navigate(`/dashboard/masters/clients/${row}/edit`)
    }
    const [isSelectVisible, setIsSelectVisible] = useState(false);
    const handleFilterIconClick = () => {
        setIsSelectVisible(!isSelectVisible);
    };
    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "left",
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "left",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    };
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true,
            compact: true,
            width: '70px',
            center: true
        },
        {
            name: "Clients",
            minWidth: '300px',
            selector: "full_name",
            cell: row => <div className="client-detail">
                <img src={row.logo && row.logo.image !== undefined ? row.logo.image : ''} className="client-logo" alt="logo" />
                <div className="client-data">
                    <div className="client-name">{row.client_name}</div>
                    <div className={`client-type ${row.client_type === 'Contracted' ? 'contract-background' : 'variable-background'}`}>{row.client_type}</div>
                </div>
            </div>,
            sortable: true,
            reorder: true,
        },
        {
            name: "Contact",
            selector: "email",
            width: '300px',
            cell: row => <div className="">
                <div className="contact-details"><RiMapPin2Line className="contact-icon" />{row.address}</div>
                <div className="contact-email"><MdOutlineMailOutline className="contact-icon" />{row.email}</div>
                <div className="contact-phone"><MdOutlinePhone className="contact-icon" />{row.mobile}</div>
            </div>,
            sortable: true,
            compact: true,
            reorder: true,
            wrap:true,
            grow:2
        },
        {
            name: "Store",
            selector: "row.store",
            cell: row => <div className="store-info">
                {row.store ? (
                    <>
                        <div className="contact-details"><MdOutlineStore className="contact-icon" />{row.store.name !== undefined ? row.store.name : ''}</div>
                        <div className="contact-email"><MdOutlineMailOutline className="contact-icon" />{row.store.email !== undefined ? row.store.email : ''}</div>
                        <div className="contact-phone"><MdOutlinePhone className="contact-icon" />{row.store.mobile !== undefined ? row.store.mobile : ''}</div>
                    </>
                ) : (
                    <p>---</p>
                )}
            </div>,
            width: '200px',
            wrap:true,
            reorder: true,
            sortable: true
        },
        {
            name: "Delivery Agent",
            selector: "date_joined",
            cell: row => <div className="store-info">
                {row.delivery_agent ? (
                    <>
                        <div className="contact-details"><MdOutlineAccountCircle className="contact-icon" />{row.delivery_agent.full_name !== undefined ? row.delivery_agent.full_name : ''}</div>
                        < div className="contact-email"><MdOutlineMailOutline className="contact-icon" />{row.delivery_agent.email !== undefined ? row.delivery_agent.email : ''}</div>
                        <div className="contact-phone"><MdOutlinePhone className="contact-icon" />{row.delivery_agent.mobileno !== undefined ? row.delivery_agent.mobileno : ''}</div>
                    </>
                ) : (
                    <p>---</p>
                )}
            </div>,
            width: '200px',
            reorder: true,
            sortable: true
        },
        {
            name: "Status",
            width: '150px',
            selector: row => {
                const HandleEdit = async () => {
                    console.log(row.id, 'iddddd')
                    setShow(true)
                    Customeredit(row.id)
                    handleShow(row.id);
                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`/clients/clients/${id}/`).then(() => {
                        SetAlertDeletePopupType("error")
                        setDeletepopupType("success")
                        GetData();
                    }).catch((error) => {
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                        console.log(error)
                    })
                }
                const handleRevive = async () => {
                    try {
                        const response = await axiosConfig.post(`/clients/clients/${row.id}/revive/`)
                        setpopupType("success")
                        setMessage("Client revived successfully")
                        GetData()
                    } catch (error) {
                        console.log("Error While Reviving", error)
                    }
                }
                return (
                    <>
                        {rowId === row.id ? (
                            row.is_suspended ? (
                                <FaHandHoldingHeart className="dashboard-tabe-data-actions" onClick={handleRevive} />
                            ) : (
                                <div className="row" >
                                    <div className="col-6" onClick={() => handleRowClick(row.id)}>
                                        <MdOutlineRemoveRedEye className="client-actions" />
                                    </div>
                                    <div className="col-6">
                                        <RiDeleteBin6Line style={{ color: '#FF0000' }} className="client-actions" onClick={DeleteAlert} />
                                    </div>
                                </div>
                            )
                        )
                            :
                            (
                                <div className={`client-type ${row.is_suspended ? 'variable-background' : 'contract-background'}`}>{row.is_suspended ? "Suspended" : "Active"}</div>
                            )
                        }
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }
    ]

    return (
        <>
            <DashboardLayout>
                {/* <div className="child-sidenav"> */}
                {/* <MarketingNavbarComponent /> */}
                <div className="childnav-content">
                    <div className="top-search" >
                        <div className="filter-item">
                            <MdFilterList size={39} onClick={handleFilterIconClick} />
                            {isSelectVisible && (
                                <div className="filter-icons">
                                    <Select
                                        value={selectedOption}
                                        onChange={handleChange}
                                        placeholder="Filter By:"
                                        options={options}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="col-4 input-cat-with-icon">
                            <span className="icon-product"><BiSearch /></span>
                            <input type="text" className="input-control" placeholder="Search" name="search" value={search.search} onChange={HandleSearch} />
                        </div>
                        <button className="btn btn-primary" onClick={handleShow} >
                            + Add New
                        </button>
                    </div>
                    <div className="table-responsive marginTop">
                        <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                            paginationPerPage={RowPerPage}
                            paginationDefaultPage={currentPage}
                            onChangePage={(page) => setCurrentPage(page)}
                            paginationPerPageOptions={[10, 20, 25, 30, 40, 50, totalRows]}
                            paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50, totalRows]}
                            onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                            onSort={handleSort}
                            // onRowClicked={(row) => handleRowClick(row)}
                            onRowMouseEnter={HandleMouse}
                            onRowMouseLeave={HandleRowmouseLeave}
                            highlightOnHover
                            fixedHeaderScrollHeight="70vh"
                            fixedHeader={true}
                            paginationServer subHeader customStyles={tableCustomStyles} progressPending={loading}
                            progressComponent={<div className="loader-container">
                                <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                            </div>}
                        />
                    </div>
                    <div>
                        <Modal
                            size="xl"
                            show={show}
                            onHide={handleClose}
                            aria-labelledby="example-modal-sizes-title-lg"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-lg">
                                    Create New Client
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FormParser modelObject={MastersModal} formData={formData} formSubmit={formSubmit} buttonTitle='Save' LoadingButton={LoadingButton} setLoadingButton={setLoadingButton} />
                            </Modal.Body>
                        </Modal>
                        {popupType !== "" &&
                            <PopupModal setpopupType={setpopupType} popupType={popupType} Navigation="" Message={message} error={Error} />
                        }
                        {deletePopupType !== "" &&
                            <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                        }
                    </div>

                </div>
                {/* </div> */}
            </DashboardLayout>
        </>
    )
}
export { CustomerManagement }