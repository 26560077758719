import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal } from "../Components";
import { SettingNavbarComponent } from "../Components/navbar/settingsnav";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import { BiSearch } from "react-icons/bi";
import axiosConfig from "../Service/axiosConfig"
import { FormParser } from "../Components";
import Modal from 'react-bootstrap/Modal';
import Masters from "../Components/masters.json";
import DataTable from 'react-data-table-component';
import { MdOutlineEdit } from "react-icons/md";
import { FaHandHoldingHeart } from "react-icons/fa"
// import "./customerManagement.css"
import { RiDeleteBin6Line } from "react-icons/ri"
import { useNavigate } from "react-router-dom";
import useDebouncedValue from "../Utils/Debounce";

const DeliveryAgent = () => {
    const MastersModal = Masters.DeliveryAgent
    const [search, SetSearch] = useState({
        search: ""
    })
    const debouncedSearchTerm = useDebouncedValue(search.search, 500);
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
    }
    const HandleSearchDebounce = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(
                `/accounts/deliver-agents/?search=${debouncedSearchTerm}`
            );
            SetData(response.data.results);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
    const [Error, SetError] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("");
    const [Data, SetData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [RowId, SetRowId] = useState()
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [popupType, setpopupType] = useState("")
    const [show, setShow] = useState(false);
    const [rowId, setRowId] = useState(false)
    const [formData, setFormData] = useState({});
    const [message, setMessage] = useState('');
    const [modalTitle, setModalTitle] = useState('Add New Delivery Agent')
    const handleShow = () => {
        setModalTitle('Add New Delivery Agent')
        setShow(true)
    };
    const handleClose = () => {
        setShow(false)
        setFormData("")
        setpopupType("")
        SetError("")
    };
    const handleSort = (column, direction) => {
        console.log(column.selector, direction)
        axiosConfig.get(`/accounts/deliver-agents/?page=${currentPage}&page_size=${RowPerPage}&ordering=${direction === "asc" ? column.selector : -column.selector}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };
    const formSubmit = (formdata, setFieldErrors) => {
        console.log("Client post data : ", formdata)
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        const formDataToSend = new FormData();
        formDataToSend.append("full_name", formdata.full_name);
        formDataToSend.append("email", formdata.email);
        formDataToSend.append("mobileno", formdata.mobileno);
        formDataToSend.append("store", formdata.store);
        if (formdata.id) {
            axiosConfig.patch(`/accounts/deliver-agents/${formdata.id}/`, formDataToSend)
                .then(res => {
                    console.log(res)
                    setMessage(res.data.message)
                    setpopupType("success")
                    GetData();
                    setShow(false);
                }).catch(error => {
                    console.log(error)
                    if (error.response.data.error) {
                        SetError(error.response.data.error)
                    }
                    setpopupType("error")
                })
        } else {
            axiosConfig.post("/accounts/deliver-agents/", formDataToSend).then(res => {
                console.log(res)
                setMessage(res.data.message)
                setpopupType("success")
                GetData();
                setShow(false);
            }).catch(error => {
                console.log(error)
                if (error.response.data.error) {
                    SetError(error.response.data.error)
                }
                setpopupType(error)
            })
        }
    };
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/accounts/deliver-agents/?page=${currentPage}&page_size=${RowPerPage}`)
            console.log(responce.data.results)
            SetData(responce.data.results)
            setLoading(false)
            setTotalRows(responce.data.count)
        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        GetData();
    }, [currentPage, RowPerPage]);
    useEffect(() => {
        HandleSearchDebounce()
    }, [debouncedSearchTerm])
    const Customeredit = (id) => {
        console.log(id, 'iddddd')
        axiosConfig.get(`/accounts/deliver-agents/${id}/`).then(res => {
            console.log(res.data)
            const DeliveryAgent = { ...res.data, store: res.data.store.id, client_ids: res.data.clients.map(client => client.id) }
            setFormData(DeliveryAgent)
            setShow(true)
        }).catch(error => {
            console.log(error)
        })
    }
    const HandleMouse = (row) => {
        setRowId(row.id)
    }
    const HandleRowmouseLeave = () => {
        setRowId()
    }
    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "center"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "center",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    };
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true
        },
        {
            name: "Full Name",
            selector: "full_name",
            cell: row => row.full_name,
            sortable: true
        },
        {
            name: "Email",
            selector: "email",
            cell: row => row.email,
            sortable: true
        },
        {
            name: "Mobile No",
            selector: "mobileno",
            cell: row => row.mobileno,
            sortable: true
        },
        {
            name: "DC",
            selector: "row.store",
            cell: row => (row.store && row.store.name !== undefined ? row.store.name : ""),
            sortable: true
        },
        {
            name: "Clients",
            selector: "row.clients",
            cell: row =>
            (
                row.clients && row.clients.length > 0
                    ? row.clients.map(client => client.client_name).join(', ')
                    : ""
            ),
            sortable: true
        },
        {
            name: "Status",
            selector: row => {
                const HandleEdit = async () => {
                    console.log(row.id, 'iddddd')
                    setModalTitle('Edit Delivery Agent')
                    setShow(true)
                    Customeredit(row.id)
                    // handleShow(row.id);
                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`/accounts/deliver-agents/${id}/`).then(() => {
                        SetAlertDeletePopupType("error")
                        setDeletepopupType("success")
                        GetData();
                    }).catch((error) => {
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                        console.log(error)
                    })
                }
                const handleRevive = async () => {
                    try {
                        const response = await axiosConfig.post(`/accounts/deliver-agents/${row.id}/revive/`)
                        setpopupType("success")
                        setMessage("Delivery Agent revived successfully")
                        GetData()
                    } catch (error) {
                        console.log("Error While Reviving", error)
                    }
                }
                return (
                    <>
                        {rowId === row.id ? (
                            row.is_suspended ? (
                                <FaHandHoldingHeart className="dashboard-tabe-data-actions" onClick={handleRevive} />
                            ) : (
                                <div className="row" >
                                    <div className="col-4" onClick={HandleEdit}>
                                        <MdOutlineEdit className="client-actions" />
                                    </div>
                                    <div className="col-4">
                                        <RiDeleteBin6Line style={{ color: '#FF0000' }} className="client-actions" onClick={DeleteAlert} />
                                    </div>
                                </div>
                            ))
                            :
                            (
                                <div className={`client-type contract-background ${row.is_suspended ? "variable-background" : "contract-background"}`}>{row.is_suspended ? "Suspended" : "Active"}</div>
                            )
                        }
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }
    ]
    return (
        <>
            <DashboardLayout>
                <div className="child-sidenav">
                    <SettingNavbarComponent />
                    <div className="childnav-content">
                        <div className="cat-button">
                            <div className="btn" onClick={handleShow} >
                                + Add New
                            </div>
                        </div>
                        <div className="input-cat-with-icon">
                            <span className="icon-product"><BiSearch /></span>
                            <input type="text" className="input-control" placeholder="Search" name="search" value={search.search} onChange={HandleSearch} />
                        </div>
                        <div className="table-responsive marginTop">
                            <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                                paginationPerPage={RowPerPage}
                                paginationDefaultPage={currentPage}
                                onChangePage={(page) => setCurrentPage(page)}
                                paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                                onSort={handleSort}
                                // onRowClicked={(row) => handleRowClick(row)}
                                onRowMouseEnter={HandleMouse}
                                onRowMouseLeave={HandleRowmouseLeave}
                                highlightOnHover
                                paginationServer fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                                progressComponent={<div className="loader-container">
                                    <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                </div>}
                            />
                        </div>
                        <Modal
                            size="xl"
                            show={show}
                            onHide={handleClose}
                            aria-labelledby="example-modal-sizes-title-lg"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-lg">
                                    {modalTitle}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FormParser modelObject={MastersModal} formData={formData} formSubmit={formSubmit} error={Error} buttonTitle='Create Delivery Agent' button='btn-block' />
                            </Modal.Body>
                        </Modal>
                        {popupType !== "" &&
                            <PopupModal setpopupType={setpopupType} popupType={popupType} Navigation="" Message={message} error={Error} />
                        }
                        {deletePopupType !== "" &&
                            <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                        }
                    </div>
                </div>
            </DashboardLayout>
        </>
    )
}

export { DeliveryAgent }