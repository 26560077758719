import React, { useState, useEffect } from "react";
import { PaymentsNav } from "../Components/navbar";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { BiSearch } from "react-icons/bi";
import { Modal } from 'react-bootstrap';
import Masters from "../Components/masters.json";
import axiosConfig from "../Service/axiosConfig";
import DataTable from 'react-data-table-component';
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md"
import { FaHandHoldingHeart } from "react-icons/fa";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import useDebouncedValue from "../Utils/Debounce";

const PaymentTypes = () => {
    const [show, setShow] = useState(false);
    const MastersModal = Masters.PaymentTypes;
    const [formData, setFormData] = useState({});
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [popupType, setpopupType] = useState("");
    const [Error, SetError] = useState('');
    const [message, setMessage] = useState("");
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [Id, SetId] = useState();
    const [loading, setLoading] = useState(false);
    const [modalTitle, setModalTitle] = useState("Add New Payment Type");
    const [RowId, SetRowId] = useState();
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("");
    const [search, setSearch] = useState("")
    const debouncedSearchTerm = useDebouncedValue(search, 500);
    const columns = [
        {
            name: "S No.",
            selector: (row, index) => index + 1,
        },
        {
            name: "Code",
            selector: "code",
            sortable: true
        },
        {
            name: "Name",
            selector: "name",
            sortable: true
        },
        {
            name: "ACTIONS",
            selector: row => {
                const HandleEdit = async () => {
                    try {
                        setShow(true)
                        setModalTitle("Edit Payment Type");
                        const response = await axiosConfig.get(`/masters/payment-types/${row.id}/`)
                        setFormData(response.data)
                    } catch (error) {
                        console.log(error)
                    }
                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (row_id) => {
                    await axiosConfig.delete(`/masters/payment-types/${row_id}/`).then(() => {
                        SetAlertDeletePopupType("error")
                        setDeletepopupType("success")
                        GetPaymentTypes();
                    }).catch((error) => {
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                        console.log(error)
                    })
                }
                const handleRevive = async () => {
                    try {
                        const response = await axiosConfig.post(`/masters/payment-types/${row.id}/revive/`)
                        setpopupType("success")
                        setMessage("Payment Type revived successfully")
                        GetPaymentTypes()
                    } catch (error) {
                        console.log("Error While Reviving", error)
                    }
                }
                return (
                    <>
                        {Id === row.id ? (
                            row.is_suspended ? (
                                <FaHandHoldingHeart className="dashboard-tabe-data-actions" onClick={handleRevive} />
                            ) : (
                                <>
                                    <div className="row" >
                                        <div className="col-4">
                                            <MdOutlineEdit className="dashboard-tabe-data-actions" onClick={HandleEdit} />
                                        </div>
                                        <div className="col-4">
                                            <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
                                        </div>
                                    </div>
                                </>
                            )
                        ) : (
                            <div className={`client-type ${row.is_suspended ? 'variable-background' : 'contract-background'}`}>
                                {row.is_suspended ? 'Suspended' : 'Active'}
                            </div>
                        )
                        }
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )
            }
        }
    ];
    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "center"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "center",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    }
    const GetPaymentTypes = async () => {
        setLoading(true)
        try {
            const response = await axiosConfig.get(`/masters/payment-types/?search=${search}`)
            console.log(response.data.results)
            setPaymentTypes(response.data.results)
            setTotalRows(response.data.count)
            setLoading(false)
        } catch (error) {
            console.log("Error while fetching payment types", error)
        }
    }

    const HandleMouse = (row) => {
        SetId(row.id)
    }
    const HandleRowmouseLeave = () => {
        SetId()
    }

    const HandleShow = () => {
        setFormData({})
        setShow(true)
        setModalTitle("Add New Payment Type")
    }

    const HandleClose = () => {
        setShow(false)
        SetError("")
    }

    const formSubmit = async (Data) => {
        console.log(Data);
        const formDataToSend = new FormData();
        formDataToSend.append("code", Data.code);
        formDataToSend.append("name", Data.name);
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        try {
            let response;
            if (Data.id) {
                response = await axiosConfig.patch(`/masters/payment-types/${Data.id}/`, formDataToSend);
                console.log("updated payment types successfully: ", response);
                setMessage("Payment types Updated Successfully");
            } else {
                response = await axiosConfig.post("/masters/payment-types/", formDataToSend);
                console.log("created payment types successfully: ", response);
                setMessage("Payment types Created Successfully");
            }
            GetPaymentTypes();
            setpopupType("success");
            setShow(false);
            SetError("")
        } catch (error) {
            console.log("Error setting payment types : ", error);
            setpopupType("error");
            if (Object.values(error.response.data).length > 0) {
                SetError(Object.values(error.response.data)[0]);
            }
        }
    };


    useEffect(() => {
        GetPaymentTypes()
    }, [debouncedSearchTerm])
    return (
        <>
            <DashboardLayout>
                <div className="child-sidenav">
                    <PaymentsNav />
                    <div className="childnav-content mt-2">
                        <div class="cat-button">
                            <div class="btn" onClick={() => HandleShow()}>+ Add New</div>
                        </div>
                        <div className="input-cat-with-icon pe-0">
                            <span className="icon-product"><BiSearch /></span>
                            <input type="text" className="input-control" placeholder="Search" name="search" onChange={(e) => setSearch(e.target.value)} />
                        </div>
                        <div className="table-responsive marginTop">
                            <DataTable
                                columns={columns}
                                data={paymentTypes}
                                pagination
                                paginationTotalRows={totalRows}
                                paginationPerPage={RowPerPage}
                                paginationDefaultPage={currentPage}
                                onChangePage={(page) => setCurrentPage(page)}
                                paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                onRowMouseEnter={HandleMouse}
                                onRowMouseLeave={HandleRowmouseLeave}
                                onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                                // onSort={handleSort}
                                paginationServer fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                                progressComponent={<div className="loader-container">
                                    <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                </div>}
                            />
                        </div>
                    </div>
                    <Modal size="lg" show={show} onHide={() => HandleClose()} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>{modalTitle}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormParser modelObject={MastersModal} formData={formData} formSubmit={formSubmit} error={Error} />
                        </Modal.Body>
                    </Modal>
                    {popupType != "" &&
                        <PopupModal setpopupType={setpopupType} popupType={popupType} Navigation="" Message={message} error={Error} />
                    }
                </div>
            </DashboardLayout>
        </>
    )
}

export { PaymentTypes }