import { DashboardLayout } from "../Components";
import { ReportsNav } from "../Components/navbar";
import { useState, useEffect } from "react";
import axiosConfig from "../Service/axiosConfig";
import Select from "react-select";
import DataTable from "react-data-table-component";
import { AiOutlineFilePdf, AiOutlineFileExcel } from "react-icons/ai";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { downloadExcel } from "react-export-table-to-excel";
import NumberFormat from "../Utils/NumberFormat";

const SalesReport = () => {
  const [formData, setFormData] = useState({
    fromDate: new Date().toISOString().split("T")[0],
    toDate: new Date().toISOString().split("T")[0],
    client: null,
    category: null,
    subcategory: null,
  });
  const [clients, setClients] = useState([]);
  const [category, setCategory] = useState([]);
  const [subcategory, setSubcategory] = useState([]);
  const [nextPageUrl, setNextPageUrl] = useState("");
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [RowPerPage, setRowPerPage] = useState(20);
  const [loadPdf, setLoadPdf] = useState(false);
  const [loadCsv, setLoadCsv] = useState(false);

  const multiSelectStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#c9dddc" : "white", // Change background color for selected option
      color: state.isSelected ? "#1B7D78" : "black", // Change text color for selected option
      ":hover": {
        backgroundColor: "#c9dddc", // Change background color on hover for options
      },
    }),
    menu: (base) => ({ ...base, zIndex: 2 })
  };

  const header = [
    "S.No",
    "product_name",
    "category_name",
    "client",
    "total_qty",
    "avg_sale_price",
    "sale_amount",
    "avg_purchase_price",
    "purchase_amount",
    "pnl",
  ];

  async function handleDownloadExcel() {
    setLoadCsv(true); // loading state
    try {
      const params = {
        client: formData.client || "",
        from_date: formData.fromDate,
        to_date: formData.toDate,
        product: formData.product || "",
        category: formData.category || "",
        subcategory: formData.subcategory || "",
        page: 1,
        page_size: 1000,
      };

      const queryString = new URLSearchParams(params).toString();
      const response = await axiosConfig.get(
        `/accounts/product_sale_pl_report/?${queryString}`
      );
      const data = response.data.results;

      if (data && data.length > 0) {
        const body = data.map((res, index) => [
          index + 1,
          res.product_name,
          res.category_name,
          res.client,
          res.total_qty,
          res.avg_sale_price,
          res.sale_amount,
          res.avg_purchase_price,
          res.purchase_amount,
          res.pnl,
        ]);
        downloadExcel({
          fileName: "Sales Report",
          sheet: "Sales Report",
          tablePayload: {
            header: header,
            body: body,
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadCsv(false);
    }
  }

  const HandlePdf = async () => {
    setLoadPdf(true); // Start loading state
    try {
      // Fetch the data from the API with the provided query parameters
      const params = {
        client: formData.client || "",
        from_date: formData.fromDate,
        to_date: formData.toDate,
        product: formData.product || "",
        category: formData.category || "",
        subcategory: formData.subcategory || "",
        page: 1,
        page_size: 1000,
      };

      const queryString = new URLSearchParams(params).toString();
      const response = await axiosConfig.get(
        `/accounts/product_sale_pl_report/?${queryString}`
      );
      const data = response.data.results;

      if (data && data.length > 0) {
        // Store the data in localStorage
        localStorage.setItem("pdf_data", JSON.stringify(data));

        // Open a new window only after data is set in localStorage
        setTimeout(() => {
          window.open(`${window.location.origin}/print`, "_blank");
        }, 100);
      } else {
        // Handle case where no data is returned
        alert("No data found");
      }
    } catch (error) {
      // Log detailed error information
      console.error("Error getting data for PDF:", error);
      alert("An error occurred while generating the PDF. Please try again.");
    } finally {
      // Stop loading state, whether success or error
      setLoadPdf(false);
    }
  };

  const CustomHeader = () => {
    return (
      <div className="rowEndCenter gap-10">
        <AiOutlineFileExcel
          className={`report-icons ${loadCsv ? "disable" : ""}`}
          onClick={handleDownloadExcel}
        />
        <AiOutlineFilePdf
          className={`report-icons ${loadPdf ? "disable" : ""}`}
          onClick={() => HandlePdf()}
        />
      </div>
    );
  };

  const columns = [
    {
      name: "S. No.",
      cell: (row, index) => (currentPage - 1) * RowPerPage + index + 1,
      sortable: true,
      compact: true,
      center: true,
      width: "100px",
    },
    {
      name: "Client",
      selector: "client",
      sortable: true,
      width: "200px",
      reorder: true,
      wrap: true,
    },
    {
      name: "Category Name",
      selector: "category_name",
      width: "200px",
      sortable: true,
      reorder: true,
    },
    {
      name: "Product Name",
      selector: "product_name",
      sortable: true,
      reorder: true,
    },
    {
      name: "Total QTY(KGs)",
      selector: "total_qty",
      center: true,
      sortable: true,
      reorder: true,
      minWidth: "150px",
    },
    {
      name: "Avg Sale Price",
      selector: "avg_sale_price",
      cell: (row) => `₹ ${NumberFormat(row.avg_sale_price)}`,
      center: true,
      sortable: true,
      reorder: true,
      right: true,
      minWidth: "150px",
    },
    {
      name: "Sale Amount(INR)",
      selector: "sale_amount",
      cell: (row) => `₹ ${NumberFormat(row.sale_amount)}`,
      right: true,
      sortable: true,
      reorder: true,
      minWidth: "150px",
    },
    {
      name: "Avg Purchase Price",
      selector: "avg_purchase_price",
      cell: (row) => `₹ ${NumberFormat(row.avg_purchase_price)}`,
      center: true,
      sortable: true,
      reorder: true,
      right: true,
      minWidth: "160px",
    },
    {
      name: "Purchase Amount",
      selector: "purchase_amount",
      cell: (row) => `₹ ${NumberFormat(row.purchase_amount)}`,
      right: true,
      sortable: true,
      reorder: true,
      minWidth: "150px",
    },
    {
      name: "P/L",
      selector: "pnl",
      cell: (row) => `₹ ${NumberFormat(row.pnl)}`,
      sortable: true,
      right: true,
      reorder: true,
      minWidth: "150px",
    },
  ];

  const HandleSelect = (selectedOption, name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: selectedOption.value,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSort = (column, direction) => {
    console.log(column, direction);
  };

  const handleSearch = (value) => setSearch(value);

  const GetFilterData = async () => {
    if (subcategory.length > 0 && category.length > 0) return;
    try {
      const subcategory = await axiosConfig.get(
        `/catlog/sub-categories/?page_size=100&is_suspended=false`
      );
      const category = await axiosConfig.get(
        `/catlog/categories/?page_size=100&is_suspended=false`
      );
      const client = await axiosConfig.get(
        `/clients/clients/?page_size=1000&is_suspended=false`
      );
      const subcategory_data = subcategory.data.results;
      const category_data = category.data.results;
      const client_data = client.data.results;
      setSubcategory(subcategory_data);
      setClients(client_data);
      setCategory(category_data);
    } catch (error) {
      console.log(error);
    }
  };

  const GetProductData = async () => {
    try {
      const product = await axiosConfig.get(
        `/catlog/products/?page_size=20&name=${search}&is_suspended=false`
      );
      console.log(product, "product");
      const product_data = product.data.results;
      setNextPageUrl(product.data.next);
      setProducts(product_data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleScroll = async () => {
    if (!loading && nextPageUrl) {
      setLoading(true);
      const product = await axiosConfig.get(nextPageUrl);
      const product_data = product.data.results;
      setNextPageUrl(product.data.next);
      setProducts([...products, ...product_data]);
      setLoading(false);
    }
  };

  const GetReport = async () => {
    setLoading(true);
    try {
      const params = {
        client: formData.client || "",
        from_date: formData.fromDate,
        to_date: formData.toDate,
        product: formData.product || "",
        category: formData.category || "",
        subcategory: formData.subcategory || "",
        page: currentPage,
        page_size: RowPerPage,
      };

      const queryString = new URLSearchParams(params).toString();
      const response = await axiosConfig.get(
        `/accounts/product_sale_pl_report/?${queryString}`
      );
      setData(response.data.results);
      setTotalRows(response.data.count);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    GetProductData();
  }, [search]);

  useEffect(() => {
    GetReport();
    GetFilterData();
  }, [currentPage, RowPerPage]);
  return (
    <DashboardLayout>
      <div className="child-sidenav">
        <ReportsNav />
        <div className="flex-1 childnav-content">
          <div className="report-filters">
            <Select
              className="flex-1"
              placeholder="All Items"
              options={[{ label: "All Items", value: null }].concat(
                products.map((product) => ({
                  label: product.name,
                  value: product.id,
                }))
              )}
              onChange={(selectedOption) =>
                HandleSelect(selectedOption, "product")
              }
              onMenuScrollToBottom={handleScroll}
              isLoading={loading}
              onInputChange={handleSearch}
              styles={multiSelectStyles}
            />
            <div className="form-group flex-1">
              <input
                className="form-control"
                type="date"
                name="fromDate"
                value={formData.fromDate}
                onChange={handleChange}
              />
            </div>
            <div className="form-group flex-1">
              <input
                className="form-control"
                type="date"
                name="toDate"
                value={formData.toDate}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="report-filters">
            <Select
              className="flex-1"
              placeholder="All Clients"
              options={[{ label: "All Clients", value: null }].concat(
                clients.map((client) => ({
                  label: client.client_name,
                  value: client.id,
                }))
              )}
              onChange={(selectedOption) =>
                HandleSelect(selectedOption, "client")
              }
              styles={multiSelectStyles}
            />

            <Select
              className="flex-1"
              placeholder="All Categories"
              options={[{ label: "All Categories", value: null }].concat(
                category.map((item) => ({ label: item.name, value: item.id }))
              )}
              onChange={(selectedOption) =>
                HandleSelect(selectedOption, "category")
              }
            />
            <Select
              className="flex-1"
              placeholder="All Subcategories"
              options={[{ label: "All Subcategories", value: null }].concat(
                subcategory.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))
              )}
              onChange={(selectedOption) =>
                HandleSelect(selectedOption, "subcategory")
              }
            />

            <div
              style={{ minWidth: "143px", height: "37px" }}
              className="d-flex justify-content-center"
            >
              {loading ? (
                <img
                  src={require("../Components/FormParser/images/loader.gif")}
                  className="loader-image"
                  alt="loader"
                />
              ) : (
                <button
                  className="btn btn-primary"
                  style={{ height: "37px" }}
                  onClick={GetReport}
                >
                  Get Reports
                </button>
              )}
            </div>
          </div>
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationTotalRows={totalRows}
              title={<CustomHeader />}
              paginationPerPage={RowPerPage}
              paginationDefaultPage={currentPage}
              onChangePage={(page) => setCurrentPage(page)}
              paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
              paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
              onChangeRowsPerPage={(RowsPerPageChange) =>
                setRowPerPage(RowsPerPageChange)
              }
              onSort={handleSort}
              paginationServer
              customStyles={tableCustomStyles}
              progressPending={loading}
              fixedHeader={true}
              fixedHeaderScrollHeight="70vh"
              progressComponent={
                <div className="loader-container">
                  <img
                    src={require("../Components/FormParser/images/loader2.gif")}
                    alt="Loading..."
                  />
                </div>
              }
            />
        </div>
      </div>
    </DashboardLayout>
  );
};

export { SalesReport };
