import React, { useEffect, useState, useRef } from "react";
import { DashboardLayout, FormParser, PopupModal, DeletePopupModal } from "../Components";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import Masters from "../Components/masters.json"
import { MdOutlineEdit } from "react-icons/md"
import { TiTick } from "react-icons/ti"
import axiosConfig from "../Service/axiosConfig"
import { useParams, useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';

const ClientTariff = ({ Code }) => {
    let { id } = useParams();
    console.log(id, "iddddd")
    const MastersModal = Masters.ModifyTariff
    const [Data, SetData] = useState('')
    const [loading, setLoading] = useState(false)
    const [RowId, setRowId] = useState()
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [popupType, setpopupType] = useState("")
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
    const [TariffId, setTariffId] = useState()
    const [PriceChange, setPriceChange] = useState()
    const [deletePopupType, setDeletepopupType] = useState("");
    const [Error, setError] = useState("");
    const [LoadingButton, setLoadingButton] = useState();
    const [message, setMessage] = useState("")
    const GetData = () => {
        setLoading(true)
        axiosConfig.get(`clients/tariffs/?client=${id}&product=&price=&page=${currentPage}&page_size=${RowPerPage}`).then(res => {
            console.log(res.data.results)
            SetData(res.data.results)
            setLoading(false)
            setTotalRows(res.data.count)

        }).catch(error => {
            console.log(error)
        })
    }
    const handleSort = (column, direction) => {
        console.log(column.selector, direction)
        axiosConfig.get(`/clients/tariffs/?page=${currentPage}&page_size=${RowPerPage}&ordering=${direction === "asc" ? column.selector : -column.selector}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };
    const HandleMouse = (row) => {
        setRowId(row.id)
    }
    const HandleRowmouseLeave = () => {
        setRowId()
    }
    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "center"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "center",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    };
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true,
            width: '80px'
        },
        {
            name: "Product Image",
            width: '150px',
            selector: row => <>
                <img className="image" src={row.product.image && row.product.image.image} alt={row.product.image && row.product.image.image} />
            </>,
            sortable: true
        },
        {
            name: "Product Name",
            width: '150px',
            selector: "product.name",
            cell: row => row.product.name,
            sortable: true
        },
        {
            name: "Base Price",
            selector: "product.price",
            cell: row => row.product.price,
            sortable: true
        },
        {
            name: "Price",
            selector: "price",
            sortable: true,
            cell: row => {
                if (TariffId === row.id) {
                    return (
                        <input
                            className="form-control"
                            type="number"
                            value={PriceChange}
                            onChange={(e) => setPriceChange(e.target.value)}
                        />
                    );
                } else {
                    return row.price;
                }
            }
        },
        {
            name: "Status",
            width: '150px',
            selector: (row) => {
                const HandleEdit = async () => {
                    setTariffId(row.id);
                    setPriceChange(row.price)
                    console.log(row.id, 'iddddd')
                };

                return (
                    <>
                        {RowId === row.id ? (
                            <>
                                {TariffId === row.id ? (
                                    <div className="row">
                                        <div className="col-12" onClick={() => handlePriceChange(row.id, row.price)}>
                                            <TiTick className="client-actions" />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row">
                                        <div className="col-12" onClick={() => HandleEdit(row)}>
                                            <MdOutlineEdit className="client-actions" />
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="client-type contract-background">Active</div>
                        )}
                    </>
                )

            }
        }
    ]
    const [show, setShow] = useState(false)
    const handleClose = () => {
        setShow(false);
        setpopupType("")
        setError("")
    }
    const handleShow = () => {
        setShow(true);
        setError("")
    }
    const handleDownloadExcel = () => {
        setLoading(true)
        axiosConfig.get(`clients/download_tariffs_csv/${id}/`).then(res => {
            console.log(res.data)
            const csvData = res.data;
            const blob = new Blob([csvData], { type: 'text/csv' });
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.download = 'tariffs.csv';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            setLoading(false)
            setTotalRows(res.data.count)

        }).catch(error => {
            console.log(error)
        })
    }
    const formSubmit = (Data, setFieldErrors) => {
        console.log(Data)
        setLoadingButton(true)
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        axiosConfig.post(`/clients/upload_csv/${id}/`, Data).then(res => {
            console.log(res)
            setError("");
            setLoadingButton(false);
            GetData();
            setMessage('File uploaded Sucessfully')
            setpopupType("success")
            setShow(false)
        }).catch(error => {
            console.log(error)
            setLoadingButton(false)
            if (error.message === "Network Error") {
                setFieldErrors(prev => ({ ...prev, ["csv_file"]: "Network Error." }));
                setError("Network Error")
            }
            if (error.response.data.error) {
                setFieldErrors(prev => ({ ...prev, ["csv_file"]: "Uploaded tariff file is not related to this client" }));
                setError(error.response.data.error)
            }
            if (error.response.data.message) {
                setFieldErrors(prev => ({ ...prev, ["csv_file"]: "Tariff matching query does not exist." }));
                setError(error.response.data.message)
            }
            setMessage('File not uploaded Sucessfully')
            setpopupType("error")
        })

    }
    const handlePriceChange = (id) => {
        console.log(id, PriceChange)
        axiosConfig.patch(`/clients/tariffs/${id}/`, { price: PriceChange }).then(res => {
            console.log(res)
            GetData()
            setTariffId(null);
            setMessage('Price updated Sucessfully')
            setpopupType("success")
        })
            .catch(error => {
                console.log(error)
                setMessage('price not Updated Sucessfully')
                setError(error.response.data.price)
                setpopupType("error")
            })
    }
    useEffect(() => {
        GetData()
    }, [currentPage, RowPerPage])
    return (
        <>
            <div className="table-responsive marginTop">
                <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                    paginationPerPage={RowPerPage}
                    paginationDefaultPage={currentPage}
                    onChangePage={(page) => setCurrentPage(page)}
                    paginationPerPageOptions={[10, 20, 25, 30, 40, 50, totalRows]}
                    paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50, totalRows]}
                    onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                    onSort={handleSort}
                    onRowMouseEnter={HandleMouse}
                    onRowMouseLeave={HandleRowmouseLeave}
                    highlightOnHover
                    paginationServer fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                    progressComponent={<div className="loader-container">
                        <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                    </div>}
                />
            </div>
            <div className="excel-button">
                <button type="submit" className="btn btn-primary" onClick={handleDownloadExcel}>Download Excel</button>
                <button type="submit" className="btn btn-primary" onClick={handleShow}>Upload Excel</button>
            </div>
            <Modal
                size="lg"
                show={show}
                onHide={handleClose}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Modify Tariff
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        {/* {
                            loading ?
                                <div className="loader-container">
                                    <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                </div>
                                : null
                        } */}
                        <FormParser modelObject={MastersModal} formSubmit={formSubmit} LoadingButton={LoadingButton} button="btn-block" />
                    </div>
                </Modal.Body>
            </Modal>
            {deletePopupType != "" &&
                <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
            }
            {popupType != "" &&
                <PopupModal setpopupType={setpopupType} popupType={popupType} Message={message} error={Error} />
            }

        </>
    )

}
export { ClientTariff }