import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal } from "../Components";
import { SettingNavbarComponent } from "../Components/navbar/settingsnav";
import { MdOutlineEdit } from "react-icons/md"
import { FormParser } from "../Components";
import Masters from "../Components/masters.json";
import { FileManager } from "../Components/FileManager";
import { Modal } from 'react-bootstrap';
import axiosConfig from "../Service/axiosConfig";
import { AiOutlinePlus } from "react-icons/ai"

const Sitesetting = () => {
    const MastersModal = Masters.SiteSettings;
    const [siteSettings, setSiteSettings] = useState([])
    const [readOnly, setReadOnly] = useState(true);
    const [show, setShow] = useState(false);
    const [Error, SetError] = useState("");
    const [popupType, setpopupType] = useState("");
    const handleShow = () => {
        setShow(true)
    }
    const GetSiteSettings = async () => {
        try {
            const responce = await axiosConfig.get(`/sitedata/site/`)
            console.log(responce.data, "site settings")
            if (responce.data.results.length > 0) {
                setSiteSettings(responce.data.results[0])
            }
        }
        catch (error) {
            console.log(error)
        }

    }

    const formSubmit = async (Data) => {
        try {
            const updatedData = { ...Data };
            updatedData.logo = Data.logo.id;
            updatedData.favicon = Data.favicon.id;
            updatedData.banner = Data.banner.id;
            axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
            if (Data.id) {
                const response = await axiosConfig.patch(`/sitedata/site/${Data.id}/`, updatedData)
                console.log("updated site settings successfully : ", response)
            } else {
                const response = await axiosConfig.post(`/sitedata/site/`, updatedData)
                console.log("created site settings successfully : ", response)
            }
            setpopupType("success")
            setReadOnly(true)
        } catch (error) {
            console.log("Error setting the site data : ", error)
            setpopupType("error")
        }
    }
    const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];
        console.log(e.target, name, "banner check")
        if (file) {
            setSiteSettings((prev) => ({
                ...prev,
                [name]: file
            }))
        }
        let ddd = siteSettings;
        console.log({ ...ddd, [name]: file }, "inside handlefilechange......")
    };

    useEffect(() => {
        GetSiteSettings();
    }, [])

    return (
        <>
            <DashboardLayout>
                <div className="child-sidenav">
                    <SettingNavbarComponent />
                    <div className="childnav-content mt-2">
                        <FormParser
                            modelObject={MastersModal}
                            buttonTitle="Save"
                            readOnly={readOnly}
                            setReadOnly={setReadOnly}
                            formData={siteSettings}
                            formSubmit={formSubmit}
                            error={Error}
                        />
                        {popupType != "" &&
                            <PopupModal popupType={popupType} setpopupType={setpopupType} Navigation="/dashboard/masters/sitesettings" />
                        }
                        {readOnly &&
                            <button className={`btn btn-primary d-flex align-items-center`} onClick={() => setReadOnly(false)}>
                                <div>Edit</div>
                                <MdOutlineEdit className="ms-5" />
                            </button>
                        }
                    </div>
                </div>
            </DashboardLayout>
        </>
    )
}
export { Sitesetting }