import React, { useState, useEffect } from "react";
import { DashboardLayout } from "../Components";
import { ReportsNav } from "../Components/navbar";
import DataTable from "react-data-table-component";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import axiosConfig from "../Service/axiosConfig";
import Select from "react-select";
import { AiOutlineFilePdf, AiOutlineFileExcel } from "react-icons/ai";
import { downloadExcel } from "react-export-table-to-excel";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import NumberFormat from "../Utils/NumberFormat";

const DateWiseSales = () => {
  const [data, setData] = useState([]);
  const [stores, setStores] = useState([]);
  const [clients, setClients] = useState([]);
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState([]);
  const [stats, setStats] = useState({});
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [RowPerPage, setRowPerPage] = useState(20);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { date, product_name, product, from, to } = useParams();
  const [formData, setFormData] = useState({
    fromDate: date
      ? date
      : from
      ? from
      : new Date().toISOString().split("T")[0],
    toDate: date ? date : to ? to : new Date().toISOString().split("T")[0],
    store: "",
    product: product ? product : "",
    category: "",
    client: "",
  });
  const [nextPageUrl, setNextPageUrl] = useState("");
  const [search, setSearch] = useState("");
  const Navigate = useNavigate();

  const columns = [
    {
      name: "S. No.",
      selector: (row, index) => index + 1,
      sortable: true,
      compact: true,
      center: true,
    },
    {
      name: "Date",
      selector: "date",
      cell: (row) => row.date.split(" ")[0],
      reorder: true,
      sortable: true,
    },
    {
      name: "Amount",
      selector: "total_amount",
      cell: (row) => `₹ ${NumberFormat(row.total_amount)}`,
      minWidth: "150px",
      right: true,
      sortable: true,
      reorder: true,
    },
    {
      name: "Total Products",
      selector: "total_quantity",
      sortable: true,
      center: true,
      reorder: true,
    },
    {
      name: "Items Quantity Total",
      selector: (row) =>
        row.total_products_quantity && row.total_products_quantity !== 0
          ? row.total_products_quantity
          : "-",
      sortable: true,
      minWidth: "200px",
      reorder: true,
      center:true
    },
    {
      name: "",
      cell: (row) => (
        <a
          href={`/dashboard/masters/item_client_reports/${encodeURIComponent(
            row.date.split(" ")[0]
          )}/details`}
        >
          Details
        </a>
      ),
    },
  ];

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSort = (column, direction) => {
    console.log(column, direction);
    // axiosConfig.get(`/accounts/orders/?orderstatus=Delivered&store=${formData.store ? formData.store : ''}&${selectedDateType === 'ordered_date' ? 'order_date_after' : 'expected_date_time_before'}=${formData.fromDate}&${selectedDateType === 'ordered_date' ? 'order_date_before' : 'expected_date_time_after'}=${formData.toDate}&page=${currentPage}&page_size=${RowPerPage}&ordering=${direction === "asc" ? column.name : `-${column.selector}`}`).then(res => {
    //     SetData(res.data.results)
    // setStats({
    //     "total_qty": response.data.total_qty,
    //     "total_amount": response.data.total_amount,
    //     "highest_sale_quantity": response.data.highest_sale_quantity,
    //     "lowest_sale_quantity": response.data.lowest_sale_quantity,
    //     "highest_sale_amount": response.data.highest_sale_amount,
    //     "lowest_sale_amount": response.data.lowest_sale_amount,
    //     "average_amount": response.data.average_amount,
    //     "average_qty": response.data.average_qty
    // })
    // }).catch(error => {
    //     console.log(error)
    // })
    // setCurrentPage(1);
  };

  const header = [
    "S.No",
    "Date",
    "Amount",
    "Total Products Quantity",
    "Quantity",
  ];
  const body = data.map((res, index) => [
    index + 1,
    res.date,
    res.total_amount,
    res.total_products_quantity,
    res.total_quantity,
  ]);

  function handleDownloadExcel() {
    downloadExcel({
      fileName: "Client & Product Report",
      sheet: "Client & Product Report",
      tablePayload: {
        header: header,
        body: body,
      },
    });
  }

  const HandlePdf = (data) => {
    localStorage.setItem("pdf_data", JSON.stringify(data));
    window.open(`${window.location.origin}/print`, "_blank");
  };

  const CustomHeader = () => {
    return (
      <div className="rowEndCenter gap-10">
        <AiOutlineFileExcel
          className="report-icons"
          onClick={handleDownloadExcel}
        />
        <AiOutlineFilePdf
          className="report-icons"
          onClick={() => HandlePdf(data)}
        />
      </div>
    );
  };

  const HandleSelect = (selectedOption, name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: selectedOption.value,
    }));
  };
  const GetProductData = async () => {
    try {
      const product = await axiosConfig.get(
        `/catlog/products/?page_size=20&name=${search}&is_suspended=false`
      );
      console.log(product, "product");
      const product_data = product.data.results;
      setNextPageUrl(product.data.next);
      setProducts(product_data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleScroll = async () => {
    if (!loading && nextPageUrl) {
      setLoading(true);
      const product = await axiosConfig.get(nextPageUrl);
      const product_data = product.data.results;
      setNextPageUrl(product.data.next);
      setProducts([...products, ...product_data]);
      setLoading(false);
    }
  };

  const handleSearch = (value) => setSearch(value);

  const GetFilterData = async () => {
    try {
      const store = await axiosConfig.get(`/stores/stores/?page_size=100`);
      const category = await axiosConfig.get(
        `/catlog/categories/?page_size=100&is_suspended=false`
      );
      const client = await axiosConfig.get(
        `/clients/clients/?page_size=1000&is_suspended=false`
      );
      const store_data = store.data.results;
      const category_data = category.data.results;
      const client_data = client.data.results;
      setStores(store_data);
      setClients(client_data);
      setCategory(category_data);
    } catch (error) {
      console.log(error);
    }
  };

  const GetData = async () => {
    setIsLoading(true);
    try {
      const response = await axiosConfig.get(
        `/accounts/generate_date_sale_reports/?from_date=${
          formData.fromDate
        }&to_date=${formData.toDate}&product=${formData.product || ""}&store=${
          formData.store || ""
        }&category=${formData.category || ""}&client=${formData.client || ""}`
      );
      const data = response.data.data;
      console.log("Datewise Data", data);
      setData(data);
      setStats({
        total_qty: response.data.total_qty,
        total_amount: response.data.total_amount,
        highest_sale_quantity: response.data.highest_sale_quantity,
        lowest_sale_quantity: response.data.lowest_sale_quantity,
        highest_sale_amount: response.data.highest_sale_amount,
        lowest_sale_amount: response.data.lowest_sale_amount,
        average_amount: response.data.average_amount,
        average_qty: response.data.average_qty,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    GetProductData();
  }, [search]);

  useEffect(() => {
    GetData();
    GetFilterData();
  }, []);

  return (
    <>
      <DashboardLayout>
        <div className="child-sidenav">
          <ReportsNav />
          <div className="flex-1 childnav-content">
            <div className="report-filters">
              {!product ? (
                <Select
                  className="flex-1"
                  placeholder="All Items"
                  options={[{ label: "All Items", value: null }].concat(
                    products.map((product) => ({
                      label: product.name,
                      value: product.id,
                    }))
                  )}
                  onChange={(selectedOption) =>
                    HandleSelect(selectedOption, "product")
                  }
                  onMenuScrollToBottom={handleScroll}
                  isLoading={loading}
                  onInputChange={handleSearch}
                />
              ) : (
                <div className="f_14_500" style={{ margin: "8px 0px" }}>
                  Product : <span>{product_name}</span>
                </div>
              )}
              <div className="form-group flex-1">
                <input
                  className="form-control"
                  type="date"
                  name="fromDate"
                  value={formData.fromDate}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group flex-1">
                <input
                  className="form-control"
                  type="date"
                  name="toDate"
                  value={formData.toDate}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="report-filters">
              <Select
                className="flex-1"
                placeholder="All Categories"
                options={[{ label: "All Categories", value: null }].concat(
                  category.map((product) => ({
                    label: product.name,
                    value: product.id,
                  }))
                )}
                onChange={(selectedOption) =>
                  HandleSelect(selectedOption, "category")
                }
              />
              <Select
                className="flex-1"
                placeholder="All Stores"
                options={[{ label: "All Stores", value: null }].concat(
                  stores.map((product) => ({
                    label: product.name,
                    value: product.id,
                  }))
                )}
                onChange={(selectedOption) =>
                  HandleSelect(selectedOption, "store")
                }
              />
              <Select
                className="flex-1"
                placeholder="All Clients"
                options={[{ label: "All Clients", value: null }].concat(
                  clients.map((client) => ({
                    label: client.client_name,
                    value: client.id,
                  }))
                )}
                onChange={(selectedOption) =>
                  HandleSelect(selectedOption, "client")
                }
              />
              <div
                style={{ minWidth: "143px", height: "37px" }}
                className="d-flex justify-content-center"
              >
                {isLoading ? (
                  <img
                    src={require("../Components/FormParser/images/loader.gif")}
                    className="loader-image"
                    alt="loader"
                  />
                ) : (
                  <button
                    className="btn btn-primary"
                    style={{ height: "37px" }}
                    onClick={GetData}
                  >
                    Get Reports
                  </button>
                )}
              </div>
            </div>
            <div>
              <div
                className=" row rowBetweenCenter f_14_500"
                style={{ margin: "16px" }}
              >
                <div className="col-3">Total Rs.</div>
                <div className="col-3 f_14_400">{`₹ ${NumberFormat(stats.total_amount)}`}</div>
                <div className="col-3">Total Qty</div>
                <div className="col-3 f_14_400">{stats.total_qty}</div>
              </div>
            </div>
            <div>
              <div
                className=" row rowBetweenCenter f_14_500"
                style={{ margin: "16px" }}
              >
                <div className="col-3">Daily Avg</div>
                <div className="col-3 f_14_400">{`₹ ${NumberFormat(stats.average_amount)}`}</div>
                <div className="col-3">Daily Avg Qty</div>
                <div className="col-3 f_14_400">{stats.average_qty}</div>
              </div>
            </div>
            <div>
              <div
                className=" row rowBetweenCenter f_14_500"
                style={{ margin: "16px" }}
              >
                <div className="col-3">Lowest Sale Rs.</div>
                <div className="col-3 f_14_400">{`₹ ${NumberFormat(stats.lowest_sale_amount)}`}</div>
                <div className="col-3">Highest Qty</div>
                <div className="col-3 f_14_400">
                  {stats.highest_sale_quantity}
                </div>
              </div>
            </div>
            <div>
              <div
                className=" row rowBetweenCenter f_14_500"
                style={{ margin: "16px" }}
              >
                <div className="col-3">Highest Sale Rs.</div>
                <div className="col-3 f_14_400">
                  {`₹ ${NumberFormat(stats.highest_sale_amount)}`}
                </div>
                <div className="col-3">Lowest Qty</div>
                <div className="col-3 f_14_400">
                  {stats.lowest_sale_quantity}
                </div>
              </div>
            </div>
              <DataTable
                columns={columns}
                data={data}
                pagination
                paginationTotalRows={totalRows}
                title={<CustomHeader />}
                paginationPerPage={RowPerPage}
                paginationDefaultPage={currentPage}
                onChangePage={(page) => setCurrentPage(page)}
                paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                onChangeRowsPerPage={(RowsPerPageChange) =>
                  setRowPerPage(RowsPerPageChange)
                }
                onSort={handleSort}
                paginationServer
                customStyles={tableCustomStyles}
                progressPending={loading}
                fixedHeader={true}
                fixedHeaderScrollHeight="70vh"
                progressComponent={
                  <div className="loader-container">
                    <img
                      src={require("../Components/FormParser/images/loader2.gif")}
                      alt="Loading..."
                    />
                  </div>
                }
              />
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export { DateWiseSales };
