import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import Masters from "../Components/masters.json";
import axiosConfig from "../Service/axiosConfig";
import { useNavigate, NavLink } from "react-router-dom";
import { SettingNavbarComponent } from "../Components/navbar/settingsnav";
const AddStore = () => {
    const MastersModal = Masters.AddStore
    const navigate = useNavigate();
    const [popupType, setpopupType] = useState("")
    const [Error, SetError] = useState("")
    const formSubmit = (Data, setFieldErrors) => {
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        const formData = new FormData();
        formData.append("address", Data.address);
        formData.append("latitude", Data.latitude);
        formData.append("longitude", Data.longitude);
        formData.append("mobile", Data.mobile);
        formData.append("email", Data.email);
        formData.append("name", Data.name);
        formData.append("password", Data.password);
        if (Data.banner) {
            formData.append("banner_image", Data.banner.id);
        }
        if (Data.logo) {
            formData.append("logo_image", Data.logo.id);
        }

        axiosConfig.post(`/stores/stores/`, formData).then(res => {
            console.log(res)
            // navigate('/dashboard/settings/stores');
            setpopupType("success")
        }).catch(error => {
            console.log(error)
            // if(ErrorData[0].code[0]==="priority with this code already exists."){
            //     SetError(" code already exists.")
            // }
            // if(ErrorData[0].name[0]==="priority with this name already exists."){
            //     SetError("Tittle already Exists")
            // }
            // if (error.response.data.detail) {
            //     SetError(prev => ({ ...prev, ["mobile"]: "User with this mobile number already exists." }));
            // }
            SetError(error.response.data.detail)
            setpopupType("error")
        })
    }

    useEffect(() => {
    }, [])
    return (
        <>
            <DashboardLayout>
                <div className="child-sidenav">
                    <SettingNavbarComponent />
                    <div className="childnav-content">
                        <div className="dashboard-header" style={{ alignItems: 'center' }}>
                            <h5 className="dashboard-subtitle">Add Store</h5>
                            <NavLink to="/dashboard/settings/stores" style={{ textDecoration: 'none' }} ><button className="btn btn-white">Cancel</button></NavLink>
                        </div>
                        <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error} />
                        {popupType != "" &&
                            <PopupModal popupType={popupType} setpopupType={setpopupType} Navigation="/dashboard/settings/stores" error={Error} />
                        }
                    </div>
                </div >
            </DashboardLayout >
        </>
    )
}

export { AddStore }