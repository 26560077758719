import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { MdOutlineMailOutline, MdOutlinePhone, MdKeyboardArrowRight } from "react-icons/md";
import { RiMapPin2Line, RiDeleteBin6Line } from "react-icons/ri"
import axiosConfig from "../../Service/axiosConfig"
import { PopupModal } from '../PopupModal/PopupModal';
const Paymentmodal = ({ showModal, handleClose, customData, formatTimestamp, GetData }) => {
    const [popupType, setpopupType] = useState("");
    const [error, setError] = useState("");
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState("");
    const [Data, setData] = useState({
        "client": customData.client?.id || customData.Client_id,
        "amount": "",
        "payment_type": "",
        "reference": ""
    })
    const [month, setMonth] = useState({
        monthUnBilled: "",
        Total_outStanding: "",
        last_paid: "",
        last_bil_due: "",
        due_date: "",
        generated_data: ""
    })
    const [selectedOption, setSelectedOption] = useState('');
    const HandleRadioChange = (e, name, value) => {
        if (name === "pay_due_amount") {
            console.log(month.last_bil_due)
            setData({ ...Data, ["amount"]: month.last_bil_due });
            setSelectedOption(name)
        }
        else if (name === "pay_other_amount") {
            setData({ ...Data, ["amount"]: value })
            setSelectedOption(name)
        }
        else {
            setData({ ...Data, [name]: value });
        }
    }
    const AccountSummary = async (id) => {
        try {
            const Summary = await axiosConfig.get(`/accounts/account_summary/?client_id=${customData.client?.id || customData.Client_id}`);
            console.log(Summary.data[0].Current_month_unbilled, "summary")
            let summary_data = Summary.data.length > 0 ? Summary.data[0] : {};
            setMonth(prevMonth => ({
                ...prevMonth,
                monthUnBilled: summary_data.Current_month_unbilled,
                Total_outStanding: summary_data.Total_outstanding,
                last_paid: summary_data.Last_paid?.amount,
                last_bil_due: summary_data.Last_billed_due?.total_amount_due,
                due_date: summary_data.last_billed_due?.duedate,
                generated_data: summary_data.Last_paid?.datetime.split("T")[0]
            }));
        } catch (error) {
            console.error('Error removing token:', error);
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target
        setData({ ...Data, [name]: value });
    }
    console.log(Data)
    const formSubmit = async () => {
        await axiosConfig.post("/accounts/payments/", Data).then(res => {
            console.log(res)
            setpopupType("success")
            setData({})
            GetData && GetData()
        }).catch(error => {
            console.log(error.response.data)
            setErrors(error.response.data)
            setpopupType("error")
        })
    }
    useEffect(() => {
        AccountSummary()
    }, [customData])
    return (
        <Modal show={showModal} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Make Payment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className='row'>
                        <div className="col-6 client-detail d-flex align-items-center" style={{ borderRight: '1px solid #000' }}>
                            <div className="client-logo" style={{ width: "82px", height: "82px" }}>
                                <img src={(customData.client?.logo && customData.client?.logo.image) || customData.Client_logo?.image} style={{ width: "100%", height: "100%" }} alt="logo" />
                            </div>
                            <div className="d-flex flex-column justify-content-between">
                                <div>
                                    <div className="client-name my-4">{customData.client?.client_name || customData.Client_name}</div>
                                    <div className={`client-type ${customData.client?.client_type || customData.Client_type === 'Contracted' ? 'contract-background my-4' : 'variable-background my-4'}`}>{customData.client?.client_type || customData.Client_type}</div>
                                </div>
                            </div>
                            <div className="store-info mx-4 d-flex flex-column">
                                <div className="contact-details"><RiMapPin2Line className="contact-icon" />{customData.client?.address || customData.Client_address}</div>
                                <div className="contact-email"><MdOutlineMailOutline className="contact-icon" />{customData.client?.email || customData.Client_email}</div>
                                <div className="contact-phone"><MdOutlinePhone className="contact-icon" />{customData.client?.mobile || customData.Client_mobile}</div>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='d-flex justify-content-around align-items-center'>
                                <div>
                                    <p>Billing Period</p>
                                    <p>Previous Month Due</p>
                                    <p>Payments Paid</p>
                                    <p>Total Purchase</p>
                                    <p>Due Date</p>
                                    <p>Generated On</p>
                                </div>
                                <div>
                                    <p>:</p>
                                    <p>:</p>
                                    <p>:</p>
                                    <p>:</p>
                                    <p>:</p>
                                    <p>:</p>
                                </div>
                                {console.log(customData, "customdata")}
                                <div>
                                    <p>{customData.period_from} <b>-</b> {customData.period_to}</p>
                                    <p>{customData.previous_due}</p>
                                    <p>{customData.payments}</p>
                                    <p>{customData.purchase}</p>
                                    <p>{formatTimestamp(customData.duedate)}</p>
                                    <p>{formatTimestamp(customData.generated_date)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=' my-4 d-flex justify-content-between'>
                        <div className='box-card-payment'>
                            <div className='form-group'>
                                <label className='text-decoration-underline'>Current Month/Unbilled</label>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <div style={{ color: "#000", fontSize: "13px", fontWeight: "700" }}>
                                            {month.monthUnBilled}
                                        </div>
                                    </div>
                                    <div>
                                        <MdKeyboardArrowRight />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='box-card-payment'>
                            <div className='form-group'>
                                <label className='text-decoration-underline'>Total Outstanding</label>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <div style={{ color: "#000", fontSize: "13px", fontWeight: "700" }}>
                                            {month.Total_outStanding}
                                        </div>
                                    </div>
                                    <div>
                                        <MdKeyboardArrowRight />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='box-card-payment'>
                            <div className='form-group'>
                                <label className='text-decoration-underline'>Last Paid</label>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <div style={{ color: "#000", fontSize: "13px", fontWeight: "700" }}>
                                            {month.last_paid}
                                        </div>
                                        <div style={{ color: "#8C8C8C", fontSize: "10px" }}>
                                            {month.generated_data}
                                        </div>
                                    </div>
                                    <div>
                                        <MdKeyboardArrowRight />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='box-card-payment'>
                            <div className='form-group'>
                                <label className='text-decoration-underline'>Last Billed Due</label>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <div style={{ color: "#000", fontSize: "13px", fontWeight: "700" }}>
                                            {month.last_bil_due}
                                        </div>
                                        <div style={{ color: "#8C8C8C", fontSize: "10px" }}>
                                            {month.due_date}
                                        </div>
                                    </div>
                                    <div>
                                        <MdKeyboardArrowRight />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='row'>
                            <div className='col-6'>
                                <div className='row align-items-center'>
                                    {errors.amount && <p className='Loginerror'>Please select amount and enter value</p>}
                                    <div className='col-6'>
                                        <div className="form-group">
                                            <div className="form-check-inline">
                                                <label className="form-check-label ari-radio-label">
                                                    <input type="radio" style={{ width: "15px", height: "15px" }} name="" onChange={(e) => HandleRadioChange(e, "pay_due_amount")} checked={selectedOption === "pay_due_amount"} />Pay Due Amount
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className="form-group">
                                            <input type="text" className="form-control" name='amount' value={selectedOption === "pay_due_amount" ? Data.amount : ""} onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className='row align-items-center'>
                                    <div className='col-6'>
                                        <div className="form-group">
                                            <div className="form-check-inline">
                                                <label className="form-check-label ari-radio-label">
                                                    <input type="radio" style={{ width: "15px", height: "15px" }} name="" onChange={(e) => HandleRadioChange(e, "pay_other_amount")} checked={selectedOption === "pay_other_amount"} />Pay Other Amount
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className="form-group">
                                            <input type="text" className="form-control" name='amount' onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-4'>
                                {errors.payment_type && <p className='Loginerror'>Please select payment type</p>}
                                <div className='form-group m-0'>
                                    <label className='text-decoration-underline'>Payment Type:</label>
                                </div>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className="form-group">
                                            <div className="form-check-inline">
                                                <label className="form-check-label ari-radio-label">
                                                    <input type="radio" style={{ width: "15px", height: "15px" }} name="paymentType" value="cash" onChange={(e) => HandleRadioChange(e, "payment_type", "cash")} />Cash
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className="form-group">
                                            <div className="form-check-inline">
                                                <label className="form-check-label ari-radio-label">
                                                    <input type="radio" style={{ width: "15px", height: "15px" }} name="paymentType" value="card" onChange={(e) => HandleRadioChange(e, "payment_type", "card")} />Card
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className="form-group">
                                            <div className="form-check-inline">
                                                <label className="form-check-label ari-radio-label">
                                                    <input type="radio" style={{ width: "15px", height: "15px" }} name="paymentType" value="online" onChange={(e) => HandleRadioChange(e, "payment_type", "online")} />Online
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className="form-group">
                                            <div className="form-check-inline">
                                                <label className="form-check-label ari-radio-label">
                                                    <input type="radio" style={{ width: "15px", height: "15px" }} name="paymentType" value="others" onChange={(e) => HandleRadioChange(e, "payment_type", "others")} />Other
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=''>
                        {errors.reference && <p className='Loginerror'>Please provide reference</p>}
                        <div className="form-group">
                            <label>Reference:</label>
                            <textarea className="form-control" name='reference' onChange={handleChange} rows="2" required></textarea>
                        </div>
                    </div>
                    {popupType != "" &&
                        <PopupModal popupType={popupType} error={error} Message={message} Navigation="" handleClose={handleClose} />
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="submit" onClick={formSubmit} className={`btn btn-primary btn-block`}>Submit</button>
            </Modal.Footer>
        </Modal>
    );
};

export { Paymentmodal };
