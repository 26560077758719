import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal } from "../Components";
import { SettingNavbarComponent } from "../Components/navbar/settingsnav";
import { MdOutlineEdit } from "react-icons/md"
import { FormParser } from "../Components";
import Masters from "../Components/masters.json";
import { FileManager } from "../Components/FileManager";
import { Modal } from 'react-bootstrap';
import axiosConfig from "../Service/axiosConfig";

const EmailGateway = () => {
    const MastersModal = Masters.EmailGateway;
    const [readOnly, setReadOnly] = useState(true);
    const [show, setShow] = useState(false);
    const [Error, SetError] = useState("");
    const [popupType, setpopupType] = useState("");
    const [emailGateway, setEmailGateway] = useState([])
    const handleShow = () => {
        setShow(true)
    }

    const GetEmailGateway = async () => {
        try {
            const responce = await axiosConfig.get(`/accounts/email-gateways/`)
            if (responce.data.results.length > 0) {
                setEmailGateway(responce.data.results[0])
            }
        }
        catch (error) {
            console.log(error)
        }

    }

    const formSubmit = async (Data) => {
        try {
            if (Data.id) {
                const response = await axiosConfig.patch(`/accounts/email-gateways/${Data.id}/`, Data)
                console.log("updated site settings successfully : ", response)
            } else {
                const response = await axiosConfig.post(`/accounts/email-gateways/`, Data)
                console.log("site settings created successfully : ", response)
            }
            setpopupType("success")
            setReadOnly(true)
        } catch (error) {
            console.log("Error setting the site data : ", error)
            setpopupType("error")
        }
    }

    const handleEdit = () => {
        setReadOnly(false)
    }

    useEffect(() => {
        GetEmailGateway();
    }, [])

    return (
        <>
            <DashboardLayout>
                <div className="child-sidenav">
                    <SettingNavbarComponent />
                    <div className="childnav-content">
                        <div className="cat-button" onClick={handleShow}>
                            <div className="btn" >
                                + Add New
                            </div>
                        </div>
                        <FormParser
                            modelObject={MastersModal}
                            buttonTitle="Save"
                            readOnly={readOnly}
                            setReadOnly={setReadOnly}
                            formData={emailGateway}
                            formSubmit={formSubmit}
                            error={Error}
                        />
                        {popupType != "" &&
                            <PopupModal popupType={popupType} setpopupType={setpopupType} Navigation="/dashboard/masters/emailgateway" />
                        }
                        <Modal
                            size="xl"
                            show={show}
                            onHide={() => setShow(false)}
                            aria-labelledby="example-modal-sizes-title-lg"
                            className="fm-modal"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-lg" className="fm-modal-title">
                                    Choose images
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="fm-modal-body">
                                <FileManager />
                            </Modal.Body>
                        </Modal>
                        {readOnly &&
                            <button className={`btn btn-primary d-flex align-items-center`} onClick={() => handleEdit()}>
                                <div>Edit</div>
                                <MdOutlineEdit className="ms-5" />
                            </button>
                        }
                    </div>
                </div>
            </DashboardLayout>
        </>
    )
}
export { EmailGateway }