import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {  MdOutlineCategory, MdOutlineNotifications, MdOutlineMenuBook, MdOutlineGroups } from "react-icons/md"
import {CiPercent} from "react-icons/ci"
import {BsFilePost} from "react-icons/bs"

const MarketingNavbarComponent = () => {
    return (
        <div className="categorie-header">
            <NavLink to="/dashboard/masters/coupons" className="categorie-subtitle" ><div className="child-title">
                <CiPercent size={24} className="childnav-icon" />
                <p className="childnav-name">Coupons</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/customers" className="categorie-subtitle" ><div className="child-title">
                <MdOutlineGroups size={24} className="childnav-icon" />
                <p className="childnav-name">Customers</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/promotionalnotifications" className="categorie-subtitle" ><div className="child-title">
                <MdOutlineNotifications size={24} className="childnav-icon" />
                <p className="childnav-name">Promotional Notifications</p>
            </div></NavLink>
        </div>
    )
}

export  {MarketingNavbarComponent}
