import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import { DashboardLayout } from './DashboardLayout';
import axiosConfig from "../../Service/axiosConfig";
import DataTable from 'react-data-table-component';
import tableCustomStyles from "../PopupModal/tableCustomStyles";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ChartTable = () => {
  let today_date = (new Date()).toISOString().split('T')[0];
  const [formData, setFormData] = useState({
    fromDate: today_date,
    toDate: today_date,
  });
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [RowPerPage, setRowPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentProductPage, setCurrentProductPage] = useState(1);
  const [totalProductRows, setTotalProductRows] = useState(0);

  const formatDate = (inputDate) => {
    const dateObject = new Date(inputDate);

    const options = { day: 'numeric', month: 'short', year: 'numeric', maintainAspectRatio: false };
    const formattedDate = dateObject.toLocaleDateString('en-GB', options);

    return formattedDate
  }

  const grouped_option = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
      title: {
        display: true,
        text: "Sales per Month",
      },
    },
    indexAxis: 'x',
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
      },
    },
  };

  const grouped_data = {
    labels: chartData.net_amount_per_day ? Object.keys(chartData.net_amount_per_day).map((date) => formatDate(date)) : [],
    datasets: [
      {
        label: 'Sales',
        backgroundColor: 'rgba(166, 226, 107)',
        borderColor: 'rgba(166, 226, 107, 1)',
        borderWidth: 1,
        data: chartData.net_amount_per_day ? Object.values(chartData.net_amount_per_day) : [],
      },
      {
        label: 'Expenses',
        backgroundColor: 'rgba(239, 68, 68)',
        borderColor: 'rgba(239, 68, 68, 1)',
        borderWidth: 1,
        data: chartData.total_amount_per_day_str ? Object.values(chartData.total_amount_per_day_str) : [],
      },
    ],
  };

  const client_columns = [
    {
      name: 'S No.',
      cell: (row, index) => <div>{index + 1}</div>,
      sortable: true,
    },
    {
      name: 'Client',
      selector: 'client_name',
      sortable: true,
    },
    {
      name: 'Total Amount',
      selector: 'total_net_amount',
      sortable: true,
    },
    {
      name: 'Total Quantity',
      selector: 'total_quantity',
      sortable: true,
    },
  ]

  const product_columns = [
    {
      name: 'S No.',
      cell: (row, index) => <div>{index + 1}</div>,
      sortable: true,
    },
    {
      name: 'Product',
      selector: 'product_name',
      sortable: true,
    },
    {
      name: 'Quantity',
      selector: 'total_quantity_product',
      sortable: true,
    },
  ]

  const client_data = chartData ? chartData.client_todays_delivered : [];

  const product_data = chartData ? chartData.product_todays_deliverd : [];

  const labels = chartData.net_amount_per_day ? Object.keys(chartData.net_amount_per_day).map((date) => formatDate(date)) : [];
  const total_labels = chartData.total_amount_per_day_str ? Object.keys(chartData.total_amount_per_day_str).map((date) => formatDate(date)) : [];

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Sales per Day",
        data: chartData.net_amount_per_day ? Object.values(chartData.net_amount_per_day) : [],
        backgroundColor: "#8bc53f",
      },
    ],
  };

  const total_data = {
    labels: total_labels,
    datasets: [
      {
        label: "Total per Day",
        data: chartData.total_amount_per_day_str ? Object.values(chartData.total_amount_per_day_str) : [],
        backgroundColor: "#8bc53f",
      },
    ],
  };

  const ClientTableHeading = () => {
    return (
      <div>Client-wise Today's orders</div>
    )
  }

  const ProductTableHeading = () => {
    return (
      <div>Product-wise Today's orders</div>
    )
  }

  const GetData = async () => {
    try {
      const response = await axiosConfig.get("/accounts/display_data_analysis/");
      setChartData(response.data)
      let chart_data = response.data;
      console.log(chart_data, "chart data");
      setTotalRows(response.data.client_todays_delivered.length)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    GetData();
  }, [])

  return (
    <>
      <DashboardLayout>
        <div>
          <div className='cards-container'>
            <div className="filter-card">
              <div className='card-heading'>Active Orders</div>
              <div className='card-body'>
                <p className='card-sale'>Sale: <span className='clr-positive'>₹{chartData.active_orders ? chartData.active_orders : 0}</span></p>
              </div>
            </div>
            <div className="filter-card">
              <div className='card-heading'>Delivered Orders</div>
              <div className='card-body'>
                <p className='card-sale'>Sale: <span className='clr-positive'>₹{chartData.deliverd_orders ? chartData.deliverd_orders : 0}</span></p>
                <p className='card-expenses'>Expenses:
                  <span className='clr-negative'>
                    ₹{(chartData.grn_orders ? chartData.grn_orders : 0) + (chartData.expendicture_orders ? chartData.expendicture_orders : 0)}
                  </span>
                </p>
                <p className='card-pnl'>P&L:
                  <span className={`clr-${chartData.total_profit_loss_orders < 0 ? 'negative' : 'positive'}`}>₹{chartData.total_profit_loss_orders ? chartData.total_profit_loss_orders : 0}</span>
                </p>
              </div>
            </div>
            <div className="filter-card">
              <div className='card-heading'>Month Average</div>
              <div className='card-body'>
                <p className='card-sale'>Sale: <span className='clr-positive'>₹{chartData.month_average ? chartData.month_average : 0}</span></p>
                <p className='card-expenses'>Expenses:
                  <span className='clr-negative'>
                    ₹{(chartData.grn_month_average ? chartData.grn_month_average : 0) + (chartData.expendicture_month_average ? chartData.expendicture_month_average : 0)}
                  </span>
                </p>
                <p className='card-pnl'>P&L:
                  <span className={`clr-${chartData.total_profit_loss_month_average < 0 ? 'negative' : 'positive'}`}>₹{chartData.total_profit_loss_month_average ? chartData.total_profit_loss_month_average.toFixed(2) : 0}</span>
                </p>
              </div>
            </div>
            <div className="filter-card">
              <div className='card-heading'>Month Lowest</div>
              <div className='card-body'>
                <p className='card-sale'>Sale: <span className='clr-positive'>₹{chartData.month_lowest ? chartData.month_lowest : 0}</span></p>
                <p className='card-expenses'>Expenses:
                  <span className='clr-negative'>
                    ₹{(chartData.grn_month_lowest ? chartData.grn_month_lowest : 0) + (chartData.expendicture_month_lowest ? chartData.expendicture_month_lowest : 0)}
                  </span>
                </p>
                <p className='card-pnl'>P&L:
                  <span className={`clr-${chartData.total_profit_loss_month_lowest < 0 ? 'negative' : 'positive'}`}>₹{chartData.total_profit_loss_month_lowest ? chartData.total_profit_loss_month_lowest : 0}</span>
                </p>
              </div>
            </div>
            <div className="filter-card">
              <div className='card-heading'>Month Highest</div>
              <div className='card-body'>
                <p className='card-sale'>Sale: <span className='clr-positive'>₹{chartData.month_highest ? chartData.month_highest : 0}</span></p>
                <p className='card-expenses'>Expenses:
                  <span className='clr-negative'>
                    ₹{(chartData.grn_month_highest ? chartData.grn_month_highest : 0) + (chartData.expendicture_month_highest ? chartData.expendicture_month_highest : 0)}
                  </span>
                </p>
                <p className='card-pnl'>P&L:
                  <span className={`clr-${chartData.total_profit_loss_month_highest < 0 ? 'negative' : 'positive'}`}>₹{chartData.total_profit_loss_month_highest ? chartData.total_profit_loss_month_highest.toFixed(2) : 0}</span>
                </p>
              </div>
            </div>
          </div>
          {/* <div className="chart-size">
            <Bar options={option} data={data} />
          </div> */}
          <div className="chart-size">
            <Bar options={grouped_option} data={grouped_data} />
          </div>
          {/* <div className="chart-size">
            <Bar options={total_options} data={total_data} />
          </div> */}
          <DataTable
            columns={client_columns}
            data={client_data}
            pagination
            title={<ClientTableHeading />}
            paginationTotalRows={totalRows}
            // paginationPerPage={RowPerPage}
            // paginationDefaultPage={currentPage}
            onChangePage={(page) => setCurrentPage(page)}
            paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
            paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
            // onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
            // onSort={handleSort}
            // paginationServer
            customStyles={tableCustomStyles}
            progressPending={loading}
            progressComponent={<div className="loader-container">
              <img src={require('../FormParser/images/loader2.gif')} alt="Loading..." />
            </div>}
          />
          <DataTable
            columns={product_columns}
            data={product_data}
            pagination
            title={<ProductTableHeading />}
            paginationTotalRows={totalProductRows}
            // paginationPerPage={RowPerPage}
            // paginationDefaultPage={currentPage}
            onChangePage={(page) => setCurrentProductPage(page)}
            paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
            paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
            // onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
            // onSort={handleSort}
            // paginationServer
            customStyles={tableCustomStyles}
            progressPending={loading}
            progressComponent={<div className="loader-container">
              <img src={require('../FormParser/images/loader2.gif')} alt="Loading..." />
            </div>}
          />
        </div>
      </DashboardLayout >
    </>
  )


};

export default ChartTable;
