import React, { useEffect, useState } from "react";
import { DashboardLayout } from "../Components";
import { BillsComponent } from "../Components/navbar/Billscomponent";
import axiosConfig from "../Service/axiosConfig";
import DataTable from 'react-data-table-component';
import { BiSearch } from "react-icons/bi";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import useDebouncedValue from "../Utils/Debounce";
import { AiOutlineFileExcel } from "react-icons/ai";
import { downloadExcel } from 'react-export-table-to-excel';
import { Paymentmodal } from "../Components/PaymentModal/PaymentModal";
import { SiContactlesspayment } from "react-icons/si";
import NumberFormat from "../Utils/NumberFormat";

const Outstandings = () => {
    const [summary, setSummary] = useState({});
    const [totals, setTotals] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false)
    const [rowId, setRowId] = useState();
    const [search, setSearch] = useState('')
    const debouncedSearchTerm = useDebouncedValue(search, 500);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [customData, setCustomData] = useState("");

    const GetSummary = async (id) => {
        setLoading(true)
        try {
            const summary = await axiosConfig.get(`/accounts/account_summary/?page=${currentPage}&page_size=${RowPerPage}&search=${search}`)
            let totals = summary.data.results[summary.data.results.length - 1]
            let data = summary.data.results.slice(0, -1);
            setSummary(data)
            setTotals(totals)
            setTotalRows(summary.data.count)
            setLoading(false)
        } catch (error) {
            console.log("error getting summary : ", error)
        }
    }

    const HandleMouse = (row) => {
        setRowId(row.Client_name)
    }

    const getCurrentYearMonth = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth()).padStart(2, '0'); // getMonth() returns 0-11, so we add 1
        return `${year}-${month}`;
    };

    const handleShow = async (row) => {
        const currentYearMonth = getCurrentYearMonth();
        try {
            const response = await axiosConfig.get(`accounts/accountstatement/?year_month=${currentYearMonth}&client=${row.Client_id}&page=1&page_size=20`);
            let data = response.data.results
            if (data.length > 0) {
                setCustomData(data[0])
            }
                
            setShowModal(true);
        } catch (error) {
            console.log("Error getting account statement", error)
        }
    }
    const handleClose = () => setShowModal(false);

    const HandleRowmouseLeave = () => {
        setRowId()
    }

    function formatTimestamp(inputTimestamp) {
        const dateObject = new Date(inputTimestamp);

        // Extract individual components
        const year = dateObject.getFullYear() % 100;
        const month = dateObject.getMonth() + 1;
        const day = dateObject.getDate();
        const hours = 23;
        const minutes = 59;
        const seconds = 0;

        // Format components into the desired string
        const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

        return formattedDate;
    }

    const handleSort = async (column, direction) => {
        try {
            console.log(column.selector, direction);
            const res = await axiosConfig.get(`/accounts/account_summary/?page=${currentPage}&page_size=${RowPerPage}&ordering=${direction === "asc" ? column.selector : -column.selector}`);
            setSummary(res.data.results);
            setCurrentPage(1);
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    const HandleSearch = (e) => {
        setSearch(e.target.value)
    }

    const HandleNavigate = (row) => {
        let year_month = new Date().toISOString().split("T")[0].split("-");
        console.log(year_month)
        navigate(`/dashboard/masters/${row.Client_id}/client_transactions`, { state: { rowData: row, year_month: `${year_month[0]}-${year_month[1]}` } });
    }

    const header = [
        "S.No",
        "Client",
        "Current Month Unbilled",
        "Last Billed Due",
        "Last Paid",
        "Total Outstanding",
    ];
    const body = summary.length > 0 && summary.map((res, index) => [index + 1, res.Client_name, res.Current_month_unbilled, res.Last_billed_due, res.Last_paid?.amount, res.Total_outstanding])

    function handleDownloadExcel() {
        downloadExcel({
            fileName: "Outstandings Report",
            sheet: "Outstandings Report",
            tablePayload: {
                header: header,
                body: body,
            },
        });
    }

    const CustomHeader = () => {
        return (
            <div className="rowEndCenter gap-10">
                <AiOutlineFileExcel
                    className="report-icons"
                    onClick={handleDownloadExcel}
                />
            </div>
        );
    };

    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "left"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "left",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
        // head: {
        //     style: {
        //         position: "fixed",
        //         top: "50vh",
        //     },
        // },
    };

    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true,
            compact:true,
            width:"70px"
        },
        {
            name: "Client",
            selector: (row) => row.Client_name,
            sortable: true,
            width: "250px",
            wrap:true,
            reorder:true
        },
        {
            name: "Current Month Unbilled",
            selector: (row) => row.Current_month_unbilled,
            cell: (row) => `₹ ${NumberFormat(row.Current_month_unbilled)}`,
            sortable: true,
            minWidth: "150px",
            right:true,
            reorder:true
        },
        {
            name: "Last Billed Due",
            selector: (row) => row.Last_billed_due,
            cell: (row) => `₹ ${NumberFormat(row.Last_billed_due)}`,
            sortable: true,
            minWidth: "150px",
            right:true,
            reorder:true
        },
        // {
        //     name: "Last Paid",
        //     selector: (row) => row.Last_paid ? row.Last_paid.amount : 0,
        //     cell: (row) => NumberFormat(row.Last_paid ? row.Last_paid.amount : 0),
        //     sortable: true,
        //     width: "120px",
        //     reorder:true
        // },
        {
            name: "Current Month Paid",
            selector: (row) => row.Current_month_paid,
            cell: (row) => `₹ ${NumberFormat(row.Current_month_paid)}`,
            sortable: true,
            reorder:true,
            right:true,
            minWidth: "150px"
        },
        {
            name: "Total Outstanding",
            selector: (row) => row.Total_outstanding,
            cell: (row) => `₹ ${NumberFormat(row.Total_outstanding)}`,
            sortable: true,
            right:true,
            reorder:true,
            minWidth: "150px"
        },
        {
            name: "Actions",
            cell: (row) => {
                return (
                    rowId === row.Client_name ?
                        <>
                            <div className="row">
                                <div className="col-6" onClick={() => HandleNavigate(row)}><MdOutlineRemoveRedEye /></div>
                                <div className="col-6" onClick={() => handleShow(row)}><SiContactlesspayment /></div>
                            </div>
                        </>
                        // <div><MdOutlineRemoveRedEye /></div>
                        :
                        <></>
                )
            },
            width: "100px"
        }
    ]

    useEffect(() => {
        GetSummary();
    }, [RowPerPage, currentPage, debouncedSearchTerm, RowPerPage])
    return (
        <>
            <DashboardLayout>
                <div className="child-sidenav">
                    <BillsComponent />
                    <div className="childnav-content">
                        <div className="input-cat-with-icon mb-3">
                            <span className="icon-product"><BiSearch /></span>
                            <input type="text" className="input-control" placeholder="Search" name="search" value={search} onChange={(e) => HandleSearch(e)} />
                        </div>
                        <div>
                            <div className=" row rowBetweenCenter f_14_500">
                                <div className="col-3">Current Month Unbilled Total</div>
                                <div className="col-3 f_14_400">{`₹ ${NumberFormat(totals.Current_month_unbilled_sum)}`}</div>
                                <div className="col-3">Last Billed Due Total</div>
                                <div className="col-3 f_14_400">{`₹ ${NumberFormat(totals.Last_billed_due_sum)}`}</div>
                            </div>
                        </div>
                        <div>
                            <div className=" row rowBetweenCenter f_14_500">
                                <div className="col-3">Last Paid Total</div>
                                <div className="col-3 f_14_400">{`₹ ${NumberFormat(totals.Last_paid_sum)}`}</div>
                                <div className="col-3">Outstandings Total</div>
                                <div className="col-3 f_14_400">{`₹ ${NumberFormat(totals.Total_outstanding_sum)}`}</div>
                            </div>
                        </div>
                        <div className="table-responsive" style={{ zIndex: 0 }}>
                            <DataTable columns={columns} data={summary} 
                                pagination 
                                paginationTotalRows={totalRows}
                                title={<CustomHeader />}
                                fixedHeader
                                paginationPerPage={RowPerPage}
                                paginationDefaultPage={currentPage}
                                onChangePage={(page) => setCurrentPage(page)}
                                paginationPerPageOptions={[10, 20, 25, 30, 40, 50, totalRows]}
                                paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50, totalRows]}
                                onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                                // onSort={handleSort}
                                // onRowClicked={(row) => handleRowClick(row)}
                                onRowMouseEnter={HandleMouse}
                                onRowMouseLeave={HandleRowmouseLeave}
                                highlightOnHover
                                fixedHeaderScrollHeight="70vh"
                                paginationServer subHeader customStyles={tableCustomStyles} progressPending={loading}
                                progressComponent={<div className="loader-container">
                                    <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                </div>}
                            />
                        </div>
                    </div>
                </div>
                {
                    showModal &&
                    <Paymentmodal showModal={showModal} handleClose={handleClose} customData={customData} formatTimestamp={formatTimestamp} />
                }
            </DashboardLayout>
        </>
    )
}
export { Outstandings }