import { color } from "chart.js/helpers";

const tableCustomStyles = {
  rows: {
    style: {},
  },
  cells: {
    style: {
      justifyContent: "start",
    },
  },
  headCells: {
    style: {
      color: "#5C5C5C",
      justifyContent: "start",
      color: "rgb(33,37,41)",
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "28px",
    },
  },
};
export default tableCustomStyles;
