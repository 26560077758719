import { useState, useEffect } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { BsArrowReturnRight } from "react-icons/bs";
import { PopupModal } from "../Components";
import axiosConfig from "../Service/axiosConfig";
import DataTable from 'react-data-table-component';
import { AiOutlineSync } from 'react-icons/ai';
import { MdOutlineEdit } from "react-icons/md"
import { TiTick } from "react-icons/ti"
import '../../src/Pages/Pages.css';
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import Select from 'react-select';
import useDebouncedValue from "../Utils/Debounce";
import ToggleSwitch from "./ToggleSwitch";
import { useURLParams } from '../Components/UrlUtils/useURLParams';
import { DashboardLayout } from "../Components";
const PricingManagement = () => {
    const user = JSON.parse(localStorage.getItem("user_data"))
    const { setParam, getParam, clearSelection } = useURLParams();
    const [products, setProducts] = useState([])
    const [categories, setCategories] = useState([])
    const [selectedVertical, setSelectedVertical] = useState({ value: getParam("business-vertical") || "" });
    const [businessVertical, setBusinessVertical] = useState(getParam("business-vertical") || "");
    const [businessVerticals, setBusinessVerticals] = useState([]);
    const [Loading, setLoading] = useState(false)
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [LoadingButton, setLoadingButton] = useState(false);
    const [search, SetSearch] = useState({
        search: getParam("search") || ""
    })
    const debouncedSearchTerm = useDebouncedValue(search.search, 500);
    const [popupType, setpopupType] = useState('')
    const [message, setMessage] = useState('')
    const [error, setError] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [hoveredRow, setHoveredRow] = useState(null);
    const [TariffId, setTariffId] = useState();
    const [verticalPrice, setVerticalPrice] = useState(0);
    const handleSort = (column, direction) => {
        console.log(column.selector, direction)
        axiosConfig.get(`/accounts/storewise_inventory/?ordering=${direction === "asc" ? column.selector : -column.selector}&category=${selectedCategory ? selectedCategory : ""}&sub_category=${selectedSubCategory ? selectedSubCategory : ""}&business_vertical=${businessVertical}`).then(res => {
            setProducts(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
        setParam("search", value)
    }

    const handleSync = async () => {
        try {
            const response = await axiosConfig.post(`/catlog/sync_product_prices/`, {
                business_vertical_id: selectedVertical.value
            });
        } catch (error) {
            console.log(error)
        }
    }
    const handlePriceChange = async (item) => {
        try {
            const response = await axiosConfig.put(`/accounts/storewise_inventory/`, {
                product_id: item.product.id,
                business_vertical: item.business_vertical.id,
                price: verticalPrice
            })
            setTariffId(null);
            GetData(false);
        } catch (error) {
            console.log(error)
        }
    }
    const handleVerticalChange = (selectedOption) => {
        setSelectedVertical({ value: selectedOption.value }); // Update selected store
        setBusinessVertical(selectedOption.value);
        setParam("business-vertical", selectedOption.value)
    };
    const product_columns = [
        {
            name: 'S No.',
            sortable: false,
            width: '70px',
            cell: (row, index) => <>{(currentPage - 1) * RowPerPage + index + 1}</>,
        },
        {
            name: 'Product',
            selector: row => <div><img className="mng-prod-img" src={row.product.image && row.product.image?.image} alt={row.product.name} /></div>,
            sortable: false,
        },
        {
            name: 'Name',
            selector: 'product.name',
            sortable: true,
            width: '220px',
            wrap: true
        },
        {
            name: 'Price',
            selector: 'price',
            sortable: true,
            right: true,
            cell: row => {
                if (TariffId === row.id) {
                    return (
                        <input
                            className="form-control"
                            type="number"
                            value={verticalPrice}
                            onChange={(e) => setVerticalPrice(e.target.value)}
                        />
                    );
                } else {
                    return row.price;
                }
            },
            width: '120px',
        },
        {
            name: 'Sort',
            selector: 'sort',
        },
        {
            name: 'HSN/GST',
            selector: 'tax_classification',
            width: '100px',
            style: { justifyContent: "center" },
            cell: row => row.tax_classification ? row.tax_classification.hsn_code : 'NA',
        },
        // {
        //     name: 'Stock',
        //     selector: 'avaliable',
        //     sortable: true,
        //     width: '100px',
        //     cell: row => <ToggleSwitch row={row} checked={row.avaliable} handleToggle={handleToggle} />,
        // },
        {
            name: 'Actions',
            sortable: false,
            selector: row => {
                const HandleEdit = async () => {
                    setTariffId(row.id);
                    setVerticalPrice(row.price)
                };
                return (
                    <>
                        {hoveredRow === row.id ? (
                            <>
                                {TariffId === row.id ? (
                                    <div className="row">
                                        <div className="col-12" onClick={() => handlePriceChange(row)}>
                                            <TiTick className="client-actions pointer" />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row">
                                        <div className="col-12" onClick={() => HandleEdit(row)}>
                                            <MdOutlineEdit className="client-actions" />
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="client-type contract-background">Active</div>
                        )
                        }
                    </>
                )
            }
        }
    ];

    const handleRowMouseEnter = (row) => {
        setHoveredRow(row.id)
    }
    const handleRowMouseLeave = () => {
        setHoveredRow(null)
    }

    const GetCategories = async () => {
        if (categories.length > 0) return
        try {
            const response = await axiosConfig.get(`/catlog/categories/?page_size=30&is_suspended=false`)
            const subCategoryPromises = response.data.results.map(async (category) => {
                return axiosConfig.get(`/catlog/sub-categories/?category=${category.id}&is_suspended=false&page_size=100`);
            });

            const subCategoryResponses = await Promise.all(subCategoryPromises);
            const categoriesWithSubCategories = response.data.results.map((category, index) => {
                return {
                    ...category,
                    subCategories: subCategoryResponses[index].data.results.map((subCategory) => ({
                        id: subCategory.id,
                        name: subCategory.name,
                        category: category.id
                    })),
                    count: subCategoryResponses[index].data.count
                };
            });

            setCategories(categoriesWithSubCategories);
            console.log(categoriesWithSubCategories);
        } catch (error) {
            console.log(error)
        }
    }
    const GetData = async (showLoader) => {
        showLoader && setLoading(true)
        try {
            const responce = await axiosConfig.get(`/accounts/storewise_inventory/?page=${currentPage}&page_size=${RowPerPage}&category=${selectedCategory ? selectedCategory : ""}&sub_category=${selectedSubCategory ? selectedSubCategory : ""}&business_vertical=${businessVertical}`)
            setTotalRows(responce.data.count)
            setProducts(responce.data.results)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }

    }

    const GetVerticals = async () => {
        if (businessVerticals.length > 0) return
        try {
            const response = await axiosConfig.get(`/masters/business-verticals/?is_suspended=false`);
            setBusinessVerticals(response.data.results)
        } catch (error) {
            console.log(error)
        }
    }

    const handleFilter = (type, data) => {
        if (type === "category") {
            setSelectedCategory(data.id)
            setSelectedSubCategory(null)
        } else if (type === "subCategory") {
            if (data.category !== selectedCategory) {
                setSelectedCategory(null)
            }
            setSelectedSubCategory(data.id)
        }
    }

    useEffect(() => {
        const getSearchResults = async () => {
            try {
                axiosConfig.get(`/accounts/storewise_inventory/?search=${debouncedSearchTerm}&business_vertical=${businessVertical}`).then(res => {
                    setProducts(res.data.results)
                    console.log(res.data.results)
                }).catch(error => {
                    console.log(error)
                })
            } catch (error) {
                console.log(error);
            }
        };

        getSearchResults();
    }, [debouncedSearchTerm]);

    useEffect(() => {
        GetCategories()
        user.role && GetData(true);
        user.role && GetVerticals()
    }, [currentPage, RowPerPage, selectedCategory, selectedSubCategory, businessVertical])

    return (
        <>
            <DashboardLayout>
                <div className="child-sidenav">
                    <div className="inventory-filters">
                        <div className="filter-by p-10">FILTERED BY</div>
                        <div className="p-10 rowBetweenCenter">
                            <div>
                                <div className="high-demand">High in demand 0</div>
                                <div className="f_10_green">All items are in stock</div>
                            </div>
                            <div className="rowAligned gap-10">
                                <span className="new">New</span>
                                <MdKeyboardArrowRight size={15} color="#C1C1C1" />
                            </div>
                        </div>
                        <div className="all-items">
                            <div className="rowAligned gap-10">
                                <div className="f_16_green">All items</div>
                                <div className="items-count">{totalRows}</div>
                            </div>
                            <MdKeyboardArrowRight size={15} color="#1B7D78" />
                        </div>
                        <div className="filter-by p-10">CATEGORIES</div>
                        {categories.length > 0 && categories.map((category) => (
                            <>
                                <div className={`category-head ${category.id === selectedCategory ? "active" : ""}`} onClick={() => handleFilter("category", category)}>
                                    <div className="f_16_5c5c5c">{category.name}</div>
                                    <div className="f_12_B8ACAC">{category.count}</div>
                                </div>
                                <ul className="subcategory-list">
                                    {category.subCategories.length > 0 && category.subCategories.map((subcategory) => (
                                        <li className={`subcategory-head ${subcategory.id === selectedSubCategory ? "active" : ""}`} onClick={() => handleFilter("subCategory", subcategory)}>
                                            <BsArrowReturnRight size={15} color="#B8ACAC" style={{ marginRight: '10px' }} />
                                            <div className="f_12_5c5c5c">{subcategory.name}</div>
                                            {/* <div className="f_12_B8ACAC">56</div> */}
                                        </li>
                                    ))}
                                </ul>
                            </>
                        ))}
                    </div>
                    <div className="childnav-content">
                        <div className="store-select">
                            <Select
                                options={businessVerticals.map(businessVertical => ({ value: businessVertical.id, label: businessVertical.name }))}
                                placeholder="Select Business Vertical"
                                value={businessVerticals.map(businessVertical => ({ value: businessVertical.id, label: businessVertical.name })).find(res => res.value === parseFloat(selectedVertical.value))}
                                onChange={(selectedOption) => { if (!selectedOption) { clearSelection(setSelectedVertical, "business-vertical") } else { handleVerticalChange(selectedOption) } }}
                                isClearable
                            />
                        </div>
                        <div>
                            <div className="prod-search">
                                <input type="text" className="form-control prod-search-input" placeholder="Enter product name" name="search" value={search.search} onChange={HandleSearch} />
                                <div className="sync" onClick={handleSync}>
                                    {LoadingButton ? <div>
                                        <img src={require('../Components/FormParser/images/loader.gif')} alt="Loading..." className="loader-image" />
                                    </div> : <AiOutlineSync />}
                                </div>
                            </div>
                        </div>
                        {businessVertical ?
                            <DataTable
                                title={
                                    <div className="datatable-title">
                                        Total Items({totalRows})
                                    </div>
                                }
                                columns={product_columns}
                                data={products}
                                pagination paginationTotalRows={totalRows}
                                paginationPerPage={RowPerPage}
                                paginationDefaultPage={currentPage}
                                onChangePage={(page) => setCurrentPage(page)}
                                paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                                onSort={handleSort}
                                paginationServer fixedHeader customStyles={tableCustomStyles}
                                progressPending={Loading}
                                progressComponent={<div className="loader-container">
                                    <img src={require('../Components/FormParser/images/loader.gif')} alt="Loading..." />
                                </div>}
                                highlightOnHover
                                onRowMouseEnter={handleRowMouseEnter}
                                onRowMouseLeave={handleRowMouseLeave}
                            />
                            :
                            <div className="text-center mt-5 f_14_500">Select a business verical to view products</div>
                        }
                    </div>
                    {popupType != "" &&
                        <PopupModal popupType={popupType} setpopupType={setpopupType} Message={message} error={error} />
                    }
                </div>
            </DashboardLayout>
        </>
    )
}

export { PricingManagement }