import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal } from "../Components";
import axiosConfig from "../Service/axiosConfig"
import { useNavigate, useParams } from "react-router";
import DataTable from 'react-data-table-component';
import { RiMapPin2Line } from "react-icons/ri"
import { MdOutlineMailOutline, MdOutlinePhone } from "react-icons/md";

const VariableTariff = () => {
    let { id } = useParams();
    const [Data, SetData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [PriceChange, setPriceChange] = useState({
        tariffs_data:[]
    });
    const [popupType, setpopupType] = useState("")
    const [message, setMessage] = useState('')
    const handleChange = (e, id) => {
        const value = e.target.value;
        const index = PriceChange.tariffs_data.findIndex(item => item.client_id === id);
    
        if (index !== -1) {
            setPriceChange(prevState => {
                const newState = { ...prevState };
                newState.tariffs_data[index].price = value;
                return newState;
            });
        } else {
            setPriceChange(prevState => ({
                ...prevState,
                tariffs_data: [
                    ...prevState.tariffs_data,
                    { client_id: id, price: value }
                ]
            }));
        }
    };
console.log(PriceChange)
    const handleSort = (column, direction) => {
        console.log(column.selector, direction)
        axiosConfig.get(`/clients/tariffs/?client__client_type=Variable&product=${id}&price=&page=${currentPage}&page_size=${RowPerPage}&ordering=${direction === "asc" ? column.selector : -column.selector}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };
    const formSubmit = async() => {
        try {
            const response = await axiosConfig.post(
                `catlog/products/${id}/update_clients_tariff/`,
                PriceChange,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
    
            console.log(response);
            GetData();
            setMessage('Product tariff Updated Successfully');
            setpopupType('success');
        } catch (error) {
            console.error(error);
            setpopupType('error');
        }
    }
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/clients/tariffs/?client__client_type=Variable&product=${id}&price=&page=${currentPage}&page_size=${RowPerPage}`)
            console.log(responce.data.results)
            SetData(responce.data.results)
            setLoading(false)
            setTotalRows(responce.data.count)
        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        GetData();
    }, [currentPage, RowPerPage]);
    const HandleMouse = (row) => {
        // setRowId(row.id)
    }
    const HandleRowmouseLeave = () => {
        // setRowId()
    }
    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "center"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "center",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    };
    const columns = [
        {
            width: '150px',
            selector: "full_name",
            cell: row => <div className="client-detail">
                <img src={row.client.logo && row.client.logo.image !== undefined ? row.client.logo.image : ''} className="client-logo" alt="logo" />
                <div className="client-data">
                    <div className="client-name">{row.client.client_name}</div>
                    <div className={`client-type ${row.client.client_type === 'Contracted' ? 'contract-background' : 'variable-background'}`}>{row.client.client_type}</div>
                </div>
            </div>,
            sortable: true
        },
        {
            selector: "email",
            cell: row => <div className="store-info">
                <div className="contact-details"><RiMapPin2Line className="contact-icon" />{row.client.address}</div>
                <div className="contact-email"><MdOutlineMailOutline className="contact-icon" />{row.client.email}</div>
                <div className="contact-phone"><MdOutlinePhone className="contact-icon" />{row.client.mobile}</div>
            </div>,
            sortable: true
        },
        {
            selector: "price",
            sortable: true,
            cell: row => {
                { console.log(row.price) }
                return (
                    <input
                        className="form-control col-md-6"
                        type="number"
                        defaultValue={row.price}
                        onChange={(e) => handleChange(e, row.client.id)}
                    />
                )
            }
        }
    ]
    return (
        <>
            <div>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Add  New Item</h5>
                    <div className="mx-4">
                        <button type="submit" className="btn btn btn-white">Cancel</button>
                    </div>
                    <div>
                        <button type="submit" className="btn btn btn-primary" onClick={formSubmit} >Submit</button>
                    </div>
                </div>
                <div className="table-responsive marginTop">
                    <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                        paginationPerPage={RowPerPage}
                        paginationDefaultPage={currentPage}
                        onChangePage={(page) => setCurrentPage(page)}
                        paginationPerPageOptions={[10, 20, 25, 30, 40, 50, totalRows]}
                        paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50, totalRows]}
                        onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                        onSort={handleSort}
                        // onRowClicked={(row) => handleRowClick(row)}
                        onRowMouseEnter={HandleMouse}
                        onRowMouseLeave={HandleRowmouseLeave}
                        highlightOnHover
                        paginationServer fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                        progressComponent={<div className="loader-container">
                            <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                        </div>}
                    />
                </div>
            </div>
            {popupType !== "" &&
                <PopupModal setpopupType={setpopupType} popupType={popupType} Navigation="" Message={message} />
            }
        </>
    )
}
export { VariableTariff }