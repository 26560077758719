import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import Masters from "../Components/masters.json";
import axiosConfig from "../Service/axiosConfig";
import { NavLink, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { SettingNavbarComponent } from "../Components/navbar/settingsnav";
import { MdOutlineEdit } from "react-icons/md"
const StoreManagement = () => {
    const navigate = useNavigate();
    const MastersModal = Masters.StoreManager
    const [Data, SetData] = useState([])
    const [popupType, setpopupType] = useState("")
    const [readOnly, setReadOnly] = useState(true);
    const [Error, SetError] = useState("")
    const [message, setMessage] = useState('')
    const user_data = JSON.parse(localStorage.getItem('user_data'))
    let { storeId } = useParams()
    let id = storeId
    const formSubmit = (Data, setFieldErrors) => {
        console.log(Data)
        const formData = new FormData();
        formData.append("address", Data.address);
        formData.append("latitude", Data.latitude);
        formData.append("longitude", Data.longitude);
        formData.append("mobile", Data.mobile);
        formData.append("email", Data.email);
        formData.append("name", Data.name);
        formData.append("password", Data.password);
        if (Data.banner) {
            formData.append("banner_image", Data.banner.id);
        }
        if (Data.logo) {
            formData.append("logo_image", Data.logo.id);
        }
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        axiosConfig.put(`/stores/stores/${id}/`, formData).then(res => {
            console.log(res)
            setpopupType("success")
            setMessage('Store Updated Successfully')
            // navigate('/dashboard/settings/stores');
        }).catch(error => {
            console.log(error)
            // Object.keys(error.response.data).forEach(fieldName => {
            //     switch (fieldName) {
            //         case 'country_name':
            //             if (error.response.data[fieldName][0] === "priority with this code already exists.") {
            //                 setFieldErrors(prev => ({ ...prev, [fieldName]: `${fieldName} Already Exists` }));
            //             }
            //             break;
            //         default:
            //             break;
            //     }
            // });
            setMessage('')
            const ErrorData = []
            ErrorData.push({
                code: error.response.data.code || ["null"],
                name: error.response.data.name || ["null"],
            })
            console.log(ErrorData[0])
            if (ErrorData[0].code[0] === "priority with this code already exists.") {
                SetError(" code already exists.")
            }
            if (ErrorData[0].name[0] === "priority with this name already exists.") {
                SetError("Tittle already Exists")
            }
            if (error.response.data.email) {
                setFieldErrors(prev => ({ ...prev, ["email"]: "Enter a Valid Email Address." }));
                SetError("Enter a Valid Email Address")
            }
            SetError(error.response.data.detail)
            setpopupType("error")
        })
    }
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/stores/stores/${id}/`)
            console.log(responce.data)
            const StoreData = {
                ...responce.data,
                logo: responce.data.logo_image,
                banner: responce.data.banner_image
            }
            SetData(StoreData)
        }
        catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        GetData();
    }, [])
    return (
        <>
            <DashboardLayout>
                <div className="child-sidenav">
                    {user_data.role === "Admin" && <SettingNavbarComponent />}
                    <div className="childnav-content">
                        <div className="dashboard-header" style={{ alignItems: 'center' }}>
                            <h5 className="dashboard-subtitle">Edit store {id}</h5>
                            {/* <NavLink to="/dashboard/masters/manageproducts" ><button className="btn btn-white">Manage Products</button></NavLink> */}
                            {user_data.role === "Admin" && <NavLink to="/dashboard/settings/stores" style={{ textDecoration: 'none' }} ><button className="btn btn-white">Manage Stores</button></NavLink>}
                        </div>
                        <FormParser modelObject={MastersModal} formData={Data} formSubmit={formSubmit} error={Error} setReadOnly={setReadOnly} readOnly={readOnly} />
                        {readOnly &&
                            <button className="btn btn-primary" style={{ width: '143px' }} onClick={() => setReadOnly(false)}>
                                <div>Edit</div>
                                <MdOutlineEdit className="ms-5" />
                            </button>
                        }
                        {popupType != "" &&
                            <PopupModal popupType={popupType} setpopupType={setpopupType} Navigation={user_data.role === "Admin" ? "/dashboard/settings/stores" : ""} Message={message} error={Error} />
                        }
                    </div>
                </div>
            </DashboardLayout >
        </>
    )
}

export { StoreManagement }