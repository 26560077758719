import React, { useEffect, useState, useRef } from "react"
import { DashboardLayout } from "../Components"
import "./Orders.css"
import DataTable from 'react-data-table-component';
import { PopupModal } from "../Components";
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { AiOutlineSearch } from "react-icons/ai"
import axiosConfig from "../Service/axiosConfig"
import { BiRupee } from "react-icons/bi"
import { TbSearch } from "react-icons/tb"
import { AiFillPrinter } from "react-icons/ai"
import { MdOutlineEdit, MdOutlineMapsHomeWork, MdOutlineLocationOn, MdOutlineEmail, MdOutlineLocalPhone } from "react-icons/md"
import { RiCustomerServiceLine, RiSendPlaneFill } from "react-icons/ri"
import { GrGallery } from "react-icons/gr"
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { elements } from "chart.js";
import { useNavigate } from "react-router-dom";
import { MdPrint, MdOutlineEditNote } from "react-icons/md";
import { IoCloseCircleOutline } from "react-icons/io5";
import useDebouncedValue from "../Utils/Debounce";
const Orders = () => {
  const [OrderDetail, SetOrderDetails] = useState([])
  const [OrderFilters, SetOrderFilters] = useState([])
  const [popupType, setpopupType] = useState("")
  const [agent, setAgent] = useState(false);
  const [OrderId, SetOrderId] = useState()
  const [oreders, setOrders] = useState({})
  const [OrderBiId, SetOrderById] = useState([])
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [clientInfo, setClientInfo] = useState({})
  const [orderInfo, setOrderInfo] = useState({})
  const [Active, setActive] = useState("Confirmed");
  const [agents, setAgents] = useState(null)
  const [selectedAgent, setSelectedAgent] = useState(null)
  const [searchstatus, setSearchStatus] = useState("Confirmed");
  const [Invoice, setInvoice] = useState({});
  const [message, setMessage] = useState(null)
  const [count, setCount] = useState({
    New: 0,
    Accepted: 0,
    Ready_to_Ship: 0,
    Shipped: 0,
    Delivered: 0,
    Cancelled: 0
  });
  let page = 1;
  let TotalPages
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const day = currentDate.getDate();
  const isLastDayOfMonth = (new Date(year, month, 0).getDate() === day);
  const nextMonth = isLastDayOfMonth ? (month % 12) + 1 : month;
  const nextYear = isLastDayOfMonth && month === 12 ? year + 1 : year;
  const nextDay = isLastDayOfMonth ? 1 : day + 1;
  const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  const nextDate = `${nextYear}-${nextMonth.toString().padStart(2, '0')}-${nextDay.toString().padStart(2, '0')}`;
  const scrollableContainerRef = useRef(null);
  let store_id = JSON.parse(localStorage.getItem('user_data')).store
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebouncedValue(search, 500);

  const formatDate = (date) => {
    const dateString = date.split('T')[0];
    const parts = dateString.split("-");
    const formattedDate = `${parts[2]}/${parts[1]}/${parts[0].substring(2)}`;
    return formattedDate;
  }

  const OrderDetails = async (status) => {
    setLoading(true);
    let response;
    if (status === "Delivered") {
      const delivered = await axiosConfig.get(`/accounts/orders/?user=&orderstatus=${status}&delivered_time_after=${formattedDate}&delivered_time_before=${nextDate}&page_size=1000&store=${store_id ? store_id : ""}`);
      response = delivered.data.results;
    } else {
      const expected = await axiosConfig.get(`/accounts/orders/?user=&orderstatus=${status}&expected_date_time_after=${formattedDate}&expected_date_time_before=${nextDate}&page_size=1000&store=${store_id ? store_id : ""}`);
      response = expected.data.results;
    }

    setLoading(false);
    let updata = { ...count };
    console.log(response, "responsejk")
    if (response.length > 0) {
      HandleOrder(response[0].id, 0, response[0].total_quantity, response[0].orderstatus, response[0].delivery_charges, response[0].packing_charges, response[0].discount_amount, response[0].total_amount, response[0].net_amount, response[0].user_name, response[0].client, response[0].delivery_agent, response[0].store, response[0].order_date, response[0])
      // HandleInvoice(response[0].id)

      if (status === "Confirmed") {
        updata["New"] = response.length;
      }
      if (status === "Accepted") {
        updata["Accepted"] = response.length;
      }
      if (status === "Ready to ship") {
        updata["Ready_to_Ship"] = response.length;
      }
      if (status === "Shipped") {
        updata["Shipped"] = response.length;
      }
      if (status === "Delivered") {
        updata["Delivered"] = response.length;
      }
      if (status === "Cancelled") {
        updata["Cancelled"] = response.length;
      }
      setClientInfo(response[0].client)
      setOrderInfo(response[0])
    }
    else {
      if (status === "Confirmed") {
        updata["New"] = 0;
        SetOrderById([])
        setOrders({})
      }
      if (status === "Accepted") {
        SetOrderById([])
        setOrders({})
      }
      if (status === "Ready to ship") {
        SetOrderById([])
        setOrders({})
      }
      if (status === "Shipped") {
        SetOrderById([])
        setOrders({})
      }
      if (status === "Delivered") {
        SetOrderById([])
        setOrders({})
      }
      if (status === "Cancelled") {
        SetOrderById([])
        setOrders({})
      }
    }
    setCount(updata);
    SetOrderDetails(response);
    SetOrderFilters(response);
    TotalPages = Math.ceil(response.length / 20)
  };
  const OrderStatusTotal = async () => {
    setLoading(true);

    try {
      let statuscount = {
        New: 0,
      };

      const confirmedResponse = await axiosConfig.get(`/accounts/orders/?user=&orderstatus=Confirmed&expected_date_time_after=${formattedDate}&expected_date_time_before=${nextDate}&store=${store_id ? store_id : ''}&page_size=1000`);
      const allOrders = await axiosConfig.get(`/accounts/orders/?user=&orderstatus=&expected_date_time_after=${formattedDate}&expected_date_time_before=${nextDate}&store=${store_id ? store_id : ''}&page_size=1000`);
      const deliveredOrders = await axiosConfig.get(`/accounts/orders/?user=&orderstatus=Delivered&delivered_time_after=${formattedDate}&delivered_time_before=${nextDate}&store=${store_id ? store_id : ''}&page_size=1000`);
      console.log([...allOrders.data.results, ...deliveredOrders.data.results], "all orders");
      const deliveredOrdersCount = deliveredOrders.data.results.filter(order => order.orderstatus === "Delivered").length;

      const orderStatusCounts = [...allOrders.data.results].reduce((counts, order) => {
        const orderStatus = order.orderstatus;
        if (orderStatus === "Ready to ship") {
          counts["Ready_to_Ship"] = (counts["Ready_to_Ship"] || 0) + 1;
        } else {
          counts[orderStatus] = (counts[orderStatus] || 0) + 1;
        }
        return counts;
      }, {});
      orderStatusCounts["Delivered"] = deliveredOrdersCount;
      setCount((prevCount) => ({
        ...prevCount,
        ...orderStatusCounts,
      }));
      if (confirmedResponse.data.results.length > 0) {
        const firstOrder = confirmedResponse.data.results[0];
        HandleOrder(firstOrder.id, 0, firstOrder.total_quantity, firstOrder.orderstatus, firstOrder.delivery_charges, firstOrder.packing_charges, firstOrder.discount_amount, firstOrder.total_amount, firstOrder.net_amount, firstOrder.user_name, firstOrder.client, firstOrder.delivery_agent, firstOrder.store, firstOrder.order_date, firstOrder);
        // HandleInvoice(firstOrder.id);

        setClientInfo(confirmedResponse.data.results[0].client)
        setOrderInfo(confirmedResponse.data.results[0])
        statuscount["New"] = confirmedResponse.data.count;
      }
      setCount((prev) => ({
        ...prev,
        New: statuscount["New"]
      }));
      SetOrderDetails(confirmedResponse.data.results);
      SetOrderFilters(confirmedResponse.data.results);

      TotalPages = Math.ceil(confirmedResponse.data.count / 20);

      setLoading(false);
    } catch (error) {
      // Handle error here
      setLoading(false);
      console.error("Error fetching order details:", error);
    }
  };

  const handleMapClick = (latitude, longitude) => {
    console.log(latitude, longitude)
    window.open(`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}&travelmode=driving`)
  }

  const handleInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 50 >=
        document.documentElement.scrollHeight
      ) {
        if (page < TotalPages) {
          console.log(TotalPages)
          setLoading2(true)
          page = page + 1
          let resp = await axiosConfig.get(`/accounts/orders/?user=&orderstatus=Confirmed&page=${page}&page_size=1000`)
          SetOrderDetails((Data) => [...Data, ...resp.data.results])
          SetOrderFilters((Data) => [...Data, ...resp.data.results])
          setLoading2(false)
        } else {
          setLoading2(false)
        }

      }

    } catch (error) {
      console.log(error)
    }

  };

  const timeConvert = (date) => {
    const givenDateTime = new Date(date);
    let hours = givenDateTime.getHours();
    const minutes = givenDateTime.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours %= 12;
    hours = hours || 12; // If hours is 0, set it to 12

    const newTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${ampm}`;

    return newTime;
  }

  const HandleSearch = async (e) => {
    const { name, value } = e.target
    setSearch(value)
    // let resp = await axiosConfig.get(`/accounts/orders/?user=&orderstatus=${Active}&expected_date_time_after=${formattedDate}&expected_date_time_before=${nextDate}&search=${value}&page_size=1000`)
    // SetOrderDetails(resp.data.results)
  }
  const HandleSearchDebounce = async () => {
    setLoading(true);
    try {
      const response = await axiosConfig.get(
        `/accounts/orders/?user=&orderstatus=${Active}&expected_date_time_after=${formattedDate}&expected_date_time_before=${nextDate}&search=${debouncedSearchTerm}&page_size=1000`
      );
      SetOrderDetails(response.data.results)
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const throttle = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (!timeoutId) {
        timeoutId = setTimeout(() => {
          func.apply(this, args);
          timeoutId = null;
        }, delay);
      }
    };
  };

  const throttledHandleInfiniteScroll = throttle(handleInfiniteScroll, 500); // Adjust the 
  const columns = [
    {
      name: "Item",
      selector: "",
      cell: (row) => <>
        {row.product &&
          <div className="itm-table">
            <div className="table-image-main">
              <img src={row.product.image ? row.product.image.image : ''} alt={row.product.name} className="table-itm-img" />
            </div>
            <div className="image-name-order">
              <div>{row.product.name}</div>
              <div>{row.product.weight_in_kg}</div>
            </div>
          </div>
        }
      </>,
      width: '300px'
    },
    {
      name: "Qty",
      selector: "quantity",
      cell: row => row.quantity

    },
    {
      name: "Price",
      selector: "price",
      cell: row => {
        if (row.product && row.price) {
          const offerPrice = parseFloat(row.product.offer_price);
          return offerPrice > 0 ? (offerPrice.toFixed(2)) : row.price;
        } else {
          return "N/A";
        }
      }
    },
    {
      name: "Amount",
      selector: "price",
      cell: row => (row.price * row.quantity).toFixed(2)
    },
  ]
  const HandleOrder = async (id, i, qty, status, delivery_charges, packing_charges, discount_amount, total_amount, net_amount, user_name, client, delivery_agent, store, time, order) => {
    setLoading(true)
    const responce1 = await axiosConfig.get(`/accounts/order-details/?order=${id}&page_size=1000`)
    SetOrderById(responce1.data.results)
    SetOrderId(i)
    setClientInfo(client)
    setOrderInfo(order)
    setOrders({ order_id: id, total_items: qty, status: status, delivery_charges: delivery_charges, packing_charges: packing_charges, discount_amount: discount_amount, total_amount: total_amount, net_amount: net_amount, user_name: user_name, delivery_agent: delivery_agent, store: store, time: time })
    setLoading(false)
    setInvoice(order.invoice)
  }
  // const HandleInvoice = async (id) => {
  //   const responce1 = await axiosConfig.get(`/accounts/invoice/?order=${id}`)
  //   setInvoice(...responce1.data.results)
  // }
  const InvoicePrint = async () => {
    try {
      const response = await axiosConfig.get(`/accounts/pdf/${Invoice.id}/`, {
        responseType: 'blob',
      });
      const blobUrl = URL.createObjectURL(response.data)
      window.open(blobUrl, '_blank');
    }
    catch (error) {
      console.log('Error fetching invoice PDF:', error);
    }
  };

  const Handlestatus = (status) => {
    if (status === "Confirmed") {
      setActive("Confirmed")
      OrderDetails("Confirmed")
    }
    if (status === "Accepted") {
      setActive("Accepted")
      OrderDetails("Accepted")
    }
    if (status === "Ready to ship") {
      setActive("Ready_to_Ship")
      OrderDetails("Ready to ship")
    }
    if (status === "Shipped") {
      setActive("Shipped")
      OrderDetails("Shipped")
    }
    if (status === "Delivered") {
      setActive("Delivered")
      OrderDetails("Delivered")
    }
    if (status === "Cancelled") {
      setActive("Cancelled")
      OrderDetails("Cancelled")
    }
  }
  const OderSCount = async (statuses) => {
    setLoading(true);
    try {
      const responses = await Promise.all(
        statuses.map(status => {
          const urlCondition = status === 'Delivered' ? "delivered_time_" : "expected_date_time_";
          return axiosConfig.get(`/accounts/orders/?user=&orderstatus=${status}&${urlCondition}after=${formattedDate}&${urlCondition}before=${nextDate}&page_size=1000`)
        })
      );
      console.log(responses, "responses")
      setLoading(false);
      const updatedCount = {
        ...count
      };
      responses.forEach((response, index) => {
        const status = statuses[index];
        if (status === "Confirmed") {
          updatedCount["New"] = response.data.count;
        }
        if (status === "Accepted") {
          updatedCount["Accepted"] = response.data.count;
        }
        if (status === "Ready to ship") {
          updatedCount["Ready_to_Ship"] = response.data.count;
        }
        if (status === "Shipped") {
          updatedCount["Shipped"] = response.data.count;
        }
        if (status === "Delivered") {
          updatedCount["Delivered"] = response.data.count;
        }
        if (status === "Cancelled") {
          updatedCount["Cancelled"] = response.data.count;
        }
      });

      setCount(updatedCount);
      if (responses.length > 0) {
        responses.map((response) => {
          if (response.data.results.length > 0) {
            SetOrderDetails(response.data.results);
          }
        })
        // SetOrderDetails(responses[0].data.results);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching order details:", error);
    }
  };

  const Handlebutttons = async (status) => {
    setLoadingButton(true)
    if (status === "Confirmed") {
      let resp = await axiosConfig.post(`/accounts/orders/${oreders.order_id}/order_status_update/`, { orderstatus: "Accepted" })
      setActive("Accepted")
      OderSCount(["Accepted", "Confirmed"])
      setLoadingButton(false)
    }
    else if (status === "Accepted") {
      let resp = await axiosConfig.post(`/accounts/orders/${oreders.order_id}/order_status_update/`, { orderstatus: "Ready to ship" })
      setActive("Ready_to_Ship")
      OderSCount(["Accepted", "Ready to ship"])
      setLoadingButton(false)
    }
    else if (status === "Ready to ship") {
      let resp = await axiosConfig.post(`/accounts/orders/${oreders.order_id}/order_status_update/`, { orderstatus: "Shipped" })
      setActive("Shipped")
      OderSCount(["Shipped", "Ready to ship"])
      setLoadingButton(false)
    }
    else if (status === "Shipped") {
      let resp = await axiosConfig.post(`/accounts/orders/${oreders.order_id}/order_status_update/`, { orderstatus: "Delivered" })
      setActive("Delivered")
      OderSCount(["Shipped", "Delivered"])
      setLoadingButton(false)
    }
    else if (status === "Picking") {
      let resp = await axiosConfig.post(`/accounts/orders/${oreders.order_id}/order_status_update/`, { orderstatus: "Packed" })
      setActive("Packed")
      OderSCount(["Packed", "Accepted"])
      setLoadingButton(false)
    }
    else if (status === "Cancel") {
      let resp = await axiosConfig.post(`/accounts/orders/${oreders.order_id}/order_status_update/`, { orderstatus: "Cancelled" })
      setpopupType("success")
      if (Active === "Ready_to_Ship") {
        OderSCount(["Cancelled", "Ready to ship"])
      } else if (Active === "Shipped") {
        OderSCount(["Cancelled", "Shipped"])
      } else if (Active === "Accepted") {
        OderSCount(["Cancelled", "Accepted"])
      } else if (Active === "Confirmed") {
        OderSCount(["Cancelled", "Confirmed"])
      }
      setMessage("Order Cancelled Successfully")
      setLoadingButton(false)
    }
    else if (status === "Shipped") {
      let resp = await axiosConfig.post(`/accounts/orders/${oreders.order_id}/order_status_update/`, { orderstatus: "Delivered" })
      setActive("Delivered")
      OderSCount(["Shipped", "Delivered"])
      setLoadingButton(false)
    }
    else if (status === "Modify") {
      console.log("modify", orderInfo)
      navigate(`/dashboard/orders/${oreders.order_id}/modify`, { state: { order_data: orderInfo, client_data: clientInfo } })
    }
  }
  const GetDeliveryAgents = async (store) => {
    try {
      const response = await axiosConfig.get(`/accounts/deliver-agents/?page_size=1000&store=${store}`)
      console.log("agents", response.data.results)
      const agent_options = response.data.results.map((result) => ({
        label: result.full_name,
        value: result.id
      }))
      setAgents(agent_options)
      console.log(oreders.delivery_agent, "llll")
      setSelectedAgent({ label: oreders.delivery_agent.full_name, value: oreders.delivery_agent.id })
    } catch (error) {
      console.log("Error getting clients : ", error)
    }
  }
  const handleChange = () => {
    setAgent(true)
    GetDeliveryAgents(oreders.store.id)
  }
  const handlePrint = async () => {
    try {
      if (Invoice) {
        window.open(Invoice.pdf_path, "_blank")
      } else {
        const url = `${axiosConfig.defaults.baseURL}accounts/pdf/${oreders.order_id}/`;
        window.open(url, '_blank');
        // alert("invoice is not available")
      }
    }
    catch (error) {
      console.log("Error getting invoice")
    }
  }
  const HandleButtons = () => {
    // if (Active === "Cancelled") {
    //   return (
    //     <>
    //       <div className="oreder-precessed">
    //         <div>
    //           <img className="success-image" src={require("./images/tick.png")} alt="tick" />
    //         </div>
    //         <div>
    //           <p>This order has been  Cancelled</p>
    //         </div>
    //       </div>
    //     </>
    //   )
    // }
    if (Active === "Confirmed" || Active === "Cancelled" || Active === "Accepted" || Active === "Ready_to_Ship" || Active === "Shipped" || Active === "Delivered") {
      return (
        <div className="rowAligned gap-20 flex-1">
          <div className="img-client">
            <img src={clientInfo.logo ? clientInfo.logo.image : ''} className="img-client" />
          </div>
          <div className="flex-1 column-h-71">
            <div className="rowBetweenCenter">
              <div className="rowAligned gap-10">
                <MdOutlineMapsHomeWork size={16} />
                <div className="client-address f_13_600">{clientInfo.client_name}.</div>
              </div>
              <div className="icon-container-16">
                <a href={`tel:+${clientInfo.mobile}`} style={{ height: '28px' }}>
                  <MdOutlineLocalPhone size={14} color='#1B7D78' />
                </a>
              </div>
            </div>
            <div className="rowBetweenCenter">
              <div className="rowAligned gap-10">
                <MdOutlineLocationOn size={16} />
                <div className="client-address f_13_500">{clientInfo.address}.</div>
              </div>
              <div className="icon-container-16" onClick={() => handleMapClick(clientInfo.latitude, clientInfo.longitude)}>
                <RiSendPlaneFill size={14} color='#1B7D78' />
              </div>
            </div>
            <div className="rowBetweenCenter">
              <div className="f_13_400 rowAligned gap-10">
                <MdOutlineEmail size={16} color="#8C8C8C" />
                <div style={{ color: '#8C8C8C' }}>{clientInfo.email}</div>
              </div>
              <div className="f_13_400 rowAligned gap-10">
                <MdOutlineLocalPhone size={16} color="#8C8C8C" />
                <div style={{ color: '#8C8C8C' }}>{clientInfo.mobile}</div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null
  };
  const HandleReassign = async (id) => {
    try {
      const response = await axiosConfig.post(`/accounts/orders/${id}/delivery_agent_update/`, { "delivery_agent_id": selectedAgent.value })
      const updated_order = await axiosConfig.get(`/accounts/orders/${id}/`)
      setOrderInfo(updated_order.data)
      console.log("Delivery Agent Reassigned : ", response)
      setAgent(false)
      setpopupType("success")
      setMessage("Delivery Agent Re-assigned")
    } catch (error) {
      console.log("Error Updating Delivery Agent : ", error)
      setpopupType("error")
      setMessage("Error Re-assigning Delivery Agent")
    }
  }
  useEffect(() => {
    OrderStatusTotal();
    window.addEventListener('scroll', throttledHandleInfiniteScroll);
    return () => {
      window.removeEventListener('scroll', throttledHandleInfiniteScroll);
    }
  }, [page])
  useEffect(() => {
    HandleSearchDebounce();
  }, [debouncedSearchTerm])
  return (
    <div>
      <DashboardLayout>
        <div className="row orders-margin ">
          <div className="col-2 Manage-Orders">
            <button className="btn btn-primary order-button" onClick={() => navigate('/dashboard/create_order')}>
              Create New Order
            </button>
            <h6>Manage Orders:</h6>
          </div>
          <div className="col-10 order-header-main">
            <div className="row">
              <div className="col-1  px-0  orders-inprogres-main">
                <div className="orders-inprogres-sub">
                  <h6>New</h6>
                </div>
                <div className="orders-inProgress">
                  <div className={Active === "Confirmed" ? "activeCalss-order" : "Class-order"} onClick={() => Handlestatus("Confirmed")}>
                    <div>
                      New
                    </div>
                    <div className={Active === "Confirmed" ? "length-data" : "lenght-Inactive"}>{count.New}</div>
                  </div>
                </div>
              </div>
              <div className="col-5  px-0  orders-inprogres-main">
                <div className="orders-inprogres-sub">
                  <h6>In Progress</h6>
                </div>
                <div className="orders-inProgress">
                  <div className={Active === "Accepted" ? "activeCalss-order" : "Class-order"} onClick={() => Handlestatus("Accepted")}>
                    <div>
                      Accepted
                    </div>
                    <div className={Active === "Accepted" ? "length-data" : "lenght-Inactive"}>{count.Accepted}</div>
                  </div>
                  <div className={Active === "Ready_to_Ship" ? "activeCalss-order" : "Class-order"} onClick={() => Handlestatus("Ready to ship")}>
                    <div>
                      Ready to Ship
                    </div>
                    <div className={Active === "Ready_to_Ship" ? "length-data" : "lenght-Inactive"}>{count.Ready_to_Ship}</div>
                  </div>
                  <div className={Active === "Shipped" ? "activeCalss-order" : "Class-order"} onClick={() => Handlestatus("Shipped")}>
                    <div>
                      Shipped
                    </div>
                    <div className={Active === "Shipped" ? "length-data" : "lenght-Inactive"}>{count.Shipped}</div>
                  </div>
                </div>
              </div>
              <div className="col-5 px-0 text-center" style={{ flex: 1 }}>
                <div className="orders-inprogres-sub">
                  <h6>Completed</h6>
                </div>
                <div className="orders-inProgress">
                  <div className={Active === "Delivered" ? "activeCalss-order" : "Class-order"} onClick={() => Handlestatus("Delivered")}>
                    <div>Delivered</div>
                    <div className={Active === "Delivered" ? "length-data" : "lenght-Inactive"}>{count.Delivered}</div>
                  </div>
                  {/* <div className={Active === "Cancelled" ? "activeCalss-order" : "Class-order"} onClick={() => Handlestatus("Cancelled")}>Cancelled</div> */}
                  <div className={Active === "Cancelled" ? "activeCalss-order" : "Class-order"} onClick={() => Handlestatus("Cancelled")}>
                    <div>Cancelled</div>
                    <div className={Active === "Cancelled" ? "length-data" : "lenght-Inactive"}>{count.Cancelled}</div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="row ">
          <div className=" col-3 input-with-icon zero-padding">
            <span className="icon"> <TbSearch /></span>
            <input
              className="input-control"
              type="text"
              placeholder="Search With Order Id"
              onChange={HandleSearch}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3 order-data" ref={scrollableContainerRef}>
            {
              loading ? (
                <div className="loader-container">
                  <img src={require('../Components/FormParser/images/loader.gif')} alt="Loading..." />
                </div>
              )
                :
                OrderDetail.map((order, i) => {
                  return (
                    <div className={OrderId === i ? "ordrs-bottom active" : 'ordrs-bottom'} key={i} onClick={() => HandleOrder(order.id, i, order.total_quantity, order.orderstatus, order.delivery_charges, order.packing_charges, order.discount_amount, order.total_amount, order.net_amount, order.user_name, order.client, order.delivery_agent, order.store, order.order_date, order)}>
                      <div className="d-flex justify-content-between ">
                        <div className="order_id">{order.id}</div>
                        <div>
                          {new Date(order.expected_date_time).toDateString() === new Date().toDateString() ? "Today" : "Tomorrow"}
                        </div>
                        <div className="order-time">{timeConvert(order.order_date)}</div>
                      </div>
                      <div className="d-flex justify-content-between my-2">
                        <div className="name-orders">{order.client ? order.client.client_name : ''}</div>
                        <div className="store-orders">{order.store ? order.store.name : ''}</div>
                      </div>
                      <div className="order-money-items">
                        <div className="f_12_400">{order.total_quantity} items</div>
                        <div className="f_12_400"><BiRupee />{parseFloat(order.net_amount)}</div>
                      </div>
                    </div>
                  )
                })
            }
            {loading2 && (
              <div className="loader-container">
                <img src={require('../Components/FormParser/images/loader.gif')} alt="Loading..." />
              </div>
            )}
          </div>
          {OrderBiId.length > 0 ?
            <div className="col-9 order-act">
              <div className="row border-order">
                <div className="col-6 order-actions">
                  {HandleButtons()}
                </div>
                <div className="col-4" style={{ borderRight: '1px solid #ddd' }}>
                  <div className="store-details-roder">
                    <p className="f_14_500">Order Id</p>
                    <p className="w_130 ellipsis">{oreders.order_id}</p>
                  </div>
                  <div className="store-details-roder">
                    <p className="f_14_500">Delivery Agent</p>
                    <p className="f_14_400 w_130 rowAligned">
                      <div className="ellipsis w_100">{oreders.delivery_agent?.full_name}</div>
                      {(Active !== "Delivered" && Active !== "Cancelled") &&
                        <MdOutlineEdit style={{ marginLeft: '10px' }} onClick={() => handleChange()} />
                      }
                    </p>
                  </div>
                  <div className="store-details-roder">
                    <p className="f_14_500">Store</p>
                    <p className="f_14_400 w_130 ellipsis">{oreders.store ? oreders.store.name : ''}</p>
                  </div>
                </div>
                <div className="col-2">
                  <div className="actions">
                    <div className="d-flex align-items-center justify-content-between">
                      <MdPrint size={24} onClick={() => handlePrint()} style={{ cursor: 'pointer' }} />
                      {(Active !== "Delivered" && Active !== "Cancelled") && (
                        <>
                          <MdOutlineEditNote size={24} onClick={() => Handlebutttons("Modify")} style={{ cursor: 'pointer' }} />
                          <IoCloseCircleOutline color="red" size={24} onClick={() => Handlebutttons("Cancel")} style={{ cursor: 'pointer' }} />
                        </>
                      )}
                    </div>
                    {orderInfo.order_date &&
                      <div className="f_13_600 rowAligned">
                        <div>Ordered : </div>
                        <div className="f_13_400">{formatDate(orderInfo.order_date)}</div>
                      </div>
                    }
                    {orderInfo.expected_date_time &&
                      <div className="f_13_600 rowAligned">
                        <div>Expected : </div>
                        <div className="f_13_400">{formatDate(orderInfo.expected_date_time)}</div>
                      </div>
                    }
                    {orderInfo.delivered_time &&
                      <div className="f_13_600 rowAligned">
                        <div>Delivered : </div>
                        <div className="f_13_400">{formatDate(orderInfo.delivered_time)}</div>
                      </div>
                    }
                    {loadingButton ? (
                      <div className="text-center">
                        <img src={require('../Components/FormParser/images/loader.gif')} alt="Loading..." className="loader-image" />
                      </div>
                    ) : (
                      <>
                        {Active === "Confirmed" && (
                          <button type="button" className="btn btn-pink f_14_500" onClick={() => Handlebutttons("Confirmed")}>
                            Accept Order
                          </button>
                        )}
                        {Active === "Accepted" && (
                          <button type="button" className="btn btn-pink f_14_500" onClick={() => Handlebutttons("Accepted")}>
                            Ready to Ship
                          </button>
                        )}
                        {Active === "Ready_to_Ship" && (
                          <button type="button" className="btn btn-pink f_14_500" onClick={() => Handlebutttons("Ready to ship")}>
                            Shipped
                          </button>
                        )}
                        {Active === "Shipped" && (
                          <button type="button" className="btn btn-pink f_14_500" onClick={() => Handlebutttons("Shipped")}>
                            Deliver Order
                          </button>
                        )}
                      </>
                    )}

                    {/* {Active === "Shipped" && <p>Waiting for Delivery</p>} */}
                  </div>
                </div>
              </div>
              {Active === "Delivered" &&
                <div className="row">
                  <div className="oreder-precessed">
                    <div>
                      <img className="success-image" src={require("./images/tick.png")} alt="tick" />
                    </div>
                    <div>
                      <p>This order has been processed successfully</p>
                    </div>
                  </div>
                </div>
              }
              {Active === "Cancelled" &&
                <div className="oreder-precessed">
                  <div>
                    <img className="success-image" src={require("./images/tick.png")} alt="tick" />
                  </div>
                  <div>
                    <p>This order has been  Cancelled</p>
                  </div>
                </div>}
              {/* {Active === "Shipped" ? <div className="ordrs-tota-button">
                <button type="button" className="button-orders-image"><GrGallery /> <span className="button-text-order">Photos by QC & Partner</span></button>
              </div> : <div className="ordrs-total">
                Total Items-{oreders.total_items}
              </div>} */}
              <div className="table-responsive order-header">
                <DataTable columns={columns} data={OrderBiId}
                  fixedHeader
                  customStyles={tableCustomStyles}
                  subHeader
                  progressPending={loading}
                  progressComponent={<div className="loader-container">
                    <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                  </div>}
                />
              </div>
              <div className="money-data">
                <div>
                  <div className="price-border">
                    <div className="details-money">
                      <p>sub Total</p>
                      <p>{oreders.total_amount}</p>
                    </div>
                    <div className="details-money">
                      <p>Delivery Charges</p>
                      <p>{oreders.delivery_charges}</p>
                    </div>
                  </div>
                  <div className="details-money-total">
                    <p>Grand Total</p>
                    <p>{oreders.net_amount}</p>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="col-9 text-center">
              <div className="no-orders">
              </div>
              <p className="para-table-nodata">No {Active} Orders</p>
            </div>
          }
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={agent}
            onHide={() => setAgent(false)}
          >
            <Modal.Header closeButton>
              Change Delivery Agent
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-7">
                  <div className="rowAligned gap-20 flex-1 delivery-client">
                    <div className="img-client">
                      <img src={clientInfo.logo ? clientInfo.logo.image : ''} className="img-client" />
                    </div>
                    <div className="flex-1 column-h-71">
                      <div className="rowBetweenCenter">
                        <div className="rowAligned gap-10">
                          <MdOutlineMapsHomeWork size={16} />
                          <div className="client-address f_13_600">{clientInfo.client_name}.</div>
                        </div>
                        <div className="icon-container-16">
                          <a href={`tel:+${clientInfo.mobile}`} style={{ height: '28px' }}>
                            <MdOutlineLocalPhone size={14} color='#1B7D78' />
                          </a>
                        </div>
                      </div>
                      <div className="rowBetweenCenter">
                        <div className="rowAligned gap-10">
                          <MdOutlineLocationOn size={16} />
                          <div className="client-address f_13_500">{clientInfo.address}.</div>
                        </div>
                        <div className="icon-container-16" onClick={() => handleMapClick(clientInfo.latitude, clientInfo.longitude)}>
                          <RiSendPlaneFill size={14} color='#1B7D78' />
                        </div>
                      </div>
                      <div className="rowBetweenCenter">
                        <div className="f_13_400 rowAligned gap-10">
                          <MdOutlineEmail size={16} color="#8C8C8C" />
                          <div style={{ color: '#8C8C8C' }}>{clientInfo.email}</div>
                        </div>
                        <div className="f_13_400 rowAligned gap-10">
                          <MdOutlineLocalPhone size={16} color="#8C8C8C" />
                          <div style={{ color: '#8C8C8C' }}>{clientInfo.mobile}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-5 d-flex flex-column justify-content-between" style={{ margin: '20px auto' }}>
                  <div className="row">
                    <div className="col-6 f_14_500">Order Id</div>
                    <div className="col-6 f_14_400" style={{ color: '#5C5C5C' }}>{oreders.order_id}</div>
                  </div>
                  <div className="row">
                    <div className="col-6 f_14_500">Amount</div>
                    <div className="col-6 f_14_400" style={{ color: '#5C5C5C' }}>{oreders.total_amount}</div>
                  </div>
                  <div className="row">
                    <div className="col-6 f_14_500">Items</div>
                    <div className="col-6 f_14_400" style={{ color: '#5C5C5C' }}>{oreders.total_items}</div>
                  </div>
                  <div className="row">
                    <div className="col-6 f_14_500">Time</div>
                    <div className="col-6 f_14_400" style={{ color: '#5C5C5C' }}>{timeConvert(oreders.time)}</div>
                  </div>
                  <div className="row">
                    <div className="col-6 f_14_500">Store</div>
                    <div className="col-6 f_14_400 ellipsis" style={{ color: '#5C5C5C' }}>{oreders.store ? oreders.store.name : ''}</div>
                  </div>
                </div>
              </div>
              {agents &&
                <Select
                  placeholder="Delivery Agent"
                  className="w-100"
                  options={agents}
                  value={selectedAgent}
                  onChange={(selectedOption) => setSelectedAgent(selectedOption)}
                />
              }
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary btn-block" onClick={() => HandleReassign(oreders.order_id)}>
                Confirm Re - Assign
              </button>
            </Modal.Footer>
          </Modal>
          {
            popupType !== "" &&
            <PopupModal popupType={popupType} setpopupType={setpopupType} Message={message} OrderDetails={OrderDetails} Active={Active} />
          }
        </div>
      </DashboardLayout >
    </div >
  )
}
export { Orders }