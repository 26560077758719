import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal } from "../Components";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import { BillsComponent } from "../Components/navbar/Billscomponent";
import { BiSearch } from "react-icons/bi";
import axiosConfig from "../Service/axiosConfig"
import { FormParser } from "../Components";
import Modal from 'react-bootstrap/Modal';
import Masters from "../Components/masters.json";
import DataTable from 'react-data-table-component';
import { Paymentmodal } from "../Components/PaymentModal/PaymentModal";
import { MdOutlineAccountCircle, MdOutlineRemoveRedEye, MdFilterList, MdOutlineMailOutline, MdOutlinePhone, MdOutlineStore, MdOutlineArrowOutward, MdOutlineArrowDownward } from "react-icons/md";
import "./customerManagement.css"
import { TbUserCheck } from "react-icons/tb";
import { RiMapPin2Line, RiDeleteBin6Line } from "react-icons/ri"
import { useNavigate, useParams } from "react-router-dom"
import { SiContactlesspayment } from "react-icons/si";
import Select from 'react-select';
import { useLocation } from "react-router-dom";
import { downloadExcel } from 'react-export-table-to-excel';

const ClientTrasactions = () => {
    const location = useLocation();
    let { id } = useParams();
    const rowData = location.state && location.state.rowData;
    const year_month = location.state && location.state.year_month;
    const [search, SetSearch] = useState({
        search: ""
    })
    const [filter_Data, setfilter_data] = useState({
        // from_date: rowData.period_from,
        // to_date: rowData.period_to,
        year_month: year_month
    })
    const handlFilterchange = (e) => {
        const { name, value } = e.target
        setfilter_data({ ...filter_Data, [name]: value })
    }
    let navigate = useNavigate();
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
        axiosConfig.get(`/accounts/transaction/?search=${value}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
    }
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
    const [Error, SetError] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("");
    const [Data, SetData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [RowId, SetRowId] = useState()
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [popupType, setpopupType] = useState("")
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('')
    const [rowId, setRowId] = useState(false)
    const [formData, setFormData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [customData, setCustomData] = useState("Hello from props!");
    const handleShow = (row) => {
        setCustomData(row)
        setShowModal(true);
    }
    const handleClose = () => setShowModal(false);
    const handleSort = (column, direction) => {
        console.log(column.selector, direction)
        axiosConfig.get(`/accounts/transaction/?client=${id}&page=${currentPage}&page_size=${RowPerPage}&ordering=${direction === "asc" ? column.selector : -column.selector}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/accounts/transaction/?client=${id}&page=${currentPage}&page_size=${RowPerPage}&year_month=${filter_Data.year_month}`)
            console.log(responce.data.results)
            SetData(responce.data.results)
            setLoading(false)
            setTotalRows(responce.data.count)
        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        GetData();
    }, [currentPage, RowPerPage, rowData, filter_Data.year_month, filter_Data.to_date]);
    const [customerid, setCustomerid] = useState()
    const HandleMouse = (row) => {
        setRowId(row.id)
    }
    const HandleRowmouseLeave = () => {
        setRowId()
    }
    const handleRowClick = (row) => {
        console.log(row)
        navigate(`/dashboard/masters/clients/${row}/edit`)
    }
    const [isSelectVisible, setIsSelectVisible] = useState(false);
    const handleFilterIconClick = () => {
        setIsSelectVisible(!isSelectVisible);
    };
    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "left"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "left",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    };
    function formatTimestamp(inputTimestamp) {
        const dateObject = new Date(inputTimestamp);

        // Extract individual components
        const year = dateObject.getFullYear() % 100;
        const month = dateObject.getMonth() + 1;
        const day = dateObject.getDate();
        const hours = 23;
        const minutes = 59;
        const seconds = 0;

        // Format components into the desired string
        const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

        return formattedDate;
    }

    const header = [
        "S.No",
        "Client",
        "Transaction Date",
        "Transaction Detail",
        "Amount",
        "Transaction Type"
    ];
    const body = Data.map((res, index) => [index + 1, res.client.client_name, res.datetime, res.transaction_detail, res.amount, res.transaction_type])

    function ExcellReports() {
        downloadExcel({
            fileName: "Client Transactions",
            sheet: "Client Transactions",
            tablePayload: {
                header: header,
                body: body,
            },
        });
    }

    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true,
            width: "80px"
        },
        // {
        //     name: "S.No",
        //     selector: "transaction_no",
        //     cell: (row, i) => `${row.transaction_no}`,
        //     sortable: true,
        // },
        {
            name: "Client",
            selector: "full_name",
            cell: row => <div className="client-detail">
                {/* <div className="client-logo" style={{ width: "82px", height: "82px" }}>
                    <img src={row.client.logo && row.client.logo.image} style={{ width: "100%", height: "100%" }} alt="logo" />
                </div> */}
                <div className="client-data">
                    <div className="client-name">{row.client.client_name}</div>
                    <div className={`client-type ${row.client.client_type === 'Contracted' ? 'contract-background' : 'variable-background'}`}>{row.client.client_type}</div>
                </div>

            </div>,
            sortable: true,
            width: "270px"
        },
        // {
        //     cell: row => <div className="store-info mx-4" >
        //         <div className="contact-details"><RiMapPin2Line className="contact-icon" />{row.client.address}</div>
        //         <div className="contact-email"><MdOutlineMailOutline className="contact-icon" />{row.client.email}</div>
        //         <div className="contact-phone"><MdOutlinePhone className="contact-icon" />{row.client.mobile}</div>
        //     </div>,
        //     sortable: true,
        //     width: "250px",
        // },
        {
            name: "Transaction Date",
            selector: "datetime",
            cell: row => `${row.datetime}`,
            sortable: true
        },
        {
            name: "Transaction Detail",
            selector: "previous_due",
            cell: row => <>
                <div>
                    {row.transaction_detail}
                </div>
            </>,
            sortable: true
        },
        {
            name: "Amount",
            selector: "amount",
            cell: row => `₹${row.amount}`,
            sortable: true,
            width: "100px"
        },
        {
            name: "Transaction Type",
            selector: "transaction_type",
            cell: row => <>
                <div className={`client-type ${row.transaction_type === 'paid' ? 'variable-background' : 'contract-background'}`} style={{ minWidth: '73px' }} >
                    {row.transaction_type}{row.transaction_type === "paid" ? <MdOutlineArrowDownward size={12} color="#008000" /> : <MdOutlineArrowOutward color="#A52A2A" size={12} />}
                </div>
            </>
            ,
            sortable: true
        },
    ]

    return (
        <>
            <DashboardLayout>
                <div className="child-sidenav">
                    <BillsComponent />
                    <div className="childnav-content">
                        <div className="row d-flex align-items-center" >
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-2 form-group mb-0">
                                        <label>Transactions</label>
                                    </div>
                                    <div className="col-6 form-group mb-0">
                                        <input type="month" className="form-control" name="year_month" value={filter_Data.year_month} onChange={handlFilterchange} />
                                    </div>
                                    <div></div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="d-flex justify-content-end">
                                    {/* <div className="input-cat-with-icon">
                                        <span className="icon-product"><BiSearch /></span>
                                        <input type="text" className="input-control" placeholder="Search" name="search" value={search.search} onChange={HandleSearch} />
                                    </div> */}
                                    <button className="btn btn-primary" onClick={() => ExcellReports()}>
                                        Export
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive marginTop">
                            <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                                paginationPerPage={RowPerPage}
                                paginationDefaultPage={currentPage}
                                onChangePage={(page) => setCurrentPage(page)}
                                paginationPerPageOptions={[10, 20, 25, 30, 40, 50, totalRows]}
                                paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50, totalRows]}
                                onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                                onSort={handleSort}
                                // onRowClicked={(row) => handleRowClick(row)}
                                onRowMouseEnter={HandleMouse}
                                onRowMouseLeave={HandleRowmouseLeave}
                                highlightOnHover
                                paginationServer fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                                progressComponent={<div className="loader-container">
                                    <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                </div>}
                            />
                        </div>
                        <div>
                            {popupType !== "" &&
                                <PopupModal setpopupType={setpopupType} popupType={popupType} Navigation="" Message={message} />
                            }
                            {deletePopupType !== "" &&
                                <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                            }
                            {
                                showModal &&
                                <Paymentmodal showModal={showModal} handleClose={handleClose} customData={customData} formatTimestamp={formatTimestamp} />
                            }
                        </div>

                    </div>
                </div>
            </DashboardLayout>
        </>
    )
}
export { ClientTrasactions }