import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import Masters from "../Components/masters.json";
import axiosConfig from "../Service/axiosConfig";
import DataTable from 'react-data-table-component';
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md"
import { BiSearch } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { SettingNavbarComponent } from "../Components/navbar/settingsnav";
import useDebouncedValue from "../Utils/Debounce";

const AdminProfile = () => {
    const [Loading, setLoading] = useState(false)
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [stores, setStores] = useState([])
    const [search, SetSearch] = useState({
        search: ""
    })
    const debouncedSearchTerm = useDebouncedValue(search.search, 500);
    const store_columns = [
        {
            name: 'ID',
            selector: 'id',
            sortable: true,
            width: "100px"
        },
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            width: "200px"
        },
        {
            name: 'Logo',
            cell: row => <img src={row.logo_image ? row.logo_image.image : ''} alt={row.name} style={{ width: '50px' }} />,
            width: "70px"
        },
        {
            name: 'Banner',
            cell: row => <img src={row.banner_image ? row.banner_image.image : ''} alt={row.name} style={{ width: '60px' }} />,
            width: "80px"
        },
        {
            name: 'Latitude',
            selector: 'latitude',
            sortable: true,
            width: "100px"
        },
        {
            name: 'Longitude',
            selector: 'longitude',
            sortable: true,
            width: "100px"
        },
        {
            name: 'Address',
            selector: 'address',
            sortable: true,
            width: "200px"
        },
        {
            name: 'Actions',
            width: "100px",
            cell: row => (
                <NavLink to={`/dashboard/settings/stores/${row.id}/edit`}>
                    <MdOutlineEdit />
                </NavLink>
            )
        }
    ];

    const GetStores = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/stores/stores/?page=${currentPage}&page_size=${RowPerPage}`)
            setStores(responce.data.results)
            setLoading(false)
            setTotalRows(responce.data.count)
        }
        catch (error) {
            console.log(error)
        }

    }

    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
    }
    const HandleSearchDebounce = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(
                `/stores/stores/?search=${debouncedSearchTerm}`
            );
            setStores(response.data.results);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };
    const handleSort = (column, direction) => {
        console.log(column.selector, direction)
        axiosConfig.get(`/stores/stores/?ordering=${direction === "asc" ? column.selector : -column.selector}`).then(res => {
            setStores(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };

    useEffect(() => {
        GetStores();
    }, [currentPage, RowPerPage])
    useEffect(() => {
        HandleSearchDebounce();
    }, [debouncedSearchTerm])
    return (
        <>
            <DashboardLayout>
                <div className="child-sidenav">
                    <SettingNavbarComponent />
                    <div className="childnav-content">
                        <div className="dashboard-header" style={{ alignItems: 'center' }}>
                            <h5 className="dashboard-subtitle">Stores</h5>
                            <NavLink to="/dashboard/settings/stores/add" style={{ textDecoration: 'none' }} ><button className="btn btn-primary">Add New</button></NavLink>
                        </div>
                        <div className="input-cat-with-icon">
                            <span className="icon-product"><BiSearch /></span>
                            <input type="text" className="input-control" placeholder="Search" name="search" value={search.search} onChange={HandleSearch} />
                        </div>
                        <div className="table-responsive">
                            <DataTable
                                columns={store_columns}
                                data={stores}
                                pagination paginationTotalRows={totalRows}
                                paginationPerPage={RowPerPage}
                                paginationDefaultPage={currentPage}
                                onChangePage={(page) => setCurrentPage(page)}
                                paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                                onSort={handleSort}
                                paginationServer fixedHeader subHeader customStyles={tableCustomStyles}
                                progressPending={Loading}
                                progressComponent={<div className="loader-container">
                                    <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                </div>}
                            />
                        </div>
                    </div>
                </div>
            </DashboardLayout>
        </>
    )
}
export { AdminProfile }
