import React, { useEffect, useState } from "react";

const Pagination = ({ setRowPerPage, folderGetNext,RowPerPage, fiesGetNext,setCurrentPage,count, folderGetPrevious,setCurrentPageFolder,LastPageFolder, fiesGetPrevious,currentPage,setLastPage,LastPage }) => {
    const [number, setNumber] = useState([100,200,300,400,500])
    const handlePage = (num) => {
        setRowPerPage(num)
    }
    const handleNextPage = () => {
        const nextPage = currentPage + 1;
         if (nextPage <= LastPage) {
        setCurrentPage(nextPage);
        folderGetNext();
        fiesGetNext();
    }
        fiesGetNext()
    }
    const handlePagesLastandfirst=()=>{
        setCurrentPageFolder(LastPageFolder);
        setCurrentPage(LastPage)
    }
    const handlePagefirst=()=>{
        setCurrentPageFolder(1);
        setCurrentPage(1)
    }
    const handlePrevPage = () => {

        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
            folderGetPrevious();
            fiesGetPrevious();
        }
    };
    console.log(currentPage)
    return (
        <>
            <div className="d-flex align-items-center justify-content-end" aria-label="Page navigation example">
                <span className="rowsPerpage">Rows Per Page:</span>
                <select className="serPerpage" name="select" onChange={(e) => handlePage(e.target.value)}>
                    {
                        number.map((num, i) => (
                            <option key={i} value={num}>
                                {num}
                            </option>
                        ))
                    }
                </select>{console.log(currentPage)}
                <span class="sc-iCnXOK sc-dyTUbJ kTisLj hLETqJ"style={{color:"gray"}}> {Math.min(currentPage * RowPerPage, count)}  of {count}</span>
                <button disabled={currentPage===1?true:false} onClick={handlePagefirst}   id="pagination-first-page" type="button" aria-label="First Page" aria-disabled="false" className="sc-eWhHU hsprtj file-icon-border" fdprocessedid="x88gi5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path><path fill="none" d="M24 24H0V0h24v24z"></path></svg></button>
                <button disabled={currentPage===1?true:false} onClick={handlePrevPage} id="pagination-previous-page" type="button" aria-label="Previous Page" aria-disabled="false" className="sc-eWhHU hsprtj file-icon-border" fdprocessedid="r4zc65"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></button>
                <button disabled={currentPage===LastPage} onClick={handleNextPage} id="pagination-next-page" type="button" aria-label="Next Page" aria-disabled="false" className="sc-eWhHU hsprtj file-icon-border" fdprocessedid="kw3ve8"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></button>
                <button disabled={currentPage===LastPage} onClick={handlePagesLastandfirst} id="pagination-last-page" type="button" aria-label="Last Page" aria-disabled="false" className="sc-eWhHU hsprtj file-icon-border" fdprocessedid="2l299i">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                        <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                        <path fill="none" d="M0 0h24v24H0V0z"></path>
                    </svg>
                </button>
            </div>
        </>
    )
}
export { Pagination }