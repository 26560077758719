import React, { useState, useEffect } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { SettingNavbarComponent } from "../Components/navbar";
import { BiSearch } from "react-icons/bi";
import { MdOutlineEdit, MdHistory } from "react-icons/md";
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import axiosConfig from "../Service/axiosConfig";
import Masters from "../Components/masters.json";
import useDebouncedValue from "../Utils/Debounce";

const Versions = () => {
    const [rowId, setRowId] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [RowPerPage, setRowPerPage] = useState(20)
    const [Data, SetData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [totalRows, setTotalRows] = useState(0);
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('');
    const [popupType, setpopupType] = useState("")
    const [formData, setFormData] = useState({});
    const [modalTitle, setModalTitle] = useState('Add New Version');
    const MastersModal = Masters.Version;
    const [Error, SetError] = useState("")
    const [search, setSearch] = useState("");
    const debouncedSearchTerm = useDebouncedValue(search, 500);
    const HandleMouse = (row) => {
        setRowId(row.id)
    }
    const HandleRowmouseLeave = () => {
        setRowId()
    }

    const Customeredit = (id) => {
        axiosConfig.get(`/sitedata/app-versions/${id}/`).then(res => {
            console.log(res.data)
            setFormData(res.data)
            setShow(true)
        }).catch(error => {
            console.log(error)
        })
    }

    const handleShow = () => {
        setModalTitle('Add New Version')
        setShow(true)
        setFormData({})
    };
    const handleClose = () => {
        setShow(false)
        setFormData({})
        setpopupType("")
        SetError("")
    };
    const handleSort = (column, direction) => {
        console.log(column.selector, direction)
        axiosConfig.get(`/sitedata/app-versions/?page=${currentPage}&page_size=${RowPerPage}&ordering=${direction === "asc" ? column.selector : -column.selector}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };

    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        setSearch(value)
    }

    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "center"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "center",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    };

    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true
        },
        {
            name: "App Type",
            selector: "app_type",
            cell: row => row.app_type,
            sortable: true
        },
        {
            name: "Version",
            selector: "version",
            cell: row => row.version,
            sortable: true
        },
        {
            name: "Release Notes",
            cell: row => <div>Bug Fixes</div>,
            sortable: true
        },
        {
            name: "Status",
            selector: row => {
                const HandleEdit = async () => {
                    setModalTitle('Edit Version')
                    setShow(true)
                    Customeredit(row.id)
                }
                return (
                    <>
                        {rowId === row.id ?
                            <div onClick={HandleEdit} className="d-flex gap-10">
                                <MdOutlineEdit className="client-actions" size={15} />
                                <MdHistory color="red" className="client-actions" size={15} />
                            </div>
                            :
                            <div className={`client-type ${row.is_active_version ? "contract-background" : "variable-background"}`}>
                                {row.is_active_version ? 'Active' : 'Inactive'}
                            </div>
                        }
                    </>
                )

            }
        }
    ]

    const formSubmit = (formdata, setFieldErrors) => {
        console.log("Version post data : ", formdata)
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        const formDataToSend = new FormData();
        formDataToSend.append("app_type", formdata.app_type);
        formDataToSend.append("version", formdata.version);
        formDataToSend.append("release_notes", formdata.release_notes);
        formDataToSend.append("is_active_version", formdata.is_active_version ? true : false);
        if (formdata.id) {
            axiosConfig.patch(`/sitedata/app-versions/${formdata.id}/`, formDataToSend)
                .then(res => {
                    console.log(res)
                    setMessage(res.data.message)
                    setpopupType("success")
                    GetData();
                    setShow(false);
                }).catch(error => {
                    console.log(error)
                    if (error.response.data.error) {
                        SetError(error.response.data.error)
                    }
                    setpopupType("error")
                })
        } else {
            axiosConfig.post("/sitedata/app-versions/", formDataToSend).then(res => {
                console.log(res)
                setMessage(res.data.message)
                setpopupType("success")
                GetData();
                setShow(false);
            }).catch(error => {
                console.log(error)
                if (error.response.data.error) {
                    SetError(error.response.data.error)
                }
                setpopupType(error)
            })
        }
    };

    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/sitedata/app-versions/?page=${currentPage}&page_size=${RowPerPage}&search=${debouncedSearchTerm}`)
            console.log(responce.data.results)
            SetData(responce.data.results)
            setLoading(false)
            setTotalRows(responce.data.count)
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        GetData();
    }, [currentPage, RowPerPage, debouncedSearchTerm]);

    return (
        <DashboardLayout>
            <div className="child-sidenav">
                <SettingNavbarComponent />
                <div className="childnav-content">
                    <div className="cat-button">
                        <div className="btn" onClick={handleShow} >
                            + Add New
                        </div>
                    </div>
                    <div className="input-cat-with-icon">
                        <span className="icon-product"><BiSearch /></span>
                        <input type="text" className="input-control" placeholder="Search" name="search" value={search} onChange={HandleSearch} />
                    </div>
                    <div className="table-responsive marginTop">
                        <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                            paginationPerPage={RowPerPage}
                            paginationDefaultPage={currentPage}
                            onChangePage={(page) => setCurrentPage(page)}
                            paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                            onSort={handleSort}
                            // onRowClicked={(row) => handleRowClick(row)}
                            onRowMouseEnter={HandleMouse}
                            onRowMouseLeave={HandleRowmouseLeave}
                            highlightOnHover
                            paginationServer fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                            progressComponent={<div className="loader-container">
                                <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                            </div>}
                        />
                    </div>
                    <Modal
                        size="lg"
                        show={show}
                        onHide={handleClose}
                        aria-labelledby="example-modal-sizes-title-lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-lg">
                                {modalTitle}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormParser modelObject={MastersModal} formData={formData} formSubmit={formSubmit} error={Error} buttonTitle={modalTitle === 'Edit Version' ? 'Edit Version' : 'Create New Version'} button='btn-block' />
                        </Modal.Body>
                    </Modal>
                    {popupType !== "" &&
                        <PopupModal setpopupType={setpopupType} popupType={popupType} Navigation="" Message={message} error={Error} />
                    }
                </div>
            </div>
        </DashboardLayout>
    )
}

export { Versions };