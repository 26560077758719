import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { MdTune, MdOutlineNextPlan, MdStorefront, MdOutlineMarkEmailRead, MdOutlinePayment, MdOutlineLocalShipping } from "react-icons/md";
import { RiFoldersLine, RiUser6Line } from "react-icons/ri";
import { BsPeople, BsCashStack } from "react-icons/bs"
import { GrDomain } from "react-icons/gr";
import { BsGraphUpArrow } from "react-icons/bs";

const SettingNavbarComponent = () => {
    return (
        <div className="categorie-header">
            <NavLink to="/dashboard/masters/sitesettings" className="categorie-subtitle" ><div className="child-title">
                <MdTune size={24} className="childnav-icon" />
                <p className="childnav-name">Site Settings</p>
            </div></NavLink>
            {/* <NavLink to="/dashboard/masters/emailgateway" className="categorie-subtitle" ><div className="child-title">
            <MdOutlineMarkEmailRead size={24} className="childnav-icon" />
            <p className="childnav-name">Email Gateway</p>
        </div></NavLink> */}
            <NavLink to="/dashboard/masters/paymentgateway" className="categorie-subtitle" ><div className="child-title">
                <MdOutlinePayment size={24} className="childnav-icon" />
                <p className="childnav-name">Payment Gateway</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/staff" className="categorie-subtitle" ><div className="child-title">
                <BsPeople size={24} className="childnav-icon" />
                <p className="childnav-name">Staff</p>
            </div> </NavLink>
            <NavLink to="/dashboard/masters/tax_classifications" className="categorie-subtitle" ><div className="child-title">
                <BsCashStack size={24} className="childnav-icon" />
                <p className="childnav-name">Tax Settings</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/delivery_agents" className="categorie-subtitle" ><div className="child-title">
                <MdOutlineLocalShipping size={24} className="childnav-icon" />
                <p className="childnav-name">Delivery Agent</p>
            </div></NavLink>
            {/* <NavLink to="/dashboard/masters/manageplans" className="categorie-subtitle" ><div className="child-title">
            <MdOutlineNextPlan size={24} className="childnav-icon" />
            <p className="childnav-name">Manage Plans</p>
        </div></NavLink> */}
            <NavLink to="/dashboard/settings/stores" className="categorie-subtitle" ><div className="child-title">
                <MdStorefront size={24} className="childnav-icon" />
                <p className="childnav-name">Stores (DC)</p>
            </div></NavLink>
            <NavLink to="/dashboard/settings/versions" className="categorie-subtitle" ><div className="child-title">
                <RiFoldersLine size={24} className="childnav-icon" />
                <p className="childnav-name">Versions</p>
            </div></NavLink>
            <NavLink to="/dashboard/settings/vendors" className="categorie-subtitle" ><div className="child-title">
                <RiUser6Line size={24} className="childnav-icon" />
                <p className="childnav-name">Vendors</p>
            </div></NavLink>
            <NavLink to="/dashboard/settings/account_head" className="categorie-subtitle" ><div className="child-title">
                <GrDomain size={24} className="childnav-icon" />
                <p className="childnav-name">Account Heads</p>
            </div></NavLink>
            <NavLink to="/dashboard/settings/business_vertical" className="categorie-subtitle" ><div className="child-title">
                <BsGraphUpArrow size={24} className="childnav-icon" />
                <p className="childnav-name">Business Verticals</p>
            </div></NavLink>
        </div>
    )
}

export { SettingNavbarComponent }