import React, { useState, useEffect } from "react";
import { DashboardLayout } from "../Components";
import { ReportsNav } from "../Components/navbar";
import DataTable from "react-data-table-component";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import axiosConfig from "../Service/axiosConfig";
import { AiOutlineFilePdf, AiOutlineFileExcel } from "react-icons/ai";
import { downloadExcel } from "react-export-table-to-excel";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import NumberFormat from "../Utils/NumberFormat";

const DayWisePLReport = () => {
  const [data, setData] = useState([]);
  const [stores, setStores] = useState([]);
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState([]);
  const [stats, setStats] = useState({});
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [RowPerPage, setRowPerPage] = useState(20);
  const [loading, setLoading] = useState(false);
  const { date, product_name, product, from, to } = useParams();
  const [formData, setFormData] = useState({
    fromDate: date
      ? date
      : from
      ? from
      : new Date().toISOString().split("T")[0],
    toDate: date ? date : to ? to : new Date().toISOString().split("T")[0],
    store: "",
    product: product ? product : "",
    category: "",
  });
  const [nextPageUrl, setNextPageUrl] = useState("");
  const [search, setSearch] = useState("");
  const Navigate = useNavigate();

  const columns = [
    {
      name: "S.No.",
      selector: (row, index) => index + 1,
      sortable: true,
      compact: true,
      center: true,
    },
    {
      name: "Date",
      selector: "date",
      reorder: true,
      sortable: true,
    },
    {
      name: "Revenue",
      selector: "orders_total",
      cell: (row) => `₹ ${NumberFormat(row.orders_total)}`,
      minWidth: "150px",
      reorder: true,
      right: true,
      sortable: true,
    },
    {
      name:"Purchase",
      selector:"grn_total",
      cell: (row) => `₹ ${NumberFormat(row.grn_total)}`,
      minWidth: "150px",
      reorder: true,
      right: true,
      sortable: true
    },
    {
      name:"Other Expenses",
      selector:"expenditure_total",
      cell: (row) => `₹ ${NumberFormat(row.expenditure_total)}`,
      minWidth: "150px",
      reorder: true,
      right: true,
      sortable: true
    },
    {
      name: "P&L",
      selector: "profit_or_loss",
      cell: (row) => `₹ ${NumberFormat(row.profit_or_loss)}`,
      minWidth: "150px",
      right: true,
      reorder: true,
      sortable: true,
    },
  ];

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSort = (column, direction) => {
    console.log(column, direction);
    // axiosConfig.get(`/accounts/orders/?orderstatus=Delivered&store=${formData.store ? formData.store : ''}&${selectedDateType === 'ordered_date' ? 'order_date_after' : 'expected_date_time_before'}=${formData.fromDate}&${selectedDateType === 'ordered_date' ? 'order_date_before' : 'expected_date_time_after'}=${formData.toDate}&page=${currentPage}&page_size=${RowPerPage}&ordering=${direction === "asc" ? column.name : `-${column.selector}`}`).then(res => {
    //     SetData(res.data.results)
    // setStats({
    //     "total_qty": response.data.total_qty,
    //     "total_amount": response.data.total_amount,
    //     "highest_sale_quantity": response.data.highest_sale_quantity,
    //     "lowest_sale_quantity": response.data.lowest_sale_quantity,
    //     "highest_sale_amount": response.data.highest_sale_amount,
    //     "lowest_sale_amount": response.data.lowest_sale_amount,
    //     "average_amount": response.data.average_amount,
    //     "average_qty": response.data.average_qty
    // })
    // }).catch(error => {
    //     console.log(error)
    // })
    // setCurrentPage(1);
  };

  const header = [
    "S.No",
    "Date",
    "Expenditure Total",
    "GRN Total",
    "Orders Total",
    "P&L",
  ];
  const body = data?.daywise_report?.map((res, index) => [
    index + 1,
    `${formData.fromDate}-${formData.toDate}`,
    res.expenditure_total,
    res.grn_total,
    res.orders_total,
    res.profit_or_loss,
  ]);

  function handleDownloadExcel() {
    downloadExcel({
      fileName: "Product-wise P&L Report",
      sheet: "Product-wise P&L Report",
      tablePayload: {
        header: header,
        body: body,
      },
    });
  }

  const HandlePdf = (data) => {
    localStorage.setItem("pdf_data", JSON.stringify(data));
    window.open(`${window.location.origin}/print`, "_blank");
  };
  const HandleSelect = (selectedOption, name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: selectedOption.value,
    }));
  };
  const handleSearch = (value) => setSearch(value);

  const GetFilterData = async () => {
    try {
      const store = await axiosConfig.get(`/stores/stores/?page_size=100`);
      const category = await axiosConfig.get(
        `/catlog/categories/?page_size=100&is_suspended=false`
      );
      const store_data = store.data.results;
      const category_data = category.data.results;
      setStores(store_data);
      setCategory(category_data);
    } catch (error) {
      console.log(error);
    }
  };

  const GetData = async () => {
    setLoading(true);
    try {
      const response = await axiosConfig.get(
        `accounts/daywise_profit_loss_reports/?from_date=${formData.fromDate}&to_date=${formData.toDate}`
      );
      const data = response.data;
      console.log("Daywise Data", data);
      setLoading(false);
      setData(data);
    } catch (error) {
      console.log(error);
    }
  };
  const CustomHeader = () => {
    return (
      <div className="rowEndCenter gap-10">
        <AiOutlineFileExcel
          className="report-icons"
          onClick={handleDownloadExcel}
        />
        {/* <AiOutlineFilePdf
                    className="report-icons"
                    onClick={() => HandlePdf(data)}
                /> */}
      </div>
    );
  };

  useEffect(() => {
    GetData();
    GetFilterData();
  }, []);

  return (
    <>
      <DashboardLayout>
        <div className="child-sidenav">
          <ReportsNav />
          <div className="flex-1 childnav-content">
            <div className="report-filters">
              <div className="form-group flex-1">
                <input
                  className="form-control"
                  type="date"
                  name="fromDate"
                  value={formData.fromDate}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group flex-1">
                <input
                  className="form-control"
                  type="date"
                  name="toDate"
                  value={formData.toDate}
                  onChange={handleChange}
                />
              </div>
              <div className="">
                {loading ? (
                  <img
                    src={require("../Components/FormParser/images/loader.gif")}
                    className="loader-image"
                    alt="loader"
                  />
                ) : (
                  <button
                    className="btn btn-primary"
                    style={{ height: "37px" }}
                    onClick={GetData}
                  >
                    Get Reports
                  </button>
                )}
              </div>
            </div>
            <div>
              <div
                className=" row rowBetweenCenter f_14_500"
                style={{ margin: "16px" }}
              >
                <div className="col-3">Other Expenses Total Rs.</div>
                <div className="col-3 f_14_400">{`₹ ${NumberFormat(data?.expenditure_total)}`}</div>
                <div className="col-3">Total Purchase Rs.</div>
                <div className="col-3 f_14_400">{`₹ ${NumberFormat(data?.grn_total)}`}</div>
              </div>
            </div>
            <div>
              <div
                className=" row rowBetweenCenter f_14_500"
                style={{ margin: "16px" }}
              >
                <div className="col-3">Total Revenue Rs.</div>
                <div className="col-3 f_14_400">{`₹ ${NumberFormat(data?.orders_total)}`}</div>
                <div className="col-3">Profit & Loss Rs.</div>
                <div className="col-3 f_14_400">{`₹ ${NumberFormat(data?.profit_or_loss)}`}</div>
              </div>
            </div>
              <DataTable
                title={<CustomHeader />}
                columns={columns}
                data={data?.daywise_report}
                pagination
                paginationTotalRows={totalRows}
                customStyles={tableCustomStyles}
                progressPending={loading}
                fixedHeader={true}
                fixedHeaderScrollHeight="70vh"
                progressComponent={
                  <div className="loader-container">
                    <img
                      src={require("../Components/FormParser/images/loader2.gif")}
                      alt="Loading..."
                    />
                  </div>
                }
              />
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};
export { DayWisePLReport };
