import React, { useState, useEffect } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { BillsComponent } from "../Components/navbar";
import { BiSearch } from "react-icons/bi";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import Masters from "../Components/masters.json";
import axiosConfig from "../Service/axiosConfig";
import DataTable from "react-data-table-component";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import { FaHandHoldingHeart } from "react-icons/fa";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import useDebouncedValue from "../Utils/Debounce";
import { AiOutlineFileExcel } from "react-icons/ai";
import { downloadExcel } from "react-export-table-to-excel";
import NumberFormat from "../Utils/NumberFormat";

const Expenditure = () => {
  const [show, setShow] = useState(false);
  const MastersModal = Masters.Expenditures;
  const [accountHeads, setAccountHeads] = useState([]);
  const [selectedAccountHead, setSelectedAccountHead] = useState(null);
  const [expenditures, setExpenditures] = useState([]);
  const [RowPerPage, setRowPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState({});
  const [popupType, setpopupType] = useState("");
  const [RowId, SetRowId] = useState();
  const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
  const [deletePopupType, setDeletepopupType] = useState("");
  const [Error, SetError] = useState("");
  const [message, setMessage] = useState("");
  let today_date = new Date().toISOString().split("T")[0];
  const [loading, setLoading] = useState(false);
  const [Id, SetId] = useState();
  const [filters, setFilters] = useState({
    from_date: today_date,
    to_date: today_date,
    search: "",
  });
  const debouncedSearchTerm = useDebouncedValue(filters.search, 500);
  const [totalRows, setTotalRows] = useState(0);
  const [modalTitle, setModalTitle] = useState("Add New Expenditure");
  const [loadCsv, setLoadCsv] = useState(false);

  const columns = [
    {
      name: "Expenditure Id",
      selector: "expendicture_no",
      sortable: true,
      minWidth: "150px",
      reorder: true,
    },
    {
      name: "Account Head",
      cell: (row) => row.account_head.title,
      sortable: true,
      minWidth: "150px",
      reorder: true,
    },
    {
      name: "Amount",
      selector: "amount",
      cell: (row) => `₹ ${NumberFormat(row.amount)}`,
      right: true,
      minWidth: "150px",
      sortable: true,
      reorder: true,
    },
    {
      name: "Created By",
      selector: "created_by",
      sortable: true,
      reorder: true,
    },
    {
      name: "Modified By",
      selector: "modified_by",
      sortable: true,
      reorder: true,
    },
    {
      name: "Created Date",
      selector: "cdate",
      sortable: true,
      width: "200px",
    },
    {
      name: "Modified Date",
      selector: "mdate",
      sortable: true,
      width: "200px",
    },
    {
      name: "Date of supply",
      selector: "date_of_supply",
      center: true,
      sortable: true,
    },
    {
      name: "Comments",
      selector: "comments",
      sortable: true,
      reorder: true,
      minWidth: "200px",
      wrap:true
    },
    {
      name: "ACTIONS",
      selector: (row) => {
        const HandleEdit = async () => {
          try {
            setShow(true);
            setModalTitle("Edit Expenditure");
            const response = await axiosConfig.get(
              `accounts/expendicture/${row.id}/`
            );
            let account_head = response.data.account_head.id;
            setFormData({ ...response.data, account_head: account_head });
          } catch (error) {
            console.log(error);
          }
        };
        const HandleExcel = (expenditure) => {
          const url = `${axiosConfig.defaults.baseURL}accounts/download-expendicture/${expenditure.id}/`;
          window.open(url, "_blank");
        };
        const DeleteAlert = () => {
          SetRowId(row.id);
          SetAlertDeletePopupType("success");
        };
        const CancelAlerrt = () => {
          SetAlertDeletePopupType("error");
        };
        const HandleDelete = async (row_id) => {
          await axiosConfig
            .delete(`accounts/expendicture/${row_id}/`)
            .then(() => {
              SetAlertDeletePopupType("error");
              setDeletepopupType("success");
              GetData();
            })
            .catch((error) => {
              setDeletepopupType("error");
              SetAlertDeletePopupType("error");
              console.log(error);
            });
        };
        const handleRevive = async () => {
          try {
            const response = await axiosConfig.post(
              `accounts/expendicture/${row.id}/revive/`
            );
            setpopupType("success");
            setMessage("Expenditure revived successfully");
            GetData();
          } catch (error) {
            console.log("Error While Reviving", error);
          }
        };
        return (
          <>
            {Id === row.id ? (
              row.is_suspended ? (
                <FaHandHoldingHeart
                  className="dashboard-tabe-data-actions"
                  onClick={handleRevive}
                />
              ) : (
                <>
                  <div className="row">
                    <div className="col-3">
                      <MdOutlineEdit
                        className="dashboard-tabe-data-actions"
                        onClick={HandleEdit}
                      />
                    </div>
                    <div className="col-3">
                      <MdDeleteOutline
                        className="dashboard-tabe-data-actions"
                        onClick={DeleteAlert}
                      />
                    </div>
                    <div className="col-3">
                      <AiOutlineFileExcel
                        className="dashboard-tabe-data-actions"
                        onClick={() => HandleExcel(row)}
                      />
                    </div>
                  </div>
                </>
              )
            ) : (
              <div
                className={`client-type ${
                  row.is_suspended
                    ? "variable-background"
                    : "contract-background"
                }`}
              >
                {row.is_suspended ? "Suspended" : "Active"}
              </div>
            )}
            {AlertDeletePopupType === "success" && (
              <AlertDeletePopupModal
                AlertDeletePopupType={AlertDeletePopupType}
                HandleDelete={HandleDelete}
                CancelAlerrt={CancelAlerrt}
                row={RowId}
              />
            )}
          </>
        );
      },
    },
  ];

  const tableCustomStyles = {
    rows: {
      style: {},
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        color: "#5C5C5C",
        justifyContent: "center",
        color: "rgb(33,37,41)",
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "28px",
      },
    },
  };

  const header = [
    "Expenditure Id",
    "Account Head",
    "Amount",
    "Created By",
    "Modified By",
    "Created Date",
    "Modified Date",
    "Date of supply",
    "Comments",
  ];
  async function handleDownloadExcel() {
    setLoadCsv(true);
    try {
        const params = {
            account_head: selectedAccountHead?.value || "",
            date_of_supply_after: filters.from_date,
            date_of_supply_before: filters.to_date,
            search: filters.search,
            page_size: 1000
        }
        const queryString = new URLSearchParams(params).toString();
        const response = await axiosConfig.get(`/accounts/expendicture/?${queryString}`)
        const data = response.data.results;
        if (data && data.length > 0) {
            const body = data.map((res, index) => [index + 1, res.account_head?.title, res.amount, res.created_by, res.modified_by, res.cdate, res.mdate, res.date_of_supply, res.comments || "-"]);
            downloadExcel({
                fileName: `Expenditures from ${filters.from_date} to ${filters.to_date}`,
                sheet: `Expenditures from ${filters.from_date} to ${filters.to_date}`,
                tablePayload: {
                    header,
                    body: body,
                },
            });
        }
    } catch (error) {
        console.log(error)
    }finally{
        setLoadCsv(false);
    }
  }
  const CustomHeader = () => {
    return (
      <div className="rowEndCenter gap-10">
        <AiOutlineFileExcel
          className={`report-icons ${loadCsv ? 'disable' : ''}`}
          onClick={handleDownloadExcel}
        />
      </div>
    );
  };

  const GetData = async () => {
    setLoading(true);
    try {
      const expenditure_res = await axiosConfig.get(
        `/accounts/expendicture/?is_suspended=&account_head=${
          selectedAccountHead ? selectedAccountHead.value : ""
        }&date_of_supply_after=${filters.from_date}&date_of_supply_before=${
          filters.to_date
        }&search=${filters.search}`
      );
      if (accountHeads.length === 0) {
        const acc_response = await axiosConfig.get(
          "/accounts/account-heads/?is_suspended=false"
        );
        let account_options = acc_response.data.results.map((account_head) => ({
          label: account_head.title,
          value: account_head.id,
        }));
        setAccountHeads(account_options);
      }
      setExpenditures(expenditure_res.data.results);
      setTotalRows(expenditure_res.data.count);
      setLoading(false);
    } catch (error) {
      console.log("error getting grns", error);
    }
  };

  const HandleChange = (e, filter) => {
    setFilters({ ...filters, [filter]: e.target.value });
  };

  const HandleClose = () => {
    SetError("");
    setShow(false);
    setFormData("");
  };

  const HandleShow = () => {
    setShow(true);
    setModalTitle("Add New Expenditure");
  };

  const HandleMouse = (row) => {
    SetId(row.id);
  };
  const HandleRowmouseLeave = () => {
    SetId();
  };

  const HandleSelectChange = (selectedOption) => {
    console.log(selectedOption);
    setSelectedAccountHead(selectedOption);
  };

  const formSubmit = async (Data) => {
    console.log(Data);
    const formDataToSend = new FormData();
    formDataToSend.append("amount", Data.amount);
    formDataToSend.append("comments", Data.comments);
    formDataToSend.append("account_head", Data.account_head);
    formDataToSend.append("date_of_supply", Data.date_of_supply);
    formDataToSend.append("mode_of_payment", Data.mode_of_payment);
    formDataToSend.append("paid_to", Data.paid_to);
    formDataToSend.append("vendor", Data.vendor);
    axiosConfig.defaults.headers["Content-Type"] = "multipart/form-data";
    try {
      let response;
      if (Data.id) {
        response = await axiosConfig.put(
          `/accounts/expendicture/${Data.id}/`,
          formDataToSend
        );
        console.log("updated expenditure successfully: ", response);
        setMessage("Expenditure Updated Successfully");
      } else {
        response = await axiosConfig.post(
          "/accounts/expendicture/",
          formDataToSend
        );
        console.log("created expenditure successfully: ", response);
        setMessage("Expenditure Created Successfully");
      }
      GetData();
      setpopupType("success");
      setShow(false);
      SetError("");
    } catch (error) {
      console.log("Error setting the site data: ", error);
      setpopupType("error");
      if (Object.values(error.response.data).length > 0) {
        SetError(Object.values(error.response.data)[0][0]);
      }
    }
  };

  useEffect(() => {
    GetData();
  }, [
    selectedAccountHead,
    filters.from_date,
    filters.to_date,
    debouncedSearchTerm,
  ]);
  return (
    <>
      <DashboardLayout>
        <div className="child-sidenav">
          <BillsComponent />
          <div className="childnav-content">
            <div className="row mb-3">
              <div className="col-3">
                <input
                  type="date"
                  className="form-control"
                  value={filters.from_date}
                  onChange={(e) => HandleChange(e, "from_date")}
                />
              </div>
              <div className="col-3">
                <input
                  type="date"
                  className="form-control"
                  value={filters.to_date}
                  onChange={(e) => HandleChange(e, "to_date")}
                />
              </div>
              <div className="col-4">
                <div className="input-cat-with-icon pe-0">
                  <span className="icon-product">
                    <BiSearch />
                  </span>
                  <input
                    type="text"
                    className="input-control"
                    placeholder="Search"
                    name="search"
                    onChange={(e) => HandleChange(e, "search")}
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="cat-button" onClick={() => HandleShow()}>
                  <div className="btn">+ Add New</div>
                </div>
              </div>
            </div>
            <Select
              placeholder="Select Account Head"
              options={[{ label: "All", value: "" }, ...accountHeads]}
              value={selectedAccountHead}
              onChange={(selectedOption) => HandleSelectChange(selectedOption)}
            />
            <div className="table-responsive marginTop" style={{ zIndex: 0 }}>
              <DataTable
                title={<CustomHeader />}
                columns={columns}
                data={expenditures}
                pagination
                paginationTotalRows={totalRows}
                paginationPerPage={RowPerPage}
                paginationDefaultPage={currentPage}
                onChangePage={(page) => setCurrentPage(page)}
                paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                onRowMouseEnter={HandleMouse}
                onRowMouseLeave={HandleRowmouseLeave}
                onChangeRowsPerPage={(RowsPerPageChange) =>
                  setRowPerPage(RowsPerPageChange)
                }
                // onSort={handleSort}
                paginationServer
                fixedHeader
                fixedHeaderScrollHeight="70vh"
                subHeader
                customStyles={tableCustomStyles}
                progressPending={loading}
                progressComponent={
                  <div className="loader-container">
                    <img
                      src={require("../Components/FormParser/images/loader2.gif")}
                      alt="Loading..."
                    />
                  </div>
                }
              />
            </div>
          </div>
          <Modal show={show} size="xl" onHide={() => HandleClose()} centered>
            <Modal.Header closeButton>
              <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormParser
                modelObject={MastersModal}
                formData={formData}
                formSubmit={formSubmit}
                error={Error}
              />
            </Modal.Body>
          </Modal>
          {popupType != "" && (
            <PopupModal
              setpopupType={setpopupType}
              popupType={popupType}
              Message={message}
              error={Error}
            />
          )}
        </div>
      </DashboardLayout>
    </>
  );
};

export { Expenditure };
