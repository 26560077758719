import React, { useEffect, useState } from "react";
import axiosConfig from "../Service/axiosConfig"
import { DashboardLayout, PopupModal } from "../Components";
import { BsFillCloudUploadFill } from "react-icons/bs"
import Select from 'react-select';
import { useNavigate, useParams } from "react-router";
import { AiOutlinePlus } from "react-icons/ai"
import { RxCross2 } from "react-icons/rx"
import { FileManager } from "../Components/FileManager";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ClientWiseTariff } from "./ClientWiseTariff";
import { NavbarComponent } from "../Components/navbar/NavComponent"
const ProductForm = () => {
    let { id } = useParams();
    const [catid, setcatid] = useState()
    const [selectedImage, setSelectedImage] = useState(null);
    const [popupType, setpopupType] = useState("")
    const [Error, SetError] = useState("")
    const [loading, setLoading] = useState(false)
    const [subcategoryOptions, setSubcategoryOptions] = useState([]);
    const [modalShow, setModalShow] = useState(false)
    const [existingSlug, setExistingSlug] = useState(null)
    const [key, setKey] = useState('Product Information');
    const [TaxOptions, setTaxOptions] = useState([]);
    const [LoadingButton, setLoadingButton] = useState();
    const [message, setMessage] = useState('')
    const [formData, setFormData] = useState({
        name: "",
        description: "",
        sort: "",
        image: "",
        price: 0,
        weight: 0,
        subcategory: "",
        track_inventry: false,
        stock: 0,
        images: [],
        brand: "",
        tax_classification: "",
        image_key: [],
        business_vertical_1: "",
        business_vertical_2: "",
        business_vertical_3: ""
    });


    const handleFormChange = (e) => {
        const { name, value } = e.target;
        console.log(formData, "inside handleFormChange")
        if (name === "name" && !existingSlug) {
            const auto_slug = value;
            const trimmedName = auto_slug.trim();
            const slug = trimmedName.replace(/\s+/g, '-').toLowerCase();
            setFormData({ ...formData, [name]: value, slug: slug })
        } else {
            setFormData({ ...formData, [name]: value });
        }
        if (name === "track_inventry") {
            setFormData({ ...formData, track_inventry: formData.track_inventry === false ? true : false })
        }
    };

    const handleFormChangesubcategorie = (selectedOption, name) => {
        setFormData({
            ...formData,
            [name]: {
                value: selectedOption.value,
                category: selectedOption.category // Assuming selectedOption has a 'category' property
            }
        });
    };
    const HandleDropdownChange = (selectedOption, name) => {
        setFormData({
            ...formData,
            [name]: {
                value: selectedOption.value,
                label: selectedOption.label
            }
        });
    };
    const HandleselectChange = (selectedOption, name) => {
        setFormData({
            ...formData,
            [name]: selectedOption.value
        });
    };
    const formSubmit = (event) => {
        let data = []
        event.preventDefault()
        const formDataToSend = new FormData();
        if (formData.name) {
            formDataToSend.append("name", formData.name);
        }
        if (formData.sort) {
            formDataToSend.append("sort", formData.sort);
        }
        if (formData.description) {
            formDataToSend.append("description", formData.description);
        }
        if (formData.business_vertical_1) {
            formDataToSend.append("business_vertical_1", formData.business_vertical_1);
        }
        if (formData.business_vertical_2) {
            formDataToSend.append("business_vertical_2", formData.business_vertical_2);
        }
        if (formData.business_vertical_3) {
            formDataToSend.append("business_vertical_3", formData.business_vertical_3);
        }
        if (formData.tax_classification) {
            formDataToSend.append("tax_classification", formData.tax_classification)
        }
        if (formData.weight) {
            formDataToSend.append("weight", formData.weight);
        }
        if (formData.free_delivery) {
            formDataToSend.append("free_delivery", formData.free_delivery);
        }
        formData.subcategory.forEach((value, index) => {
            formDataToSend.append(`subcategory`, value);
        });
        if (formData.brand) {
            formDataToSend.append("brand", formData.brand);
        }
        if (formData.image_key && formData.image_key.length > 0) {
            let data = formData.image_key.map(image => image.id);
            formDataToSend.append("images", JSON.stringify(data));
        }
        if (formData.track_inventry) {
            formDataToSend.append("track_inventry", formData.track_inventry);
            formDataToSend.append("stock", formData.stock)
        }
        if (formData.category) {
            formDataToSend.append("category", formData.category);
        }
        if (formData.price) {
            formDataToSend.append("price", formData.price);
        }
        axiosConfig.defaults.headers["Content-Type"] = "multipart/form-data";
        setLoadingButton(true)
        if (id) {
            axiosConfig
                .put(`catlog/products/${id}/`, formDataToSend)
                .then((res) => {
                    console.log(res);
                    setMessage('Product Updated Successfully')
                    setpopupType("success");
                    SetError("")
                    setLoadingButton(false)
                })
                .catch((error) => {
                    let errorData = {}
                    setLoadingButton(false)
                    console.log(error.response.data)
                    if (error.response.data.error) {
                        SetError(error.response.data.error)
                    }
                    if (error.response.data?.measurement_unit?.[0]) {
                        SetError("measurement_unit is required")
                    }
                    if (error.response.data?.price?.[0]) {
                        SetError("Price must be a non-empty number.")
                    }
                    if (error.response.data?.weight?.[0]) {
                        SetError("weight must be a non-empty number.")
                    }
                    setpopupType("error");
                });
        } else {
            axiosConfig
                .post(`catlog/products/`, formDataToSend)
                .then((res) => {
                    console.log(res);
                    setMessage('Product Created successfully')
                    setpopupType("success");
                    SetError("")
                    setLoadingButton(false)
                })
                .catch((error) => {
                    let errorData = {}
                    console.log(error)
                    setLoadingButton(false)
                    if (error.response.data.error) {
                        SetError(error.response.data.error)
                    }
                    if (error.response.data?.measurement_unit?.[0]) {
                        SetError("measurement_unit is required")
                    }
                    if (error.response.data?.price?.[0]) {
                        SetError("Price must be a non-empty number.")
                    }
                    if (error.response.data?.weight?.[0]) {
                        SetError("weight must be a non-empty number.")
                    }
                    if (error.response.data?.images?.[0]) {
                        SetError("images must be a non-empty .")
                    }
                    setpopupType("error")
                });
        }
    };
    const catedit = () => {
        axiosConfig
            .get(`catlog/products/${id}/`)
            .then((res) => {
                HandleImageProduct(res.data)
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const convertImageURLToBlob = async (imageURL, fileName) => {
        const response = await fetch(imageURL);
        const blob = await response.blob();
        return new File([blob], fileName, { type: blob.type });
    };

    const HandleImageProduct = async (product) => {
        console.log(product)
        try {
            const res = await axiosConfig.get(`catlog/product-images/?product=${product.id}&default_image=`);
            console.log(res)
            if (res.data.results.length > 0) {
                res.data.results.forEach((images) => {
                    const imageId = images.image.id;
                    const imageUrl = images.image.image;
                    setFormData((prevFormData) => {
                        const newData = [...prevFormData.images, imageId];
                        const newEditData = [
                            ...prevFormData.image_key,
                            { id: imageId, image: imageUrl },
                        ];

                        return {
                            ...product,
                            images: newData,
                            image_key: newEditData,
                            brand: product.brand ? product.brand.id : "",
                            tax_classification: product.tax_classification ? product.tax_classification.id : '',
                        };
                    });
                });
            }
            else {
                setFormData({ ...product, image_key: product.image ? [product.image] : "" })

            }
        } catch (error) {
            console.error(error);
        }
    };
    const [Branddata, setBranddata] = useState([])
    async function fetchAllData() {
        const response = await axiosConfig.get(`/catlog/brands/?page_size=1000`);
        setBranddata(response.data.results);
    }
    const fetchTax = async () => {
        const response = await axiosConfig.get(`masters/taxclasses/?page_size=1000`);
        console.log("All allTax:", response.data.results);
        setTaxOptions(response.data.results);
    };
    useEffect(() => {
        if (id) {
            catedit();
        }
        const fetchCategories = async () => {
            const response = await axiosConfig.get(`catlog/sub-categories/?page_size=300&is_suspended=false`)
            setSubcategoryOptions(response.data.results)
        }
        fetchCategories();
        fetchTax();
        fetchAllData();
    }, [])
    const handleMultiSelectChange = (selectedOption, value, name) => {
        let data = []
        selectedOption.forEach(res => {
            data.push(res.value)
        })
        setFormData({ ...formData, [name]: (data) });
    };
    const handleSelectChange = (selectedOption, name) => {
        setFormData({ ...formData, [name]: selectedOption.value });
    };
    const handledefaultSlug = () => {
        const name = formData.name;
        const trimmedName = name.trim();
        const slug = trimmedName.replace(/\s+/g, '-').toLowerCase();
        setFormData({ ...formData, slug: slug });
    };
    const removeImageByIndex = (indexToRemove) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            image_key: prevFormData.image_key.filter((_, index) => index !== indexToRemove),
        }));
    };
    let navigate = useNavigate();
    const HandleNavigate = () => {
        navigate("/dashboard/masters/products")
    }
    const [dragging, setDragging] = useState(false);
    const [files, setFiles] = useState([]);
    const Handlefilemanager = () => {
        setModalShow(true)
    }
    const handleImageDragStart = (e, index) => {
        e.dataTransfer.setData("imageIndex", index);
    };

    const handleImageDrop = (e) => {
        e.preventDefault();
        const sourceIndex = e.dataTransfer.getData("imageIndex");
        const destinationIndex = 0; // Assuming you want to set the dragged image as the main image
        const imagesCopy = [...formData.image_key];

        // Swap the images
        const temp = imagesCopy[destinationIndex];
        imagesCopy[destinationIndex] = imagesCopy[sourceIndex];
        imagesCopy[sourceIndex] = temp;

        setFormData({
            ...formData,
            image_key: imagesCopy,
        });
    };
    // console.log(formData)
    return (
        <DashboardLayout>
            <div className="child-sidenav">
                <NavbarComponent />
                <div className="childnav-content">
                    <div className="client-form">
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3">
                            <Tab eventKey="Product Information" title="Product Information">
                                <div>
                                    <form onSubmit={(e) => formSubmit(e, null)}>
                                        <div className="dashboard-header">
                                            <h5 className="dashboard-subtitle">{id ? "Edit Item" : "Add Item"}</h5>
                                            <div className="mx-4">
                                                <button type="submit" className="btn btn btn-white" onClick={HandleNavigate}>Cancel</button>
                                            </div>
                                            <div>
                                                <button type="submit" className="btn btn btn-primary">{LoadingButton ? <div>
                                                    <img src={require('../Components/FormParser/images/loader.gif')} alt="Loading..." className="loader-image" />
                                                </div> : "Submit"}</button>
                                            </div>
                                        </div>
                                        {/* {Error && <div className="Loginerror">
                                <p>{Error}</p>
                            </div>} */}
                                        <div className="row align-items-center mt-2">
                                            <div className="col-12">
                                                <div className="flex-container">
                                                    {formData.image_key && formData.image_key.length > 0 && (
                                                        <div className="image-field">
                                                            <div
                                                                onDrop={(e) => handleImageDrop(e)}
                                                                onDragOver={(e) => e.preventDefault()}
                                                            >
                                                                <img
                                                                    className="image-item"
                                                                    src={
                                                                        formData.image_key[0] && formData.image_key[0].image
                                                                            ? formData.image_key[0].image
                                                                            : ""
                                                                    }
                                                                    alt={
                                                                        formData.image_key[0] && formData.image_key[0].image
                                                                            ? formData.image_key[0].image
                                                                            : "images"
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="">

                                                        {formData.image_key && formData.image_key.length > 0 && (
                                                            <div className="image-grid">
                                                                {formData.image_key.map((image, index) => {
                                                                    // Add a null check for 'image' property
                                                                    const imageUrl = image && image.image ? image.image : '';

                                                                    return (
                                                                        <div className="flex-item-image" key={index} draggable onDragStart={(e) => handleImageDragStart(e, index)}>
                                                                            <div>
                                                                                <div className="image-container">
                                                                                    <img src={imageUrl} alt={`Image ${index + 1}`} className="grid-item" style={{ width: '100px', height: '100px' }} />
                                                                                    <RxCross2 onClick={() => removeImageByIndex(index)} className="cross-icon" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        )}

                                                        <div>
                                                            <div className="upload-multiple" onClick={Handlefilemanager}>
                                                                <div className="upload-plus">
                                                                    <label htmlFor="imageInput1">
                                                                        <AiOutlinePlus className="upload-width" />
                                                                    </label>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-4">
                                                <div className="form-field">
                                                    <label htmlFor="name">Name:</label>
                                                    <input
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        className="form-control"
                                                        value={formData.name}
                                                        onChange={(e) => handleFormChange(e)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-field">
                                                    {console.log(subcategoryOptions.length)}
                                                    <label htmlFor="subcategory">Subcategory:</label>
                                                    <Select
                                                        isMulti
                                                        options={subcategoryOptions.map((res) => ({ value: res.id, label: res.name }))}
                                                        value={subcategoryOptions
                                                            .filter((res) => Array.isArray(formData.subcategory) && formData.subcategory.includes(res.id))
                                                            .map((res) => ({ value: res.id, label: res.name }))
                                                            .filter((option, index, self) =>
                                                                self.findIndex((o) => o.value === option.value) === index
                                                            )}
                                                        onChange={(selectedOption) => handleMultiSelectChange(selectedOption, selectedOption.value, "subcategory")}
                                                        placeholder="Select Subcategories"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-field">
                                                    <label htmlFor="price">Price:</label>
                                                    <input
                                                        type="text"
                                                        id="price"
                                                        name="price"
                                                        className="form-control"
                                                        value={formData.price}
                                                        onChange={(e) => handleFormChange(e)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-field">
                                                    <label htmlFor="sort">Sort:</label>
                                                    <input
                                                        type="number"
                                                        id="sort"
                                                        name="sort"
                                                        className="form-control"
                                                        value={formData.sort}
                                                        onChange={(e) => handleFormChange(e)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-field">
                                                    <label htmlFor="weight">weight in kg’s:</label>
                                                    <div className="flexitem">
                                                        <input
                                                            type="text"
                                                            id="weight"
                                                            name="weight"
                                                            className="input-control"
                                                            value={formData.weight}
                                                            onChange={(e) => handleFormChange(e)}
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text kg-tag">kg</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-4">
                                                <label className="form-label">Tax:</label>
                                                <Select options={TaxOptions.map((res) => (
                                                    { "value": res.id, "label": res.title }
                                                ))} placeholder="Tax" value={TaxOptions.map(res => ({ "value": res.id, "label": res.title })).find(res => res.value === formData.tax_classification)} onChange={(selectedOption) => HandleselectChange(selectedOption, "tax_classification")} />
                                            </div>
                                            <div className="form-group col-4">
                                                <label className="form-label">Brand:</label>
                                                <Select options={Branddata.map((res) => (
                                                    { "value": res.id, "label": res.brand_name }
                                                ))} placeholder="brand" value={Branddata.map(res => ({ "value": res.id, "label": res.brand_name })).find(res => res.value === formData.brand)} onChange={(selectedOption) => HandleselectChange(selectedOption, "brand")} />
                                            </div>
                                            <div className="col-12">
                                                <div className="form-field">
                                                    <label htmlFor="description">Description:</label>
                                                    <textarea
                                                        type="text"
                                                        id="description"
                                                        name="description"
                                                        className="form-control"
                                                        rows="6"
                                                        value={formData.description}
                                                        onChange={(e) => handleFormChange(e)}
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="margin-inventry">
                                            <div className="display-head">
                                                <div>
                                                    <h5 className="dashboard-subtitle-custom">Inventory</h5>
                                                </div>
                                            </div>
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" name="track_inventry" checked={formData.track_inventry} value="true" onChange={handleFormChange} />
                                                <label class="form-check-label" for="flexSwitchCheckDefault">Track inventory</label>
                                            </div>
                                            {formData.track_inventry === true && <div className="row">
                                                <div className="col-4">
                                                    <div className="form-field">
                                                        <label htmlFor="description">Inventory:</label>
                                                        <input
                                                            type="text"
                                                            id="stock"
                                                            name="stock"
                                                            className="form-control"
                                                            value={formData.stock}
                                                            onChange={(e) => handleFormChange(e)}
                                                            required={formData.track_inventry}
                                                        />
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    </form>
                                    {popupType != "" &&
                                        <PopupModal setpopupType={setpopupType} popupType={popupType} Message={message} Navigation="/dashboard/masters/products" error={Error} />
                                    }
                                    {
                                        modalShow &&
                                        <FileManager modalShow={modalShow} setModalShow={setModalShow} setFormData={setFormData} formData={formData} ismultiple={true} value="image_key" />
                                    }
                                </div>
                            </Tab>
                            {id && (
                                <Tab eventKey="Client Wise Tariff" title="Client Wise Tariff">
                                    <ClientWiseTariff />
                                </Tab>
                            )}
                        </Tabs>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}
export { ProductForm }
