import React, { useEffect, useState } from "react";
import { DashboardLayout } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig";
import { MdOutlineDownload, MdOutlineEdit } from "react-icons/md";
import Select from "react-select";
import DataTable from "react-data-table-component";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { useDownloadExcel, downloadExcel } from "react-export-table-to-excel";
import { ReportsNav } from "../Components/navbar";
import { AiOutlineFileExcel } from "react-icons/ai";
import NumberFormat from "../Utils/NumberFormat";

const BillItemList = () => {
  let today_date = new Date().toISOString().split("T")[0];
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [RowPerPage, setRowPerPage] = useState(20);
  const [UserData, SetUserData] = useState({});
  const [ExcellData, SetExcellData] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [formData, setFormData] = useState({
    fromDate: today_date,
    toDate: today_date,
    store: localStorage.getItem("user_data")
      ? [JSON.parse(localStorage.getItem("user_data")).store]
      : [],
    client: null,
  });
  const [dateType, setDateType] = useState([
    { label: "Ordered Date", value: "ordered_date" },
    { label: "Expected Date", value: "expected_date" },
    { label: "Delivered Date", value: "delivered_date" },
  ]);
  const [selectedDateType, setSelectedDateType] = useState(dateType[0].value);
  const [storeOptions, setStoreOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [Data, SetData] = useState([]);
  let navigate = useNavigate();

  const GetData = async () => {
    setLoading(true);
    setIsLoading(true);
    let date_filter_from =
      selectedDateType === "ordered_date"
        ? "order_date_after"
        : selectedDateType === "expected_date"
        ? "expected_date_time_after"
        : "delivered_time_after";
    let date_filter_to =
      selectedDateType === "ordered_date"
        ? "order_date_before"
        : selectedDateType === "expected_date"
        ? "expected_date_time_before"
        : "delivered_time_before";
    try {
      const responce = await axiosConfig.get(
        `/accounts/orders/?orderstatus=&${date_filter_from}=${
          formData.fromDate
        }&${date_filter_to}=${formData.toDate}&stores=${
          formData.store ? formData.store : ""
        }&clients=${
          formData.client ? formData.client : ""
        }&page=${currentPage}&page_size=${RowPerPage}`
      );
      SetData(responce.data.results);
      setLoading(false);
      setTotalRows(responce.data.count);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleSort = (column, direction) => {
    console.log(column, direction);
    axiosConfig
      .get(
        `/accounts/orders/?orderstatus=Delivered&store=${
          formData.store ? formData.store : ""
        }&${
          selectedDateType === "ordered_date"
            ? "order_date_after"
            : "expected_date_time_before"
        }=${formData.fromDate}&${
          selectedDateType === "ordered_date"
            ? "order_date_before"
            : "expected_date_time_after"
        }=${
          formData.toDate
        }&page=${currentPage}&page_size=${RowPerPage}&ordering=${
          direction === "asc" ? column.name : `-${column.selector}`
        }`
      )
      .then((res) => {
        SetData(res.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
    setCurrentPage(1);
  };

  const HandlePrint = (invoice) => {
    if (invoice) {
      window.open(invoice.pdf_path);
    }
  };

  const HandleEdit = (order) => {
    navigate(`/dashboard/orders/${order.id}/modify`, {
      state: { order_data: order, client_data: order.client },
    });
  };

  const HandleExcel = (order) => {
    const url = `${axiosConfig.defaults.baseURL}accounts/download-order-history/${order.id}/`;
    window.open(url, "_blank");
  };

  function formatDateTime(dateTimeString) {
    const optionsDate = { year: "numeric", month: "short", day: "numeric" };
    const optionsTime = { hour: "numeric", minute: "numeric", hour12: true };

    const dateTime = new Date(dateTimeString);

    const formattedDate = dateTime.toLocaleDateString("en-US", optionsDate);
    const formattedTime = dateTime.toLocaleTimeString("en-US", optionsTime);

    return `${formattedDate} - ${formattedTime}`;
  }

  const columns = [
    {
      name: "Order ID",
      selector: "id",
      sortable: true,
      width: "120px",
    },
    {
      name: "Invoice ID",
      selector: "invoice",
      cell: (row) => <div>{row.invoice ? row.invoice.invoice_id : ""}</div>,
      sortable: true,
      width: "120px",
    },
    {
      name: "Client",
      cell: (row) => <div>{row.client?.client_name}</div>,
      selector: "client",
      sortable: true,
      width: "100px",
    },
    {
      name: "Store",
      selector: "store",
      cell: (row) => <div>{row.store.name}</div>,
      sortable: true,
      width: "100px",
    },
    {
      name: "Delivery Agent",
      selector: "delivery_agent",
      cell: (row) => (
        <div className="ellipsis w-120">{row.delivery_agent?.full_name}</div>
      ),
      sortable: true,
      width: "120px",
    },
    {
      name: "Total Amount",
      selector: "total_amount",
      minWidth: "150px",
      cell: (row) => `₹ ${NumberFormat(row.total_amount)}`,
      sortable: true,
      right: true,
      width: "100px",
    },
    {
      name: "Total Items",
      selector: "total_quantity",
      minWidth: "150px",
      sortable: true,
      center: true,
    },
    {
      name: "Ordered Date",
      selector: "order_date",
      cell: (row) => <div>{formatDateTime(row.order_date)}</div>,
      sortable: true,
      width: "120px",
    },
    {
      name: "Delivered Date",
      selector: "delivered_time",
      cell: (row) => (
        <div>
          {row.orderstatus === "Delivered"
            ? formatDateTime(row.delivered_time)
            : "Not delivered"}
        </div>
      ),
      sortable: true,
      width: "120px",
    },
    {
      name: "Status",
      selector: "orderstatus",
    },
    {
      name: "Invoice",
      cell: (row) => (
        <div style={{ cursor: "pointer" }} className="d-flex gap-10">
          {row.invoice && (
            <MdOutlineDownload
              size={15}
              color={"#1B7D78"}
              onClick={() => HandlePrint(row.invoice)}
            />
          )}
          <MdOutlineEdit
            size={15}
            color={"#1B7D78"}
            onClick={() => HandleEdit(row)}
          />
          <AiOutlineFileExcel
            size={15}
            color={"#1B7D78"}
            onClick={() => HandleExcel(row)}
          />
        </div>
      ),
      width: "80px",
    },
  ];

  const handleRowsPerPageChange = (event) => {
    console.log(event.target.value);
    setCurrentPage(1);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const fetchStore = async () => {
    const response = await axiosConfig.get(`/stores/stores/?page_size=1000`);
    setStoreOptions([
      { id: "", name: "---Select Store---" },
      ...response.data.results,
    ]);
  };

  const fetchClients = async () => {
    const response = await axiosConfig.get(
      `/clients/clients/?store=${formData.store}&page_size=1000&is_suspended=false`
    );
    setClientOptions([
      { id: "", client_name: "---Select Client---" },
      ...response.data.results,
    ]);
    setFilteredClients([
      { id: "", client_name: "---Select Client---" },
      ...response.data.results,
    ]);
  };

  const HandleselectChange = (selectedOptions, fieldName) => {
    const selectedValues = selectedOptions.map((item) => item.value);
    if (fieldName === "store") {
      if (selectedOptions.length === 0) {
        setFilteredClients(clientOptions);
      } else {
        let filtered_clients = clientOptions.filter((client) => {
          return client.store && selectedValues.includes(client.store.id);
        });
        console.log(filtered_clients, storeOptions, "filtered_clients");
        setFilteredClients(filtered_clients);
      }
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: selectedValues,
    }));
  };

  const HandleTypeChange = (selectedOption) => {
    setSelectedDateType(selectedOption.value);
  };

  const handleSubmit = () => {
    GetData();
  };

  const multiSelectStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#c9dddc" : "white", // Change background color for selected option
      color: state.isSelected ? "#1B7D78" : "black", // Change text color for selected option
      ":hover": {
        backgroundColor: "#c9dddc", // Change background color on hover for options
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#c9dddc", // Change background color for the multi-value container
      color: "#1B7D78", // Change text color for the multi-value container
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#1B7D78", // Change text color for the label within the multi-value container
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#1B7D78", // Change color for the "X" button within the multi-value container
      ":hover": {
        backgroundColor: "#1B7D78", // Change background color on hover for the "X" button
        color: "white",
      },
    }),
    menu: (base) => ({ ...base, zIndex: 2 }),
  };

  useEffect(() => {
    GetData();
    fetchStore();
    fetchClients();
  }, [currentPage, RowPerPage]);

  return (
    <>
      <DashboardLayout>
        <div className="child-sidenav">
          <ReportsNav />
          <div className="flex-1 childnav-content">
            <div className="report-filters">
              <Select
                placeholder="Store"
                className="flex-1"
                options={dateType}
                value={dateType.find(
                  (option) => option.value === selectedDateType
                )}
                onChange={(selectedOption) => HandleTypeChange(selectedOption)}
                styles={multiSelectStyles}
              />
              <div className="form-group flex-1">
                <input
                  className="form-control"
                  type="date"
                  name="fromDate"
                  value={formData.fromDate}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group flex-1">
                <input
                  className="form-control"
                  type="date"
                  name="toDate"
                  value={formData.toDate}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="report-filters mb-3">
              {user_data.role === "Admin" ? (
                <Select
                  placeholder="Store"
                  className="flex-1"
                  options={storeOptions.map((res) => ({
                    value: res.id,
                    label: res.name,
                  }))}
                  isMulti
                  value={storeOptions
                    .map((res) => ({ value: res.id, label: res.name }))
                    .find((res) => res.value === formData.store)}
                  onChange={(selectedOption) =>
                    HandleselectChange(selectedOption, "store")
                  }
                  styles={multiSelectStyles}
                />
              ) : (
                <div className="flex-1 f_14_500">
                  Store :
                  <span className="f_14_400">
                    {" "}
                    {
                      storeOptions.find((res) => res.id === user_data.store)
                        ?.name
                    }
                  </span>
                </div>
              )}
              <Select
                placeholder="Clients"
                className="flex-1"
                options={filteredClients.map((res) => ({
                  value: res.id,
                  label: res.client_name,
                }))}
                isMulti
                value={filteredClients
                  .map((res) => ({ value: res.id, label: res.client_name }))
                  .find((res) => res.value === formData.client)}
                onChange={(selectedOption) =>
                  HandleselectChange(selectedOption, "client")
                }
                styles={multiSelectStyles}
              />
              <div
                style={{ minWidth: "143px", height: "37px" }}
                className="d-flex justify-content-center"
              >
                {isLoading ? (
                  <img
                    src={require("../Components/FormParser/images/loader.gif")}
                    className="loader-image"
                    alt="loader"
                  />
                ) : (
                  <button
                    className="btn btn-primary"
                    style={{ height: "37px" }}
                    onClick={handleSubmit}
                  >
                    Get Reports
                  </button>
                )}
              </div>
            </div>
            <DataTable
              columns={columns}
              data={Data}
              pagination
              paginationTotalRows={totalRows}
              paginationPerPage={RowPerPage}
              paginationDefaultPage={currentPage}
              onChangePage={(page) => setCurrentPage(page)}
              paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
              paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
              onChangeRowsPerPage={(RowsPerPageChange) =>
                setRowPerPage(RowsPerPageChange)
              }
              onSort={handleSort}
              paginationServer
              fixedHeader
              fixedHeaderScrollHeight="70vh"
              customStyles={tableCustomStyles}
              progressPending={loading}
              progressComponent={
                <div className="loader-container">
                  <img
                    src={require("../Components/FormParser/images/loader2.gif")}
                    alt="Loading..."
                  />
                </div>
              }
            />
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};
export { BillItemList };
