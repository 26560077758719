import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {MdOutlineViewCarousel, MdOutlineImage } from "react-icons/md"
import {BsCardText, BsMoonStars} from "react-icons/bs"
import {BiImages} from "react-icons/bi"
import {AiOutlineHome} from "react-icons/ai"

const CMSNavbarComponent = ({handleShow}) => {
    return (
        <div className="categorie-header">
            <NavLink to="/dashboard/masters/homedesign" className="categorie-subtitle" ><div className="child-title">
                <AiOutlineHome size={24} className="childnav-icon" />
                <p className="childnav-name">Homepage Design</p>
            </div></NavLink>
            <NavLink  className="categorie-subtitle" ><div className="child-title" onClick={handleShow}>
                <BsMoonStars size={24} className="childnav-icon" />
                <p className="childnav-name">Theme</p>
            </div></NavLink>
            <NavLink  to="/dashboard/cms/old-banner" className="categorie-subtitle" ><div className="child-title" >
                <BsMoonStars size={24} className="childnav-icon" />
                <p className="childnav-name">Old Banner</p>
            </div></NavLink>
        </div>
    )
}

export  {CMSNavbarComponent}