import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md"
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { downloadExcel, useDownloadExcel } from 'react-export-table-to-excel';
const Reports = () => {
    let today_date = (new Date()).toISOString().split('T')[0];
    const [clinics, setClinics] = useState()
    const [services, setServices] = useState()
    const [specialities, setSpacalitys] = useState()
    const [doctors, setDoctors] = useState()
    const [visible, SetVisable] = useState([])
    const [action, setaction] = useState(0)
    const [action2, setaction2] = useState(0)
    const [action3, setaction3] = useState(0)
    const [loading, setLoading] = useState(false);
    const [deletePopupType, setDeletepopupType] = useState("")
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [RowId, SetRowId] = useState()
    const [Datafilter, SetDatafilter] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [UserData, SetUserData] = useState({})

    const [search, SetSearch] = useState({
        search: ""
    })
    let navigate = useNavigate();
    const user_data = JSON.parse(localStorage.getItem('user_data'));
    const [formData, setFormData] = useState({
        fromDate: today_date,
        toDate: today_date,
        clinic:user_data.doctor_id?user_data.clinic_id:"",
        service: '',
        bill_item_type:"Consultation",
        specialities:"",
        doctor:user_data.doctor_id?user_data.doctor_id:""
    });
    const [Data, SetData] = useState([])

    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/clinics/bill-items/?bill_item_type=${formData.bill_item_type}&patient=&clinic=${formData.clinic}&speciality=${formData.specialities}&doctor=${formData.doctor}&service=&created_date_after=${formData.fromDate}&created_date_before=${formData.toDate}&page=${currentPage}&page_size=${RowPerPage}`)
            SetData(responce.data.results)
            SetDatafilter(responce.data.results)
            setLoading(false)
            setTotalRows(responce.data.count)
        }
        catch (error) {
            console.log(error)
        }

    }

    const getClinics = async () => {
        try {
            const responce = await axiosConfig.get(`/clinics/clinics/`)
            setClinics(responce.data.results)
        }
        catch (error) {
            console.log(error)
        }

    }

    const getServices = async () => {
        try {
            const responce = await axiosConfig.get(`/clinics/doctorservices/`)
            setServices(responce.data.results)
        }
        catch (error) {
            console.log(error)
        }

    }

    const getDoctors = async () => {
        try {
            const responce = await axiosConfig.get(`/clinics/doctors/`)
            setDoctors(responce.data.results)
        }
        catch (error) {
            console.log(error)
        }

    }
    const getUserData = async () => {
        const user_data = JSON.parse(localStorage.getItem('user_data'));
        SetUserData(user_data);
        console.log('before', user_data.user_type)

        if (user_data.user_type == "doctor") {
            // setFormData({ ...formData, ['doctor']: user_data.id })
            console.log('docror', user_data.id)
        }
        GetData();
    }
    const getSpecalitys = async () => {
        try {
            const responce = await axiosConfig.get(`/masters/specialities/`)
            setSpacalitys(responce.data.results)
        }
        catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        getSpecalitys();
        getUserData();
        getClinics();
        getServices();
        getDoctors();
    }, [currentPage, RowPerPage, formData.bill_item_type])

    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        axiosConfig.get(`/clinics/bill-items/?bill_item_type=${formData.bill_item_type}&patient=&clinic=${formData.clinic}&speciality=${formData.specialities}&doctor=${formData.doctor}&service=&created_date_after=${formData.fromDate}&created_date_before=${formData.toDate}&page=${currentPage}&page_size=${RowPerPage}&search=${value}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        SetSearch({ ...search, [name]: value })

    }
    const handleSort = (column, direction) => {
        console.log(column,direction)
        axiosConfig.get(`/clinics/bill-items/?bill_item_type=${formData.bill_item_type}&patient=&clinic=${formData.clinic}&speciality=${formData.specialities}&doctor=${formData.doctor}&service=&created_date_after=${formData.fromDate}&created_date_before=${formData.toDate}&page=${currentPage}&page_size=${RowPerPage}&ordering=${direction === "asc" ? column.selector : `-${column.selector}`}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };
    const ExampleComponent = (time) => {
        const timestamp = time;
        const date = new Date(timestamp);
      
        const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedTime = `${hours % 12 || 12}:${minutes.toString().padStart(2, '0')} ${hours >= 12 ? 'PM' : 'AM'}`;
      
        
        const formattedDateTime = `${formattedDate} ${formattedTime}`;
        return formattedDateTime
    }
    const columns = [
        {
            name: "UHID",
            selector:"patient_details.uhid",
            sortable: true,
        },
        {
            name: "Patient",
            selector:"patient",
            cell: (row)=>`${row.patient_name} (${row.gender}) ${row.age} yrs`,
            sortable: true,
        },
        {
            name: "Speciality - Service By Doctor",
            selector:"speciality_name",
            cell: row => `${row.speciality_name}-${row.service_name} By:${row.doctor_name}`,
            sortable: true,
        },
        {
            name: "Scheduled At",
            selector: "booking_date_time",
            cell: row => ExampleComponent(row.created_date),
            sortable: true,
        },
        {
            name: "net_amount",
            selector: "net_amount",
            sortable: true,
        },
        {
            name: "Status",
            selector: "status",
            sortable: true,
        },
    ]
    const handleRowsPerPageChange = (event) => {
        console.log(event.target.value);
        setCurrentPage(1);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };
    const HandleRadio = (name) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            ["bill_item_type"]:name
        }));
    };

    const HandleSelect = (selectedOption, name) => {
        setFormData({ ...formData, [name]: selectedOption.value })
    }

    const handleSubmit = async () => {
        setLoading(true)
        GetData();
    };
    const HandleSheet=()=>{
        const header = ["S.No",
    "UHID",
    "Patient",
    "Speciality - Service By Doctor",
    "Scheduled At",
    "Net Amount",
    "status",];
 const body3 = Data.map((res, index) => [index + 1, res.patient.uhid,`${res.patient.name} (${res.patient.gender}) ${res.patient.age} yrs`, `${res.speciality_name}-${res.service_name} ${res.doctor_name}`, ExampleComponent(res.booking_date_time),res.net_amount,res.status])
 downloadExcel({
     fileName: `Reports.xls`,
     sheet: `Reports.xls`,
     tablePayload: {
         header,
         body: body3,
     },
  });
    }
  

    return (
        <>
            <DashboardLayout>

                <div className="mainbar">
                    <div className="sidebar-filters px-2">
                        <div className="form-group">
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onChange={()=>HandleRadio("Consultation")} checked={formData.bill_item_type==="Consultation"?true:false}/>
                                    <label className="form-check-label" for="flexRadioDefault1">
                                    Consultation
                                    </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"  onChange={()=>HandleRadio("Service")} checked={formData.bill_item_type==="Service"?true:false}/>
                                    <label className="form-check-label" for="flexRadioDefault2">
                                    Service
                                    </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3"  onChange={()=>HandleRadio("Procedure")} checked={formData.bill_item_type==="Procedure"?true:false}/>
                                    <label className="form-check-label" for="flexRadioDefault3">
                                    Procedure
                                    </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>From Date</label>
                            <input
                                className="form-control"
                                type="date"
                                name="fromDate"
                                value={formData.fromDate}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>To Date</label>
                            <input
                                className="form-control"
                                type="date"
                                name="toDate"
                                value={formData.toDate}
                                onChange={handleChange}
                            />
                        </div>
                        {UserData && UserData.user_type == "staff" && (
                            <>
                                <div className="form-group">
                                    <label>Specialities</label>
                                    <Select
                                        options={specialities && specialities.map((res) => ({ "value": res.id, "label":res.name }))}
                                        onChange={(selectedOption) => HandleSelect(selectedOption, "specialities")}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Doctor</label>
                                    <Select
                                        options={doctors && doctors.map((doctor) => ({ "value": doctor.id, "label": doctor.name }))}
                                        onChange={(selectedOption) => HandleSelect(selectedOption, "doctor")}
                                    />
                                </div>
                            </>
                        )}
                        {/* {
                            UserData && UserData.user_type==="doctor"&& (
                                <>
                                <div className="form-group">
                                <label>Clinic/Hospital</label>
                                <Select
                                    options={clinics && clinics.map((clinic) => ({ "value": clinic.id, "label": clinic.clinic_name }))}
                                    onChange={(selectedOption) => HandleSelect(selectedOption, "clinic")}
                                />
                            </div>
                            <div className="form-group">
                                <label>Department/Service</label>
                                <Select
                                    options={services && services.map((service) => ({ "value": service.id, "label": service.service_name }))}
                                    onChange={(selectedOption) => HandleSelect(selectedOption, "service")}
                                />
                            </div>
                            </>
                            )
                        } */}
                         {
                            UserData && UserData.user_type==="admin"&& (
                                <>
                                <div className="form-group">
                                <label>Clinic/Hospital</label>
                                <Select
                                    options={clinics && clinics.map((clinic) => ({ "value": clinic.id, "label": clinic.clinic_name }))}
                                    onChange={(selectedOption) => HandleSelect(selectedOption, "clinic")}
                                />
                            </div>
                            <div className="form-group">
                                    <label>Specialities</label>
                                    <Select
                                        options={specialities && specialities.map((res) => ({ "value": res.id, "label":res.name }))}
                                        onChange={(selectedOption) => HandleSelect(selectedOption, "specialities")}
                                    />
                                </div>
                            <div className="form-group">
                                    <label>Doctor</label>
                                    <Select
                                        options={doctors && doctors.map((doctor) => ({ "value": doctor.id, "label": doctor.name }))}
                                        onChange={(selectedOption) => HandleSelect(selectedOption, "doctor")}
                                    />
                                </div>
                            </>
                            )
                        }
                        <button className="btn btn-primary btn-filter" onClick={handleSubmit}>
                            Filter
                        </button>

                    </div>
                    <div className="main-code">
                        <div className="dashboard-header">
                            <h5 className="dashboard-subtitle">View Reports</h5>
                            {UserData.user_type == "doctor"?"":<NavLink to="/dashboard/worklist//billitem/add" ><button className="btn btn-white">Add New</button></NavLink>}
                        </div>
                        <div className="table-responsive">
                            <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                                paginationPerPage={RowPerPage}
                                paginationDefaultPage={currentPage}
                                onChangePage={(page) => setCurrentPage(page)}
                                paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                                onSort={handleSort}
                                paginationServer
                                fixedHeader
                                customStyles={tableCustomStyles}
                                subHeader
                                progressPending={loading}
                                progressComponent={<div className="loader-container">
                                    <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                </div>}
                                subHeaderComponent={
                                    <input type="text" className="form-control w-25" name="search" value={search.search} onChange={HandleSearch} />} />
                        </div>
                    <button type="button" className="btn btn-primary" onClick={HandleSheet}>Export</button>

                        {deletePopupType != "" &&
                            <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                        }
                    </div>
                </div>
            </DashboardLayout>
        </>
    )
}
export { Reports }