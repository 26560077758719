import React, { useState, useEffect } from "react";
import { DashboardLayout } from "../Components";
import { useParams } from "react-router-dom";
import { ReportsNav } from "../Components/navbar";
import DataTable from "react-data-table-component";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import axiosConfig from "../Service/axiosConfig";
import Select from "react-select";
import { AiOutlineFilePdf, AiOutlineFileExcel } from "react-icons/ai";
import { downloadExcel } from "react-export-table-to-excel";
import { useNavigate } from "react-router";
import NumberFormat from "../Utils/NumberFormat";

const ItemClientReports = () => {
  const [data, setData] = useState([]);
  const [stats, setStats] = useState({});
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [RowPerPage, setRowPerPage] = useState(20);
  const [loading, setLoading] = useState(false);
  const { date, product_name, product, from, to } = useParams();
  const [selectedProduct, setSelectedProduct] = useState({
    label: "All Products",
    value: null,
  });
  const [formData, setFormData] = useState({
    fromDate: date
      ? date
      : from
      ? from
      : new Date().toISOString().split("T")[0],
    toDate: date ? date : to ? to : new Date().toISOString().split("T")[0],
    client: null,
    product: product ? product : "",
  });
  const [products, setProducts] = useState([]);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const formattedDate = `${day}-${months[monthIndex]}-${year}`;
    return formattedDate;
  };
  const Navigate = useNavigate();

  const columns = [
    {
      name: "S. No.",
      selector: (row, index) => index + 1,
      sortable: true,
      compact: true,
      center: true,
    },
    {
      name: "Client",
      selector: "client_name",
      sortable: true,
      reorder: true,
      grow:2
    },
    {
      name: "Quantity(KG)",
      selector: "qty",
      sortable: true,
      reorder: true,
      center:true
    },
    {
      name: "Amount(INR)",
      selector: "amount",
      cell: (row) => `₹ ${NumberFormat(row.amount)}`,
      sortable: true,
      reorder: true,
      right:true
    },
  ];

  const multiSelectStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#c9dddc" : "white", // Change background color for selected option
      color: state.isSelected ? "#1B7D78" : "black", // Change text color for selected option
      ":hover": {
        backgroundColor: "#c9dddc", // Change background color on hover for options
      },
    }),
    menu: (base) => ({ ...base, zIndex: 2 })
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const HandleSelect = (selectedOption) => {
    setSelectedProduct(selectedOption);
    setFormData((prevFormData) => ({
      ...prevFormData,
      product: selectedOption.value,
    }));
  };

  const handleSort = (column, direction) => {
    console.log(column, direction);
    // axiosConfig.get(`/accounts/orders/?orderstatus=Delivered&store=${formData.store ? formData.store : ''}&${selectedDateType === 'ordered_date' ? 'order_date_after' : 'expected_date_time_before'}=${formData.fromDate}&${selectedDateType === 'ordered_date' ? 'order_date_before' : 'expected_date_time_after'}=${formData.toDate}&page=${currentPage}&page_size=${RowPerPage}&ordering=${direction === "asc" ? column.name : `-${column.selector}`}`).then(res => {
    //     SetData(res.data.results)
    // }).catch(error => {
    //     console.log(error)
    // })
    // setCurrentPage(1);
  };

  const header = ["S.No", "Client", "Quantity", "Amount"];
  const body = data.map((res, index) => [
    index + 1,
    res.client_name,
    res.qty,
    res.amount,
  ]);

  function handleDownloadExcel() {
    downloadExcel({
      fileName: "Client & Product Report",
      sheet: "Client & Product Report",
      tablePayload: {
        header: header,
        body: body,
      },
    });
  }

  const HandlePdf = (data) => {
    localStorage.setItem("pdf_data", JSON.stringify(data));
    window.open(`${window.location.origin}/print`, "_blank");
  };

  const CustomHeader = () => {
    return (
      <div className="rowEndCenter gap-10">
        <AiOutlineFileExcel
          className="report-icons"
          onClick={handleDownloadExcel}
        />
        <AiOutlineFilePdf
          className="report-icons"
          onClick={() => HandlePdf(data)}
        />
      </div>
    );
  };

  const GetData = async () => {
    setLoading(true);
    try {
      const response = await axiosConfig.get(
        `/accounts/generate_item_client_reports/?from_date=${
          formData.fromDate
        }&to_date=${formData.toDate}&product=${
          formData.product ? formData.product : ""
        }`
      );
      const data = response.data.data;
      console.log("ItemClient Data", data);
      setData(data);
      setStats({
        average_qty: response.data.average_qty,
        average_amount: response.data.average_amount,
        total_qty: response.data.total_qty,
        total_amount: response.data.total_amount,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    GetData();
  };

  const GetProducts = async () => {
    try {
      const response = await axiosConfig.get(
        `catlog/products/?page_size=100&is_suspended=false`
      );
      const products = response.data.results;
      console.log(products);
      setProducts(products);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetData();
    GetProducts();
  }, []);

  return (
    <>
      <DashboardLayout>
        <div className="child-sidenav">
          <ReportsNav />
          <div className="flex-1 childnav-content">
            <div className="report-filters">
              {!product && (
                <Select
                  className="flex-1"
                  placeholder="All Products"
                  options={[{ label: "All Products", value: null }].concat(
                    products.map((product) => ({
                      label: product.name,
                      value: product.id,
                    }))
                  )}
                  onChange={(selectedOption) => HandleSelect(selectedOption)}
                  styles={multiSelectStyles}
                />
              )}
              <div className="form-group flex-1">
                <input
                  className="form-control"
                  type="date"
                  name="fromDate"
                  value={formData.fromDate}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group flex-1">
                <input
                  className="form-control"
                  type="date"
                  name="toDate"
                  value={formData.toDate}
                  onChange={handleChange}
                />
              </div>
              <div
                style={{ minWidth: "143px", height: "37px" }}
                className="d-flex justify-content-center"
              >
                {loading ? (
                  <img
                    src={require("../Components/FormParser/images/loader.gif")}
                    className="loader-image"
                    alt="loader"
                  />
                ) : (
                  <button
                    className="btn btn-primary"
                    style={{ height: "37px" }}
                    onClick={handleSubmit}
                  >
                    Get Reports
                  </button>
                )}
              </div>
            </div>
            <div className="rowBetweenCenter" style={{ margin: "16px" }}>
              {!product ? (
                <div className="f_14_500">
                  Product :{" "}
                  <span className="f_14_400">{selectedProduct.label}</span>
                </div>
              ) : (
                <div className="f_14_500">
                  Product : <span>{product_name}</span>
                </div>
              )}
              <div className="f_14_500">
                From{" "}
                <span
                  className="f_14_400"
                  style={{ marginLeft: "8px", marginRight: "8px" }}
                >
                  {formatDate(formData.fromDate)}
                </span>
                To{" "}
                <span
                  className="f_14_400"
                  style={{ marginLeft: "8px", marginRight: "8px" }}
                >
                  {formatDate(formData.toDate)}
                </span>
              </div>
            </div>
            <div className="rowBetweenCenter" style={{ margin: "16px" }}>
              <div className="f_14_500">
                Total Rs. :{" "}
                <span className="f_14_400">{`₹  ${NumberFormat(stats.total_amount)}`}</span>
              </div>
              <div className="f_14_500">
                Total Qty : <span className="f_14_400"> {stats.total_qty}</span>{" "}
              </div>
              <div className="f_14_500">
                Daily Avg Rs. :{" "}
                <span className="f_14_400">{`₹ ${NumberFormat(stats.average_amount)}`}</span>{" "}
              </div>
              <div className="f_14_500">
                Daily Avg Qty :{" "}
                <span className="f_14_400">{stats.average_qty}</span>
              </div>
            </div>
              <DataTable
                columns={columns}
                data={data}
                // pagination
                // paginationTotalRows={totalRows}
                title={<CustomHeader />}
                paginationPerPage={RowPerPage}
                paginationDefaultPage={currentPage}
                onChangePage={(page) => setCurrentPage(page)}
                paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                onChangeRowsPerPage={(RowsPerPageChange) =>
                  setRowPerPage(RowsPerPageChange)
                }
                onSort={handleSort}
                fixedHeader={true}
                fixedHeaderScrollHeight="70vh"
                paginationServer
                customStyles={tableCustomStyles}
                progressPending={loading}
                progressComponent={
                  <div className="loader-container">
                    <img
                      src={require("../Components/FormParser/images/loader2.gif")}
                      alt="Loading..."
                    />
                  </div>
                }
              />
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export { ItemClientReports };
