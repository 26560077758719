import React from 'react';
import { NavLink } from 'react-router-dom';
import { HiOutlineCreditCard } from "react-icons/hi2";
import { TbBusinessplan } from "react-icons/tb";
const PaymentsNav = () => {
    return (
        <div className="categorie-header">
            <NavLink to="/dashboard/masters/payment_types" className="categorie-subtitle" ><div className="child-title">
                <HiOutlineCreditCard size={24} className="childnav-icon" />
                <p className="childnav-name">Payment Types</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/business_vertical_payments" className="categorie-subtitle" ><div className="child-title">
                <TbBusinessplan size={24} className="childnav-icon" />
                <p className="childnav-name">Business Vertical Payments</p>
            </div></NavLink>
        </div>
    )
}

export { PaymentsNav }