import React, { useEffect, useState } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { VariableTariff } from "./Variable";
import { ContractedTariff } from "./Contracted";

const ClientWiseTariff = () => {
    const [key, setKey] = useState('Variable');
    return (
        <>
            <div>
                <div className="form-group">
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                    >
                        <Tab eventKey="Variable" title="Variable">
                            <VariableTariff />
                        </Tab>
                        <Tab eventKey="Contracted" title="Contracted">
                            <ContractedTariff />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </>
    )
}
export { ClientWiseTariff }